import { Box, Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { BanVoucherListApi, BanVoucherUpdateApi } from '../../../../services/ConfigServices'
import { useState } from 'react'

const VoucherConfig = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation('common')
    const { restaurantList } = useSelector((state) => state.restaurantList);
    const [ignoredList, setIgnoredList] = useState([])
    const [banList, setBanList] = useState([])

    useEffect(() => {
        dispatch(BanVoucherListApi((res) => {
            let arr = [];
            res.data && res.data.length > 0 && res.data.map((item) => {
                if (item.isActive == '1') {
                    arr.push(item.restaurantId)
                }
            })
            setBanList(res.data)
            setIgnoredList(arr)
        }))
    }, [])


    useEffect(() => {

    }, [dispatch])

    const onsubmit = (values) => {
        let object = {}
        banList.map((item) => {
            if (values.excludeRestaurant.includes(item.restaurantId)) {
                item.isActive = '1'
            } else {
                item.isActive = '0'
            }
            delete item.RestaurantName
            item.id = parseInt(item.id)
        })

        object = {
            RestaurantList: banList
        }

        dispatch(BanVoucherUpdateApi(object, (ress) => {
            setTimeout(() => {
                dispatch(BanVoucherListApi((res) => {
                    let arr = [];
                    res.data && res.data.length > 0 && res.data.map((item) => {
                        if (item.isActive == '1') {
                            arr.push(item.restaurantId)
                        }
                    })
                    values.excludeRestaurant = arr
                    setIgnoredList(arr)
                }))
            }, 100);
        }))
    }


    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">{t('voucherconfigscreen.title')}</h4>
            </div>

            <Formik
                enableReinitialize
                initialValues={{
                    excludeRestaurant: ignoredList && ignoredList.length > 0 ? ignoredList : [],
                }}

                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, isValid, isDisabled, handleBlur, setFieldValue, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Form onSubmit={handleSubmit} noValidate>
                                <div className="card-body">
                                    <div className="row">
                                        <div className='col-12 pt-3'>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-multiple-checkbox-label">{t('voucherconfigscreen.excluderestaurant')}</InputLabel>
                                                <Select
                                                    fullWidth
                                                    multiple
                                                    labelId="demo-multiple-checkbox-label"
                                                    id="demo-multiple-checkbox"
                                                    name='excludeRestaurant'
                                                    value={values.excludeRestaurant}
                                                    onChange={(e) => handleChange(e)}
                                                    input={<OutlinedInput label={t('voucherconfigscreen.excluderestaurant')} />}
                                                    renderValue={(selected) => (
                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                            {selected && selected.length > 0 && selected.map((value) => (
                                                                <Chip key={value} label={restaurantList.filter((item) => item.RID == value)[0]?.RestaurantName} />
                                                            ))}
                                                        </Box>
                                                    )}
                                                >
                                                    {restaurantList && restaurantList.length > 0 && restaurantList.map((item, index) => (
                                                        <MenuItem key={index} value={item.RID}>
                                                            <Checkbox checked={values.excludeRestaurant.indexOf(item.RID) > -1} />
                                                            <ListItemText primary={item.RestaurantName} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </div>
                            </Form >
                        </>
                    );
                }}
            </Formik>
        </div >
    )
}

export default VoucherConfig
