
export const COLUMNSFORVOUCHER = [
	
	{
		Header : 'voucherscreen.vouchername',
		accessor: 'VoucherName'
	},
	{
		Header : 'voucherscreen.minimumamount',
		accessor: 'MinimumAmount'
	},
	{
		Header : 'voucherscreen.price',
		accessor: "Price"
	},
	{
		Header : 'voucherscreen.quantity',
		accessor: 'Quantity'
	},
]
