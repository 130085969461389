import { Checkbox, FormControl, FormControlLabel, MenuItem, TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { Button, Modal, Nav, Tab } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { symbolsArr } from '../../../../constants/constants';
import { VivaPaymentManage } from '../../../../services/RestaurantServices';


const VivaEditModal = (props) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()
    const { openVivaModal, setOpenVivaModal, paymentData, settingData, handleSettings } = props

    const onsubmit = (values) => {
        if (paymentData) {
            values.ID = paymentData.ID
        }
        values.RestaurantId = settingData.RID
        dispatch(VivaPaymentManage(values, (res) => {
            setTimeout(() => {
                handleModalClose()
                handleSettings(settingData)
            }, 1);
        }))

    }


    const handleModalClose = () => {
        setOpenVivaModal(false);

    }


    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openVivaModal}
            // onHide={handleModalClose}
            size="lg"
            centered
        >

            <Formik
                enableReinitialize
                initialValues={{
                    Payment_Enable: paymentData && paymentData.IsActive == '0' ? false : true,
                    Payment_Enable_TestMode: paymentData && paymentData.Payment_Enable_TestMode == '1' ? true : false,
                    Payment_Pre_Auth: paymentData && paymentData.Payment_Pre_Auth == '1' ? true : false,
                    Payment_Name: paymentData && paymentData.Payment_Name ? paymentData.Payment_Name : '',
                    Payment_Description: paymentData && paymentData.Payment_Description ? paymentData.Payment_Description : "",
                    Payment_Amount_Percent: paymentData && paymentData.Payment_Amount_Percent ? paymentData.Payment_Amount_Percent : "",

                    Viva_Merchant_ID: paymentData && paymentData.Viva_Merchant_ID ? paymentData.Viva_Merchant_ID : "",
                    Viva_ISV_Partner_ID: paymentData && paymentData.Viva_ISV_Partner_ID ? paymentData.Viva_ISV_Partner_ID : "",
                    Viva_ISV_Partner_Key: paymentData && paymentData.Viva_ISV_Partner_Key ? paymentData.Viva_ISV_Partner_Key : "",
                    Viva_Client_ID: paymentData && paymentData.Viva_Client_ID ? paymentData.Viva_Client_ID : "",
                    Viva_Client_Secret: paymentData && paymentData.Viva_Client_Secret ? paymentData.Viva_Client_Secret : "",
                    Viva_SourceCode: paymentData && paymentData.Viva_SourceCode ? paymentData.Viva_SourceCode : "",

                    Viva_Merchant_ID_Test: paymentData && paymentData.Viva_Merchant_ID_Test ? paymentData.Viva_Merchant_ID_Test : "",
                    Viva_ISV_Partner_ID_Test: paymentData && paymentData.Viva_ISV_Partner_ID_Test ? paymentData.Viva_ISV_Partner_ID_Test : "",
                    Viva_ISV_Partner_Key_Test: paymentData && paymentData.Viva_ISV_Partner_Key_Test ? paymentData.Viva_ISV_Partner_Key_Test : "",
                    Viva_Client_ID_Test: paymentData && paymentData.Viva_Client_ID_Test ? paymentData.Viva_Client_ID_Test : "",
                    Viva_Client_Secret_Test: paymentData && paymentData.Viva_Client_Secret_Test ? paymentData.Viva_Client_Secret_Test : "",
                    Viva_SourceCode_Test: paymentData && paymentData.Viva_SourceCode_Test ? paymentData.Viva_SourceCode_Test : "",
                }}
                validationSchema={
                    Yup.object().shape({
                        Payment_Name: Yup.string().required("name is required."),
                        Viva_Merchant_ID: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: false,
                                then: Yup.string().required("Merchant Id is required")
                            }),
                        Viva_Client_ID: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: false,
                                then: Yup.string().required("Client Id is required")
                            }),
                        Viva_Client_Secret: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: false,
                                then: Yup.string().required("Client Secret is required")
                            }),
                        Viva_ISV_Partner_ID: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: false,
                                then: Yup.string().required("ISV partner Id is required")
                            }),
                        Viva_ISV_Partner_Key: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: false,
                                then: Yup.string().required("ISV Partner key is required")
                            }),
                        Viva_Merchant_ID_Test: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: true,
                                then: Yup.string().required("Merchant Id is required")
                            }),
                        Viva_Client_ID_Test: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: true,
                                then: Yup.string().required("Client Id is required")
                            }),
                        Viva_Client_Secret_Test: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: true,
                                then: Yup.string().required("Client Secret is required")
                            }),
                        Viva_ISV_Partner_ID_Test: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: true,
                                then: Yup.string().required("ISV partner Id is required")
                            }),
                        Viva_ISV_Partner_Key_Test: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: true,
                                then: Yup.string().required("ISV partner key is required")
                            }),
                    })
                }
                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Modal.Header>
                                <Modal.Title>{t('profile.payment.settings')}</Modal.Title>
                                <Button
                                    variant=""
                                    className="btn-close"
                                    onClick={handleModalClose}
                                >

                                </Button>
                            </Modal.Header>
                            <Form onSubmit={handleSubmit} noValidate>
                                <Modal.Body>
                                    <div className="row">
                                        <div className='col-12'>
                                            <FormControlLabel
                                                control={<Checkbox checked={values.Payment_Enable} />}
                                                label={`Enable Viva`}
                                                name="Payment_Enable"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-12 pt-3">
                                            {/* <div className="card">
                                                <div className="card-header">
                                                    <h4 className="card-title">{t('profile.payment.displaysettings')}</h4>
                                                </div>
                                                <div className="card-body"> */}
                                            <div className='col-12'>
                                                <TextField
                                                    required
                                                    error={Boolean(errors.Payment_Name) && touched.Payment_Name}
                                                    helperText={(Boolean(errors.Payment_Name) && touched.Payment_Name) ? errors.Payment_Name : ''}
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    name="Payment_Name"
                                                    label={t('profile.payment.name')}
                                                    value={values.Payment_Name}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className='col-12 pt-3'>
                                                <TextField
                                                    multiline
                                                    rows={3}
                                                    error={Boolean(errors.Payment_Description) && touched.Payment_Description}
                                                    helperText={(Boolean(errors.Payment_Description) && touched.Payment_Description) ? errors.Payment_Description : ''}
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    name="Payment_Description"
                                                    label={t('profile.payment.description')}
                                                    value={values.Payment_Description}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="col-12 pt-3">
                                                <TextField
                                                    required
                                                    type='number'
                                                    InputProps={{ inputProps: { min: 0 } }}
                                                    onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                    error={Boolean(errors.Payment_Amount_Percent) && touched.Payment_Amount_Percent}
                                                    helperText={(Boolean(errors.Payment_Amount_Percent) && touched.Payment_Amount_Percent) ? errors.Payment_Amount_Percent : ''}
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    name="Payment_Amount_Percent"
                                                    label={t('profile.payment.amountpercent')}
                                                    value={values.Payment_Amount_Percent}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className='col-12'>
                                                <FormControlLabel
                                                    control={<Checkbox checked={values.Payment_Enable_TestMode} />}
                                                    label={t('profile.payment.enabletestmode')}
                                                    name="Payment_Enable_TestMode"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className='col-12 '>
                                                <Tab.Container defaultActiveKey='live'>
                                                    <Nav as='ul' className="nav nav-tabs">
                                                        <Nav.Item as='li' className="nav-item">
                                                            <Nav.Link to="#live" eventKey='live'>{t('profile.payment.live')}</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item as='li' className="nav-item">
                                                            <Nav.Link to="#test" eventKey='test'>{t('profile.payment.test')}</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                    <Tab.Content>
                                                        <Tab.Pane id="live" eventKey='live'>
                                                            <div className="row pt-3">
                                                                <div className="col-12 pt-3">
                                                                    <TextField
                                                                        required
                                                                        error={Boolean(errors.Viva_Merchant_ID) && touched.Viva_Merchant_ID}
                                                                        helperText={(Boolean(errors.Viva_Merchant_ID) && touched.Viva_Merchant_ID) ? errors.Viva_Merchant_ID : ''}
                                                                        fullWidth
                                                                        onBlur={handleBlur}
                                                                        name="Viva_Merchant_ID"
                                                                        label={t('profile.payment.merchantid')}
                                                                        value={values.Viva_Merchant_ID}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                                <div className="col-12 pt-3">
                                                                    <TextField
                                                                        required
                                                                        error={Boolean(errors.Viva_Client_ID) && touched.Viva_Client_ID}
                                                                        helperText={(Boolean(errors.Viva_Client_ID) && touched.Viva_Client_ID) ? errors.Viva_Client_ID : ''}
                                                                        fullWidth
                                                                        onBlur={handleBlur}
                                                                        name="Viva_Client_ID"
                                                                        label={t('profile.payment.clientid')}
                                                                        value={values.Viva_Client_ID}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                                <div className="col-12 pt-3">
                                                                    <TextField
                                                                        required
                                                                        error={Boolean(errors.Viva_Client_Secret) && touched.Viva_Client_Secret}
                                                                        helperText={(Boolean(errors.Viva_Client_Secret) && touched.Viva_Client_Secret) ? errors.Viva_Client_Secret : ''}
                                                                        fullWidth
                                                                        onBlur={handleBlur}
                                                                        name="Viva_Client_Secret"
                                                                        label={t('profile.payment.clientsecret')}
                                                                        value={values.Viva_Client_Secret}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                                <div className="col-12 pt-3">
                                                                    <TextField
                                                                        required
                                                                        error={Boolean(errors.Viva_ISV_Partner_ID) && touched.Viva_ISV_Partner_ID}
                                                                        helperText={(Boolean(errors.Viva_ISV_Partner_ID) && touched.Viva_ISV_Partner_ID) ? errors.Viva_ISV_Partner_ID : ''}
                                                                        fullWidth
                                                                        onBlur={handleBlur}
                                                                        name="Viva_ISV_Partner_ID"
                                                                        label={t('profile.payment.isvpartnerid')}
                                                                        value={values.Viva_ISV_Partner_ID}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                                <div className="col-12 pt-3">
                                                                    <TextField
                                                                        required
                                                                        error={Boolean(errors.Viva_ISV_Partner_Key) && touched.Viva_ISV_Partner_Key}
                                                                        helperText={(Boolean(errors.Viva_ISV_Partner_Key) && touched.Viva_ISV_Partner_Key) ? errors.Viva_ISV_Partner_Key : ''}
                                                                        fullWidth
                                                                        onBlur={handleBlur}
                                                                        name="Viva_ISV_Partner_Key"
                                                                        label={t('profile.payment.isvpartnerkey')}
                                                                        value={values.Viva_ISV_Partner_Key}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                                <div className="col-12 pt-3">
                                                                    <TextField
                                                                        required
                                                                        error={Boolean(errors.Viva_SourceCode) && touched.Viva_SourceCode}
                                                                        helperText={(Boolean(errors.Viva_SourceCode) && touched.Viva_SourceCode) ? errors.Viva_SourceCode : ''}
                                                                        fullWidth
                                                                        onBlur={handleBlur}
                                                                        name="Viva_SourceCode"
                                                                        label={t('profile.payment.sourcecode')}
                                                                        value={values.Viva_SourceCode}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>
                                                        <Tab.Pane id="test" eventKey='test'>
                                                            <div className="row pt-3">
                                                                <div className="col-12 pt-3">
                                                                    <TextField
                                                                        required
                                                                        error={Boolean(errors.Viva_Merchant_ID_Test) && touched.Viva_Merchant_ID_Test}
                                                                        helperText={(Boolean(errors.Viva_Merchant_ID_Test) && touched.Viva_Merchant_ID_Test) ? errors.Viva_Merchant_ID_Test : ''}
                                                                        fullWidth
                                                                        onBlur={handleBlur}
                                                                        name="Viva_Merchant_ID_Test"
                                                                        label={t('profile.payment.merchantid')}
                                                                        value={values.Viva_Merchant_ID_Test}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                                <div className="col-12 pt-3">
                                                                    <TextField
                                                                        required
                                                                        error={Boolean(errors.Viva_Client_ID_Test) && touched.Viva_Client_ID_Test}
                                                                        helperText={(Boolean(errors.Viva_Client_ID_Test) && touched.Viva_Client_ID_Test) ? errors.Viva_Client_ID_Test : ''}
                                                                        fullWidth
                                                                        onBlur={handleBlur}
                                                                        name="Viva_Client_ID_Test"
                                                                        label={t('profile.payment.clientid')}
                                                                        value={values.Viva_Client_ID_Test}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                                <div className="col-12 pt-3">
                                                                    <TextField
                                                                        required
                                                                        error={Boolean(errors.Viva_Client_Secret_Test) && touched.Viva_Client_Secret_Test}
                                                                        helperText={(Boolean(errors.Viva_Client_Secret_Test) && touched.Viva_Client_Secret_Test) ? errors.Viva_Client_Secret_Test : ''}
                                                                        fullWidth
                                                                        onBlur={handleBlur}
                                                                        name="Viva_Client_Secret_Test"
                                                                        label={t('profile.payment.clientsecret')}
                                                                        value={values.Viva_Client_Secret_Test}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                                <div className="col-12 pt-3">
                                                                    <TextField
                                                                        required
                                                                        error={Boolean(errors.Viva_ISV_Partner_ID_Test) && touched.Viva_ISV_Partner_ID_Test}
                                                                        helperText={(Boolean(errors.Viva_ISV_Partner_ID_Test) && touched.Viva_ISV_Partner_ID_Test) ? errors.Viva_ISV_Partner_ID_Test : ''}
                                                                        fullWidth
                                                                        onBlur={handleBlur}
                                                                        name="Viva_ISV_Partner_ID_Test"
                                                                        label={t('profile.payment.isvpartnerid')}
                                                                        value={values.Viva_ISV_Partner_ID_Test}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                                <div className="col-12 pt-3">
                                                                    <TextField
                                                                        required
                                                                        error={Boolean(errors.Viva_ISV_Partner_Key_Test) && touched.Viva_ISV_Partner_Key_Test}
                                                                        helperText={(Boolean(errors.Viva_ISV_Partner_Key_Test) && touched.Viva_ISV_Partner_Key_Test) ? errors.Viva_ISV_Partner_Key_Test : ''}
                                                                        fullWidth
                                                                        onBlur={handleBlur}
                                                                        name="Viva_ISV_Partner_Key_Test"
                                                                        label={t('profile.payment.isvpartnerkey')}
                                                                        value={values.Viva_ISV_Partner_Key_Test}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                                <div className="col-12 pt-3">
                                                                    <TextField
                                                                        required
                                                                        error={Boolean(errors.Viva_SourceCode_Test) && touched.Viva_SourceCode_Test}
                                                                        helperText={(Boolean(errors.Viva_SourceCode_Test) && touched.Viva_SourceCode_Test) ? errors.Viva_SourceCode_Test : ''}
                                                                        fullWidth
                                                                        onBlur={handleBlur}
                                                                        name="Viva_SourceCode_Test"
                                                                        label={t('profile.payment.sourcecode')}
                                                                        value={values.Viva_SourceCode_Test}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Tab.Container>
                                            </div>
                                            <div className='col-12'>
                                                <FormControlLabel
                                                    control={<Checkbox checked={values.Payment_Pre_Auth} />}
                                                    label={t('profile.payment.preauth')}
                                                    name="Payment_Pre_Auth"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            {/* </div>
                                            </div> */}
                                        </div>
                                    </div>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={handleModalClose}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        size="large"
                                    // disabled={!isValid || isDisabled}
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        </>
                    );
                }}
            </Formik>

        </Modal >

    )
}

export default VivaEditModal
