import { FormControl, MenuItem, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup'

const RestaurantSelect = (props) => {
    const { t } = useTranslation('common')
    const { setRestaurantId } = props
    const { restaurantList } = useSelector((state) => state.restaurantList);



    return (
        <Formik
            validateOnChange={false}
            enableReinitialize
            initialValues={{
                RestaurantId: ''
            }}

            validationSchema={Yup.object().shape({
                RestaurantId: Yup.string().required("Restaurant  is required."),
            })}
        >
            {(props) => {
                const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                return (
                    <>
                        <Form onSubmit={handleSubmit} noValidate>
                            <div className="row">
                                <div className="col-6">
                                    <FormControl fullWidth>
                                        <TextField
                                            required
                                            select
                                            fullWidth
                                            value={values.RestaurantId}
                                            label={t('transactionscreen.restaurant')}
                                            name='RestaurantId'
                                            onChange={(e) => { handleChange(e); setRestaurantId(e.target.value) }}
                                            onBlur={handleBlur}
                                            error={Boolean(errors.RestaurantId) && touched.RestaurantId}
                                            helperText={Boolean(errors.RestaurantId) || touched.RestaurantId ? errors.RestaurantId : ""}
                                        >
                                            {restaurantList && restaurantList.length > 0 && restaurantList.map((item, index) => {
                                                return <MenuItem key={index} value={item.RID}>{item.RestaurantName}</MenuItem>
                                            })}
                                        </TextField>
                                    </FormControl>
                                </div>
                            </div>
                        </Form>
                    </>
                );
            }}
        </Formik>
    )
}

export default RestaurantSelect
