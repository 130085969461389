import AWS from 'aws-sdk'
import { imageUploadinLocal } from '../../constants/constants';
import axiosApi from '../../api';

AWS.config.region = 'eu-west-2'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'eu-west-2:fd314250-c785-408c-8a5a-6a4565fcf2d2',
});

const BUCKET_NAME = 'foodel-backend-images';

const s3 = new AWS.S3({
    region: AWS.config.region,
    credentials: AWS.config.credentials
});

export const S3Uploads = async (fileContent, filePath, fileName) => {
    if (imageUploadinLocal) {
        let tempFileArray = filePath.split('/')
        tempFileArray.pop()
        let tempfilePath = tempFileArray.join("/")

        const formData = new FormData()
        formData.append('File', fileContent)
        formData.append('FilePath', tempfilePath)
        formData.append('FileName', fileName)

        axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        axiosApi.defaults.headers.common['Content-Type'] = `multipart/form-data`;
        await axiosApi.post('Api/upload-file', formData).then((res) => {
            if (res.data.status == 1) {
                return true
            } else {
                return false
            }
        }).catch((error) => {
            return false
        })
    } else {
        const params = {
            Bucket: BUCKET_NAME,
            Key: `uploads/${filePath}`,
            Body: fileContent,
            ContentType: 'image/jpeg',
        };

        let data = s3.upload(params).promise()
        if (data) {
            return true
        }
        else {
            return false
        }
    }
}

export const S3ImageDelete = async (fileName) => {
    if (imageUploadinLocal) {
        axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        await axiosApi.post('Api/remove-file', { FilePath: fileName }).then((res) => {
            if (res.data.status == 1) {
                return true
            } else {
                return false
            }
        }).catch((error) => {
            return false
        })
    } else {
        s3.deleteObject({
            Bucket: BUCKET_NAME,
            Key: `uploads/${fileName}`,
        }, function (err, data) {
            if (err) {
                console.log(err, "@@@@@@@@@@@@@@@@@@@@@@@")
                return false
            }
        })
        return true
    }
}