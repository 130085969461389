
export const MenuList = [
    {
        title: 'sidebar.menu.managerestaurant',
        to: 'admin/restaurantlist',
        iconStyle: "fa-solid fa-utensils",
        Permissions: ['admin'],
    },
    {
        title: 'sidebar.menu.managepages',
        to: 'admin/pagemaster',
        iconStyle: "bi bi-file-earmark-fill",
        Permissions: ['admin'],
    },
    {
        title: 'sidebar.menu.manageprmocode',
        to: 'admin/promocode',
        iconStyle: "bi bi-percent",
        Permissions: ['admin'],
    },
    {
        title: 'sidebar.menu.managevoucher',
        to: 'admin/voucher',
        iconStyle: "bi bi-tags-fill",
        Permissions: ['admin'],
    },
    {
        title: 'sidebar.menu.managerating',
        to: 'admin/ratings',
        iconStyle: "bi bi-star-fill",
        Permissions: ['admin'],
    },
    {
        title: 'sidebar.menu.managedelivaryarea',
        to: 'admin/deliveryarea',
        iconStyle: "bi bi-truck",
        Permissions: ['admin'],
    },
    {
        title: 'sidebar.menu.paymentoptions',
        to: 'admin/paymentoptions',
        iconStyle: "bi bi-credit-card-fill",
        Permissions: ['admin'],
    },
    {
        title: 'sidebar.menu.transactionreport',
        to: 'admin/transaction',
        iconStyle: "bi bi-file-earmark-text-fill",
        Permissions: ['admin'],
    },
    // {
    //     title: 'sidebar.menu.localization',
    //     iconStyle: "bi bi-sliders",
    //     to: 'localization-settings',
    //     Permissions: ['admin', 'restaurant']
    // },
    {
        title: 'sidebar.menu.configuration',
        classsChange: 'mm-collapse',
        iconStyle: "bi bi-gear-fill",
        Permissions: ['admin'],
        content: [
            {
                title: 'sidebar.submanu.systemconfiguration',
                to: 'admin/systemconfiguration',
            },
            {
                title: 'sidebar.submanu.voucherconfiguration',
                to: 'admin/voucherconfiguration',
            },
            {
                title: 'sidebar.submanu.servicefeerconfiguration',
                to: 'admin/servicefeeconfiguration',
            },
        ],
    },
]