import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useGlobalFilter, useSortBy, useTable } from 'react-table'
import swal from 'sweetalert'
import AddEditVoucherModal from './AddEditVoucherModal'
import { COLUMNSFORVOUCHER } from './VoucherColumns'
import ViewVoucherModal from './ViewVoucherModal'
import { Empty } from 'antd'
import { DeleteVoucherApi, getVoucherListApi } from '../../../services/voucherServices'
import TablePaginationHelper from '../../../helpers/Pagination'
import TableSearch from '../../../helpers/TableSearch'
import RestaurantSelect from '../Common/RestaurantSelect'

const Voucher = () => {
    const dispatch = useDispatch()
    const [voucherList, setVoucherList] = useState([])
    const [openModal, setOpenModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [viewData, setViewData] = useState({});
    const [editData, setEditData] = useState({});
    const { t } = useTranslation('common');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [RestaurantId, setRestaurantId] = useState('');

    useEffect(() => {
        if (RestaurantId) {
            dispatch(getVoucherListApi({ RestaurantId }, (res) => {
                setVoucherList(res.data)
            }))
        }
    }, [openModal, RestaurantId])

    const columns = COLUMNSFORVOUCHER
    const data = voucherList
    const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy)
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter, state } = tableInstance
    const { globalFilter } = state


    const handleEdit = (row) => {
        setEditData(row.original)
        setOpenModal(true)
    }

    const handleAddOpen = () => {
        setEditData()
        setOpenModal(true)
    }

    const handleView = (row) => {
        setViewData(row.original)
        setOpenViewModal(true)
    }


    const handleDelete = (row) => {
        const VoucherId = row.original.ID
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover!",
            icon: "warning",
            buttons: {
                confirm: 'Ok',
                cancel: 'Cancel'
            },
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal("Voucher has been deleted!", {
                    icon: "success",
                });
                dispatch(DeleteVoucherApi({ VoucherId }, (res) => {
                    dispatch(getVoucherListApi({ RestaurantId },(res) => {
                        setVoucherList(res.data)
                    }))
                }))

            }
        })
    }



    return (
        <>
            <div className="row pb-3">
                <RestaurantSelect setRestaurantId={setRestaurantId} />
            </div>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{t('voucherscreen.title')}</h4>
                    <div className='d-flex justify-content-md-around TableSerchInput'>
                        <TableSearch globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                       {RestaurantId &&  <Button onClick={handleAddOpen}>{t('buttons.addvoucher')}</Button>}
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <div className="dataTables_wrapper">
                            <table {...getTableProps()} className="table dataTable display">
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {t(column.render('Header'))}
                                                    <span className="ml-1">
                                                        {column.isSorted ? (
                                                            column.isSortedDesc ?
                                                                <i className="fa fa-arrow-down ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                                :
                                                                <i className="fa fa-arrow-up ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                        )
                                                            :
                                                            (<i className="fa fa-sort ms-2 fs-14" style={{ opacity: '0.3' }} />)
                                                        }
                                                    </span>
                                                </th>
                                            ))}
                                            <th className='fixedColumn text-end'>{t('table.action')}</th>
                                        </tr>
                                    ))}
                                </thead>
                                {tableInstance.rows && tableInstance.rows.length > 0 ? <tbody {...getTableBodyProps()}>
                                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()}>
                                                <td > {row.original.VoucherName} </td>
                                                <td >{t("currency", { Number: parseFloat(row.original.MinimumAmount) })}</td>
                                                <td >{t("currency", { Number: parseFloat(row.original.Price) })}</td>
                                                <td >{row.original.Quantity}</td>
                                                <td className='fixedColumn text-end'>
                                                    <button className='btn btn-info shadow btn-xs sharp me-1' onClick={() => handleView(row)} > <i className="fa-solid fa-eye"></i></button>
                                                    <button className='btn btn-primary shadow btn-xs sharp me-1' onClick={() => handleEdit(row)} > <i className="fas fa-pencil-alt"></i></button>
                                                    <button className='btn btn-danger shadow btn-xs sharp' onClick={() => handleDelete(row)} > <i className="fa fa-trash"></i></button>
                                                </td>

                                            </tr>
                                        )
                                    })}
                                </tbody> :
                                    <tbody>
                                        <tr>
                                            <td colSpan={6} className='text-center p-5'><Empty /></td>
                                        </tr>
                                    </tbody>}
                            </table>
                        </div>
                    </div>
                </div>
                {tableInstance.rows && tableInstance.rows.length > 0 && <TablePaginationHelper page={page} setPage={setPage} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage} totalnumber={tableInstance.rows.length} />}
            </div>
            {openModal && <AddEditVoucherModal openModal={openModal} setOpenModal={setOpenModal} editData={editData} setEditData={setEditData} RestaurantId={RestaurantId} />}
            {openViewModal && <ViewVoucherModal openViewModal={openViewModal} setOpenViewModal={setOpenViewModal} viewData={viewData} />}
        </>
    )
}

export default Voucher
