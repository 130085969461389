import * as React from 'react';
import TablePagination from '@mui/material/TablePagination';

export default function TablePaginationHelper(props) {
  const { page, setPage, rowsPerPage, setRowsPerPage, totalnumber } = props;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  return (
    <TablePagination
      component="div"
      className='align-items-baseline'
      count={totalnumber}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
}
