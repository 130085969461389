import { TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { symbolsArr } from '../../../constants/constants';
import { checkDelivaryAreaExist, updateDelivaryArea } from '../../../services/delivaryAreaServices';
import { useState } from 'react';


const DelivaryAreaAddEditModal = (props) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()
    const { openModal, setOpenModal, editData, setEditData, RestaurantId } = props
    const [errorFlag, setErrorFlag] = useState(false)

    const onsubmit = (values) => {
        values.RestaurantId = RestaurantId
        if (editData && editData.ID) {
            values.id = editData.ID
        }
        dispatch(updateDelivaryArea(values, (res) => {
            handleModalClose();
        }))
    }

    const handleModalClose = () => {
        setOpenModal(false);
        setEditData()
    }

    const handleChangeArea = (e, values) => {
        const obj = {};
        obj.RestaurantId = RestaurantId
        if (e.target.name == 'ToArea') {
            obj.ToArea = e.target.value;
            obj.FromArea = values.FromArea;
        }
        else if (e.target.name == 'FromArea') {
            obj.FromArea = e.target.value;
            obj.ToArea = values.ToArea
        }
        if (editData && editData.ID) {
            obj.EditFlag = true
            obj.id = editData.ID
        } else {
            obj.EditFlag = false
        }

        dispatch(checkDelivaryAreaExist(obj, (res) => {
            if (res.data) {
                setErrorFlag(true)
            } else {
                setErrorFlag(false)
            }
        }))

    }

    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openModal}
            // onHide={handleModalClose}
            size="lg"
            centered
        >
            <Formik
                // validateOnChange={false}
                // validateOnBlur={false}
                enableReinitialize
                initialValues={{
                    FromArea: editData && editData.StartRange ? editData.StartRange : 0,
                    ToArea: editData && editData.EndRange ? editData.EndRange : 0,
                }}

                validationSchema={
                    Yup.object().shape({
                        FromArea: Yup.number()
                            .required("From Area is required.")
                            .min(0, 'area cant be negative')
                            .test({
                                name: 'max',
                                exclusive: false,
                                params: {},
                                message: 'From area cant be greater then to area',
                                test: function (value) {
                                    return value <= parseFloat(this.parent.ToArea)
                                },
                            }),
                        ToArea: Yup.number().required("To Area is required.")
                            .test({
                                name: 'min',
                                exclusive: false,
                                params: {},
                                message: 'To area cant be less then From area',
                                test: function (value) {
                                    return value >= parseFloat(this.parent.FromArea)
                                },
                            }),
                    })
                }
                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Modal.Header>
                                <Modal.Title>{editData ? t('deliveryareascreen.updatedelivaryarea') : t('deliveryareascreen.adddeliveryarea')}</Modal.Title>
                                <Button
                                    variant=""
                                    className="btn-close"
                                    onClick={handleModalClose}
                                >
                                </Button>
                            </Modal.Header>
                            <Form onSubmit={handleSubmit} noValidate>
                                <Modal.Body>
                                    <div className="row">
                                        <div className='col-6 pt-3'>
                                            <TextField
                                                required
                                                type={'number'}
                                                InputProps={{ inputProps: { min: 0 } }}
                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                error={Boolean(errors.FromArea) && touched.FromArea}
                                                helperText={(Boolean(errors.FromArea) && touched.FromArea) ? errors.FromArea : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="FromArea"
                                                label={t('deliveryareascreen.fromarea')}
                                                value={values.FromArea}
                                                onChange={(e) => { handleChange(e); handleChangeArea(e, values) }}
                                            />
                                        </div>
                                        <div className='col-6 pt-3'>
                                            <TextField
                                                required
                                                type={'number'}
                                                InputProps={{ inputProps: { min: 0 } }}
                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                error={Boolean(errors.ToArea) && touched.ToArea}
                                                helperText={(Boolean(errors.ToArea) && touched.ToArea) ? errors.ToArea : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="ToArea"
                                                label={t('deliveryareascreen.toarea')}
                                                value={values.ToArea}
                                                onChange={(e) => { handleChange(e); handleChangeArea(e, values) }}
                                            />
                                        </div>
                                        {(errorFlag && Object.keys(errors).length == 0) ? <p className='Mui-error text-red pt-3'>This range is already available</p> : ''}
                                    </div>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={handleModalClose}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        size="large"
                                        disabled={errorFlag || Object.keys(errors).length > 0}
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        </>
                    );
                }}
            </Formik>

        </Modal >
    )
}

export default DelivaryAreaAddEditModal
