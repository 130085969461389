import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const AuthVerify = (props) => {
    let location = useLocation();

    useEffect(() => {
        const token = localStorage.getItem("token");

        if (!token) {
            props.logOut();
        }
    }, [location, props]);

    return;
};

export default AuthVerify;