import { useLocation, Navigate, Outlet } from "react-router-dom";

import React from "react";
import { useSelector } from "react-redux";
import { userRole } from "./constants/constants";

const Auth = ({ allowedRoles }) => {
    const { auth } = useSelector((state) => state.auth);
    const location = useLocation();

    let UserRoleName  = userRole.filter((item)=>{
        return item.id == auth.user_type_id
    })
 

    return allowedRoles.find((role) => role.includes(UserRoleName[0].role)) ? (
        <Outlet />
    ) : (
        <Navigate to="/404" state={{ from: location }} replace />
    );
};

export default Auth;