
export const COLUMNSFORPROMOCODES = [
	
	{
		Header : 'promocodescreen.coupencode',
		accessor: 'CouponCode'
	},
	{
		Header : 'promocodescreen.fromdate',
		accessor: 'FromDate'
	},
	{
		Header : 'promocodescreen.todate',
		accessor: 'ToDate',
	},
	{
		Header : 'promocodescreen.maxuser',
		accessor: 'MaxUser'
	},
	{
		Header : 'promocodescreen.timesperuser',
		accessor: 'TimesPerUser'
	},
]
