import { GET_RESTAURANT_LIST } from "../../constants/ActionTypes";


const INIT_STATE = {
    restaurantList: [],
};

export function restaurantList(state = INIT_STATE, action) {
    switch (action.type) {
        case GET_RESTAURANT_LIST: {
            return {
                ...state,
                restaurantList: action.payload,
            };
        }
        default:
            return state;
    }
};
