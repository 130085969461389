import React from 'react'
import { paymentGetways } from '../../../constants/constants'
import { useTranslation } from 'react-i18next'
import { Button } from 'react-bootstrap'
import { Switch } from '@mui/material'
import { useEffect } from 'react'
import { useState } from 'react'
import { GetPaymentData, GetPaymentGatewayList, UpdatePaymentGatewayStatus } from '../../../services/RestaurantServices'
import { useDispatch } from 'react-redux'
import StripeEditModal from '../Restaurant/PaymentModal/StripeEditModal'
import CashEditModal from '../Restaurant/PaymentModal/CashEditModal'
import VivaEditModal from '../Restaurant/PaymentModal/VivaEditModal'
import PaymentSenseEditModal from '../Restaurant/PaymentModal/PaymentSenseEditModal'

const PaymentOptions = () => {
    const { t } = useTranslation('common')
    const dispatch = useDispatch()
    const [paymentGateWayList, setPaymentGateWayList] = useState([]);
    const [paymentData, setPaymentData] = useState({});
    const [openStripeModal, setOpenStripeModal] = useState(false);
    const [openCashModal, setOpenCashModal] = useState(false);
    const [openVivaModal, setOpenVivaModal] = useState(false);
    const [openPaymentSenseModal, setOpenPaymentSenseModal] = useState(false);
    const [settingData, setSettingData] = useState({
        RID: ''
    });

    useEffect(() => {
        const RestaurantId = ''
        dispatch(GetPaymentGatewayList({ RestaurantId }, (res) => {
            setPaymentGateWayList(res)
        }))
    }, [openStripeModal, openCashModal, openVivaModal, openPaymentSenseModal])

    const handleChangeForEnable = (e, row) => {
        let obj = JSON.parse(JSON.stringify(row))
        row.IsActive = e.target.checked ? 1 : 0
        obj.IsActive = e.target.checked ? 1 : 0
        dispatch(UpdatePaymentGatewayStatus(obj, (res) => {

        }))
    }

    const handlePaymentSettings = (item) => {
        const obj = {
            RestaurantId: '',
            PaymentType: item.PaymentType,
        }
        dispatch(GetPaymentData(obj, (res) => {
            setPaymentData(res[0])
        }))
        if (item.PaymentType == 1) {
            setOpenCashModal(true)
        } else if (item.PaymentType == 10) {
            setOpenVivaModal(true)
        } else if (item.PaymentType == 4) {
            setOpenPaymentSenseModal(true)
        } else if (item.PaymentType == 11) {
            setOpenStripeModal(true)
        }
    }

    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">{t('profile.payment.paymentconfig') + '(Foodel)'}</h4>
            </div>
            <div className="card-body">
                <table className="table table-borderless">
                    <thead>
                        <tr>
                            <th scope="col">{t('profile.payment.paymenttype')}</th>
                            <th scope="col">{t('profile.payment.enabled')}</th>
                            <th scope="col" className='text-center'>{t('table.action')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {paymentGateWayList && paymentGateWayList.length > 0 && paymentGateWayList.map((item, index) => {
                        // let checked = paymentGateWayList.filter((items) => items.PaymentType == item.id)
                        return <tr key={index}>
                            <th scope="row" style={{ width: '65%' }}>{item.PaymentMode}</th>
                            <td style={{ width: '15%' }}>
                                <Switch
                                    checked={item.IsActive == '1' ? true : false}
                                    onChange={(e) => handleChangeForEnable(e, item)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </td>
                            <td className='text-end' style={{ width: '20%' }}>
                                <Button variant="outline-info" onClick={() => { handlePaymentSettings(item) }}>{t('profile.payment.manage')}</Button>
                            </td>
                        </tr>
                    })} */}
                        {paymentGetways && paymentGetways.length > 0 && paymentGetways.map((item, index) => {
                            let checked = paymentGateWayList.filter((items) => items.PaymentType == item.PaymentType)
                            return <tr key={index}>
                                <th scope="row" style={{ width: '65%' }}>{item.name}</th>
                                <td style={{ width: '15%' }}>
                                    <Switch
                                        disabled={checked.length <= 0 ? true : false}
                                        checked={checked[0]?.IsActive == '1' ? true : false}
                                        onChange={(e) => handleChangeForEnable(e, checked[0])}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </td>
                                <td className='text-end' style={{ width: '20%' }}>
                                    <Button variant="outline-info" onClick={() => { handlePaymentSettings(item) }}>{t('profile.payment.manage')}</Button>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            {openStripeModal && <StripeEditModal openStripeModal={openStripeModal} setOpenStripeModal={setOpenStripeModal} paymentData={paymentData} settingData={settingData} />}
            {openCashModal && <CashEditModal openCashModal={openCashModal} setOpenCashModal={setOpenCashModal} paymentData={paymentData} settingData={settingData} />}
            {openVivaModal && <VivaEditModal openVivaModal={openVivaModal} setOpenVivaModal={setOpenVivaModal} paymentData={paymentData} settingData={settingData} />}
            {openPaymentSenseModal && <PaymentSenseEditModal openPaymentSenseModal={openPaymentSenseModal} setOpenPaymentSenseModal={setOpenPaymentSenseModal} paymentData={paymentData} settingData={settingData} />}
        </div>
    )
}

export default PaymentOptions
