import React, { useState, useEffect } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import LogoutPage from './Logout';
import { useSelector } from "react-redux";
import ChangePassword from "../../pages/ChangePassword";
import { useTranslation } from "react-i18next";
import { Avatar } from "@mui/material";
import { S3ImageUpload } from "../../../constants/constants";

const Header = ({ onNote }) => {
	const { t } = useTranslation('common')
	const [headerFix, setheaderFix] = useState(false);
	const [openModal, setOpenModal] = useState(false);

	const { auth } = useSelector((state) => state.auth);


	useEffect(() => {
		setTimeout(function () {
			// Dropdown 
			const dropbtn = document.getElementById("droptoggle1")
			//let dropTooglebtn = dropbtn.getAttribute("aria-expanded");
			function toggledrop() {
				return dropbtn.classList.toggle("show")
			}
			dropbtn.addEventListener("click", toggledrop);
		}, 500);

		// for header fix on scroll
		window.addEventListener("scroll", () => {
			setheaderFix(window.scrollY > 50);
		});
	}, []);

	return (
		<div className={`header ${headerFix ? "is-fixed" : ""}`}>
			<div className="header-content">
				<nav className="navbar navbar-expand">
					<div className="container d-block my-0">
						<div className="d-flex align-items-center justify-content-end">
							<ul className="navbar-nav header-right">
								<li>
									<Dropdown className=" header-profile2">
										<Dropdown.Toggle as="a" className={`nav-link i-false cursor-pointer `} id="droptoggle1"
										//onClick={DropBtnblog()}
										>

											<div className="header-info2 d-flex align-items-center">
											
												<Avatar src={`${S3ImageUpload}adminprofile/${auth?.HeaderLogo_URL}`} alt="" />
												<div className="d-flex align-items-center sidebar-info">
													{auth?.is_admin_login == '1' ? <h6 className="font-w500 mb-0 ms-2 headerName">foodel admin</h6> : <div>
														<h6 className="font-w500 mb-0 ms-2 headerName">{auth?.full_name}</h6>
													</div>}
													<i className="fas fa-chevron-down"></i>
												</div>
											</div>
										</Dropdown.Toggle>
										<Dropdown.Menu className="dropdown-menu-end">
											{auth?.is_admin_login == '1' ? <h6 className="font-w500 mb-0 ms-2 headerdpName">foodel admin</h6>
												:
												<div>
													<h6 className="font-w500 mb-0 ms-2 headerdpName">{auth?.full_name}</h6>
												</div>}

											<Link to="./profile" className="dropdown-item ai-icon ">
												<svg xmlns="http://www.w3.org/2000/svg" className="text-primary" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
												<span className="ms-2">Account</span>
											</Link>
											<button className="dropdown-item ai-icon ms-1 log-btn" onClick={() => { setOpenModal(true) }}>
												<i className="fa fa-key text-primary"></i>
												<span className="ms-2 ">{t('buttons.changepassword')}</span>
											</button>
											<LogoutPage />
										</Dropdown.Menu>
									</Dropdown>
								</li>
							</ul>
							{openModal && <ChangePassword openModal={openModal} setOpenModal={setOpenModal} />}
						</div>
					</div>
				</nav>
			</div>
		</div>
	);
};

export default Header;
