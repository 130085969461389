
export const COLUMNFORSERVICEFEES = [

	{
		Header: 'servicefeeconfigscreen.restaurantname',
		accessor: 'RestaurantName'
	},
	{
		Header: 'servicefeeconfigscreen.type',
		accessor: 'ContactNumber'
	},
	{
		Header: 'servicefeeconfigscreen.servicefee',
		accessor: 'EmailAddress'
	},
	{
		Header: 'isActive',
		accessor: 'IsActive'
	},
]
