import { Checkbox, FormControl, FormControlLabel, MenuItem, TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { Button, Modal, Nav, Tab } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { CashPaymentManage } from '../../../../services/RestaurantServices';


const CashEditModal = (props) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()
    const { openCashModal, setOpenCashModal, paymentData, settingData, handleSettings } = props


    const onsubmit = (values) => {
        if (paymentData) {
            values.ID = paymentData.ID
        }
        values.RestaurantId = settingData.RID
        dispatch(CashPaymentManage(values, (res) => {
            setTimeout(() => {
                handleModalClose()
                handleSettings(settingData)
            }, 1);
        }))
    }

    const handleModalClose = () => {
        setOpenCashModal(false);

    }


    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openCashModal}
            // onHide={handleModalClose}
            size="lg"
            centered
        >

            <Formik
                enableReinitialize
                initialValues={{
                    Payment_Enable: paymentData && paymentData.IsActive == '0' ? false : true,
                    Payment_Name: paymentData && paymentData.Payment_Name ? paymentData.Payment_Name : '',
                    Payment_Description: paymentData && paymentData.Payment_Description ? paymentData.Payment_Description : "",
                }}
                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Modal.Header>
                                <Modal.Title>{t('profile.payment.settings')}</Modal.Title>
                                <Button
                                    variant=""
                                    className="btn-close"
                                    onClick={handleModalClose}
                                >

                                </Button>
                            </Modal.Header>
                            <Form onSubmit={handleSubmit} noValidate>
                                <Modal.Body>
                                    <div className="row">
                                        <div className='col-12'>
                                            <FormControlLabel
                                                control={<Checkbox checked={values.Payment_Enable} />}
                                                label={`Enable Cash`}
                                                name="Payment_Enable"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12'>
                                            <TextField
                                                required
                                                error={Boolean(errors.Payment_Name) && touched.Payment_Name}
                                                helperText={(Boolean(errors.Payment_Name) && touched.Payment_Name) ? errors.Payment_Name : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="Payment_Name"
                                                label={t('profile.payment.name')}
                                                value={values.Payment_Name}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                multiline
                                                rows={3}
                                                error={Boolean(errors.Payment_Description) && touched.Payment_Description}
                                                helperText={(Boolean(errors.Payment_Description) && touched.Payment_Description) ? errors.Payment_Description : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="Payment_Description"
                                                label={t('profile.payment.description')}
                                                value={values.Payment_Description}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={handleModalClose}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        size="large"
                                    // disabled={!isValid || isDisabled}
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        </>
                    );
                }}
            </Formik>

        </Modal >

    )
}

export default CashEditModal
