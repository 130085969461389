import React from "react";
import { Dialog } from "@mui/material";
import ImageCropper from "./ImageCropper";


const ImageModal = ({ modalIsOpen, closeModal, image, onCropImage, ratio, type, imageWidth, imageHeight }) => {

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={modalIsOpen}
        onClose={closeModal}
      >
        {image && (
          <ImageCropper
            imgName={image.name}
            onCropImage={onCropImage}
            inputImg={URL.createObjectURL(image)}
            closeModal={closeModal}
            ratio={ratio}
            type={type}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
          />
        )}
      </Dialog>
    </div>
  );
};

export default ImageModal;
