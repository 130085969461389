
export const TRANSACTIONCOLUMNS = [
	{
		Header : 'transactionscreen.orderid',
		accessor: 'OrderId'
	},
	{
		Header : 'transactionscreen.restaurant',
		accessor: 'RestaurantName'
	},
	{
		Header : 'transactionscreen.paymenttype',
		accessor: 'PaymentType'
	},
	{
		Header : 'transactionscreen.status',
		accessor: 'Status'
	},
	{
		Header : 'transactionscreen.orderquantity',
		accessor: 'Quantity'
	},
	{
		Header : 'transactionscreen.subtotal',
		accessor: 'SubTotal'
	},
	{
		Header : 'transactionscreen.delivaryfee',
		accessor: 'ShippingCharge'
	},
	{
		Header : 'transactionscreen.servicecharge',
		accessor: 'ServiceCharge'
	},
	{
		Header : 'transactionscreen.smallorderfees',
		accessor: 'SmallOrderFee'
	},
	{
		Header : 'transactionscreen.discount',
		accessor: 'Discount'
	},
	{
		Header : 'transactionscreen.grandtotal',
		accessor: 'GrandTotal'
	},
	{
		Header : 'transactionscreen.orderdate',
		accessor: 'OrderDateTime'
	}
]
