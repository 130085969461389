import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// image
import logo from "../../images/logo-full.png";
import { forgotPassword } from "../../services/AuthService";
import { Button } from "react-bootstrap";


const ForgotPassword = ({ history }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [email, setEmail] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();

    const obj = {
      email: email
    }

    dispatch(forgotPassword(obj, (res) => {
      if (res.status == 1) {
        navigate("/admin/login");
      }
    }))
  };

  const handleCancle = () => {
    navigate("/admin/login");
  }


  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        {" "}
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <h4 className="text-center mb-4 ">Forgot Password</h4>
                    <form onSubmit={(e) => onSubmit(e)}>
                      <div className="form-group">
                        <label className="mb-1"><strong>Email Address</strong></label>
                        <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                      </div>
                      <div className="text-center mt-3">
                        <div display="flex" className="text-end" mb={4}>
                          <Button variant="contained" color="primary" onClick={handleCancle}>Cancel</Button>
                          <Button type="submit">
                            SUBMIT
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
