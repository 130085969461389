
export const RATINGSCOLUMNS = [
	{
		Header: 'Ratingscreen.ratingid',
		accessor: 'ID'
	},
	{
		Header: 'Ratingscreen.restaurantname',
		accessor: 'RestaurantName'
	},
	{
		Header: 'Ratingscreen.userreview',
		accessor: 'UserReview'
	},
	{
		Header: 'Ratingscreen.userrating',
		accessor: 'UserRating'
	},
	{
		Header: 'Ratingscreen.date',
		accessor: 'CreatedDateTime'
	}
]