import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControl, FormControlLabel, FormGroup, InputAdornment, MenuItem, OutlinedInput, Select, TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { Button, Modal, Nav, Tab } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { stripeShortStatementLimit, stripeStatementLimit, symbolsArr } from '../../../../constants/constants';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { stripePaymentManage } from '../../../../services/RestaurantServices';


const StripeEditModal = (props) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()
    const { openStripeModal, setOpenStripeModal, paymentData, settingData, handleSettings } = props
    const [expanded, setExpanded] = React.useState(false);

    const onsubmit = (values) => {
        if (paymentData) {
            values.ID = paymentData.ID
        }
        values.RestaurantId = settingData.RID
        dispatch(stripePaymentManage(values, (res) => {
            setTimeout(() => {
                handleModalClose()
                handleSettings(settingData)
            }, 1);
        }))
    }

    const handleModalClose = () => {
        setOpenStripeModal(false);
    }

    const handleChangeAdvance = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };



    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openStripeModal}
            // onHide={handleModalClose}
            size="lg"
            centered
        >

            <Formik
                enableReinitialize
                initialValues={{
                    Payment_Enable: paymentData && paymentData.IsActive == '0' ? false : true,
                    Payment_Name: paymentData && paymentData.Payment_Name ? paymentData.Payment_Name : '',
                    Payment_Description: paymentData && paymentData.Payment_Description ? paymentData.Payment_Description : '',
                    Payment_Enable_TestMode: paymentData && paymentData.Payment_Enable_TestMode == '1' ? true : false,
                    Payment_Enable_ViaSavedCard: paymentData && paymentData.Payment_Enable_ViaSavedCard == '1' ? true : false,
                    Payment_Amount_Percent: paymentData && paymentData.Payment_Amount_Percent ? paymentData.Payment_Amount_Percent : '',
                    Payment_Order_Status: paymentData && paymentData.Payment_Order_Status ? paymentData.Payment_Order_Status : '',
                    Payment_Full_Statement: paymentData && paymentData.Payment_Full_Statement ? paymentData.Payment_Full_Statement : '',
                    Payment_Short_Statement: paymentData && paymentData.Payment_Short_Statement ? paymentData.Payment_Short_Statement : '',
                    Payment_Pre_Auth: paymentData && paymentData.Payment_Pre_Auth == '1' ? true : false,
                    Payment_Application_Fee_Refundable: paymentData && paymentData.Payment_Application_Fee_Refundable == '1' ? true : false,
                    Payment_Log_ErrorMessage: paymentData && paymentData.Payment_Log_ErrorMessage == '1' ? true : false,

                    Stripe_Platform_Key: paymentData && paymentData.Stripe_Platform_Key ? paymentData.Stripe_Platform_Key : '',
                    Stripe_Platform_Secret: paymentData && paymentData.Stripe_Platform_Secret ? paymentData.Stripe_Platform_Secret : '',
                    Stripe_Connect_Account: paymentData && paymentData.Stripe_Connect_Account ? paymentData.Stripe_Connect_Account : '',
                    Stripe_Connect_Key: paymentData && paymentData.Stripe_Connect_Key ? paymentData.Stripe_Connect_Key : '',
                    Stripe_Connect_Secret: paymentData && paymentData.Stripe_Connect_Secret ? paymentData.Stripe_Connect_Secret : '',
                    Stripe_Platform_Key_Test: paymentData && paymentData.Stripe_Platform_Key_Test ? paymentData.Stripe_Platform_Key_Test : '',
                    Stripe_Platform_Secret_Test: paymentData && paymentData.Stripe_Platform_Secret_Test ? paymentData.Stripe_Platform_Secret_Test : '',
                    Stripe_Connect_Account_Test: paymentData && paymentData.Stripe_Connect_Account_Test ? paymentData.Stripe_Connect_Account_Test : '',
                    Stripe_Connect_Key_Test: paymentData && paymentData.Stripe_Connect_Key_Test ? paymentData.Stripe_Connect_Key_Test : '',
                    Stripe_Connect_Secret_Test: paymentData && paymentData.Stripe_Connect_Secret_Test ? paymentData.Stripe_Connect_Secret_Test : '',
                }}
                validationSchema={
                    Yup.object().shape({
                        Payment_Name: Yup.string().required("name is required."),
                        Stripe_Platform_Key: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: false,
                                then: Yup.string().required("Platform key is required")
                            }),
                        Stripe_Platform_Secret: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: false,
                                then: Yup.string().required("Platform secret is required")
                            }),
                        Stripe_Connect_Account: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: false,
                                then: Yup.string().required("Connect account is required")
                            }),
                        Stripe_Connect_Key: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: false,
                                then: Yup.string().required("Connect key is required")
                            }),
                        Stripe_Connect_Secret: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: false,
                                then: Yup.string().required("Conncet secret is required")
                            }),
                        Stripe_Platform_Key_Test: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: true,
                                then: Yup.string().required("Platform key is required")
                            }),
                        Stripe_Platform_Secret_Test: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: true,
                                then: Yup.string().required("Platform secret is required")
                            }),
                        Stripe_Connect_Account_Test: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: true,
                                then: Yup.string().required("Connect account is required")
                            }),
                        Stripe_Connect_Key_Test: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: true,
                                then: Yup.string().required("Connect key is required")
                            }),
                        Stripe_Connect_Secret_Test: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: true,
                                then: Yup.string().required("Conncet secret is required")
                            })
                    })
                }
                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Modal.Header>
                                <Modal.Title>{t('profile.payment.settings')}</Modal.Title>
                                <Button
                                    variant=""
                                    className="btn-close"
                                    onClick={handleModalClose}
                                >

                                </Button>
                            </Modal.Header>
                            <Form onSubmit={handleSubmit} noValidate>
                                <Modal.Body>
                                    <div className="row">
                                        <div className='col-12'>
                                            <FormControl variant="standard">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={values.Payment_Enable}
                                                            name="Payment_Enable"
                                                            onChange={handleChange}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    }
                                                    label={`Enable Stripe`}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="col-12 pt-3">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h4 className="card-title">{t('profile.payment.displaysettings')}</h4>
                                                </div>
                                                <div className="card-body">
                                                    <div className='col-12'>
                                                        <TextField
                                                            required
                                                            error={Boolean(errors.Payment_Name) && touched.Payment_Name}
                                                            helperText={(Boolean(errors.Payment_Name) && touched.Payment_Name) ? errors.Payment_Name : ''}
                                                            fullWidth
                                                            onBlur={handleBlur}
                                                            name="Payment_Name"
                                                            label={t('profile.payment.name')}
                                                            value={values.Payment_Name}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className='col-12 pt-3'>
                                                        <TextField
                                                            multiline
                                                            rows={3}
                                                            error={Boolean(errors.Payment_Description) && touched.Payment_Description}
                                                            helperText={(Boolean(errors.Payment_Description) && touched.Payment_Description) ? errors.Payment_Description : ''}
                                                            fullWidth
                                                            onBlur={handleBlur}
                                                            name="Payment_Description"
                                                            label={t('profile.payment.description')}
                                                            value={values.Payment_Description}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className='col-12'>
                                                        <FormControlLabel
                                                            control={<Checkbox checked={values.Payment_Enable_TestMode} />}
                                                            label={t('profile.payment.enabletestmode')}
                                                            name="Payment_Enable_TestMode"
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className='col-12 '>
                                                        <Tab.Container defaultActiveKey='live'>
                                                            <Nav as='ul' className="nav nav-tabs">
                                                                <Nav.Item as='li' className="nav-item">
                                                                    <Nav.Link to="#live" eventKey='live'>{t('profile.payment.live')}</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item as='li' className="nav-item">
                                                                    <Nav.Link to="#test" eventKey='test'>{t('profile.payment.test')}</Nav.Link>
                                                                </Nav.Item>
                                                            </Nav>
                                                            <Tab.Content>
                                                                <Tab.Pane id="live" eventKey='live'>
                                                                    <div className="row pt-3">
                                                                        <div className="col-12 pt-3">
                                                                            <TextField
                                                                                required
                                                                                error={Boolean(errors.Stripe_Platform_Key) && touched.Stripe_Platform_Key}
                                                                                helperText={(Boolean(errors.Stripe_Platform_Key) && touched.Stripe_Platform_Key) ? errors.Stripe_Platform_Key : ''}
                                                                                fullWidth
                                                                                onBlur={handleBlur}
                                                                                name="Stripe_Platform_Key"
                                                                                label={t('profile.payment.plateformkey')}
                                                                                value={values.Stripe_Platform_Key}
                                                                                onChange={handleChange}
                                                                            />
                                                                        </div>
                                                                        <div className="col-12 pt-3">
                                                                            <TextField
                                                                                required
                                                                                error={Boolean(errors.Stripe_Platform_Secret) && touched.Stripe_Platform_Secret}
                                                                                helperText={(Boolean(errors.Stripe_Platform_Secret) && touched.Stripe_Platform_Secret) ? errors.Stripe_Platform_Secret : ''}
                                                                                fullWidth
                                                                                onBlur={handleBlur}
                                                                                name="Stripe_Platform_Secret"
                                                                                label={t('profile.payment.platformsecret')}
                                                                                value={values.Stripe_Platform_Secret}
                                                                                onChange={handleChange}
                                                                            />
                                                                        </div>
                                                                        <div className="col-12 pt-3">
                                                                            <TextField
                                                                                required
                                                                                error={Boolean(errors.Stripe_Connect_Account) && touched.Stripe_Connect_Account}
                                                                                helperText={(Boolean(errors.Stripe_Connect_Account) && touched.Stripe_Connect_Account) ? errors.Stripe_Connect_Account : ''}
                                                                                fullWidth
                                                                                onBlur={handleBlur}
                                                                                name="Stripe_Connect_Account"
                                                                                label={t('profile.payment.connectaccount')}
                                                                                value={values.Stripe_Connect_Account}
                                                                                onChange={handleChange}
                                                                            />
                                                                        </div>
                                                                        <div className="col-12 pt-3">
                                                                            <TextField
                                                                                required
                                                                                error={Boolean(errors.Stripe_Connect_Key) && touched.Stripe_Connect_Key}
                                                                                helperText={(Boolean(errors.Stripe_Connect_Key) && touched.Stripe_Connect_Key) ? errors.Stripe_Connect_Key : ''}
                                                                                fullWidth
                                                                                onBlur={handleBlur}
                                                                                name="Stripe_Connect_Key"
                                                                                label={t('profile.payment.connectkey')}
                                                                                value={values.Stripe_Connect_Key}
                                                                                onChange={handleChange}
                                                                            />
                                                                        </div>
                                                                        <div className="col-12 pt-3">
                                                                            <TextField
                                                                                required
                                                                                error={Boolean(errors.Stripe_Connect_Secret) && touched.Stripe_Connect_Secret}
                                                                                helperText={(Boolean(errors.Stripe_Connect_Secret) && touched.Stripe_Connect_Secret) ? errors.Stripe_Connect_Secret : ''}
                                                                                fullWidth
                                                                                onBlur={handleBlur}
                                                                                name="Stripe_Connect_Secret"
                                                                                label={t('profile.payment.connectsecret')}
                                                                                value={values.Stripe_Connect_Secret}
                                                                                onChange={handleChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Tab.Pane>
                                                                <Tab.Pane id="test" eventKey='test'>
                                                                    <div className="row pt-3">
                                                                        <div className="col-12 pt-3">
                                                                            <TextField
                                                                                required
                                                                                error={Boolean(errors.Stripe_Platform_Key_Test) && touched.Stripe_Platform_Key_Test}
                                                                                helperText={(Boolean(errors.Stripe_Platform_Key_Test) && touched.Stripe_Platform_Key_Test) ? errors.Stripe_Platform_Key_Test : ''}
                                                                                fullWidth
                                                                                onBlur={handleBlur}
                                                                                name="Stripe_Platform_Key_Test"
                                                                                label={t('profile.payment.plateformkey')}
                                                                                value={values.Stripe_Platform_Key_Test}
                                                                                onChange={handleChange}
                                                                            />
                                                                        </div>
                                                                        <div className="col-12 pt-3">
                                                                            <TextField
                                                                                required
                                                                                error={Boolean(errors.Stripe_Platform_Secret_Test) && touched.Stripe_Platform_Secret_Test}
                                                                                helperText={(Boolean(errors.Stripe_Platform_Secret_Test) && touched.Stripe_Platform_Secret_Test) ? errors.Stripe_Platform_Secret_Test : ''}
                                                                                fullWidth
                                                                                onBlur={handleBlur}
                                                                                name="Stripe_Platform_Secret_Test"
                                                                                label={t('profile.payment.platformsecret')}
                                                                                value={values.Stripe_Platform_Secret_Test}
                                                                                onChange={handleChange}
                                                                            />
                                                                        </div>
                                                                        <div className="col-12 pt-3">
                                                                            <TextField
                                                                                required
                                                                                error={Boolean(errors.Stripe_Connect_Account_Test) && touched.Stripe_Connect_Account_Test}
                                                                                helperText={(Boolean(errors.Stripe_Connect_Account_Test) && touched.Stripe_Connect_Account_Test) ? errors.Stripe_Connect_Account_Test : ''}
                                                                                fullWidth
                                                                                onBlur={handleBlur}
                                                                                name="Stripe_Connect_Account_Test"
                                                                                label={t('profile.payment.connectaccount')}
                                                                                value={values.Stripe_Connect_Account_Test}
                                                                                onChange={handleChange}
                                                                            />
                                                                        </div>
                                                                        <div className="col-12 pt-3">
                                                                            <TextField
                                                                                required
                                                                                error={Boolean(errors.Stripe_Connect_Key_Test) && touched.Stripe_Connect_Key_Test}
                                                                                helperText={(Boolean(errors.Stripe_Connect_Key_Test) && touched.Stripe_Connect_Key_Test) ? errors.Stripe_Connect_Key_Test : ''}
                                                                                fullWidth
                                                                                onBlur={handleBlur}
                                                                                name="Stripe_Connect_Key_Test"
                                                                                label={t('profile.payment.connectkey')}
                                                                                value={values.Stripe_Connect_Key_Test}
                                                                                onChange={handleChange}
                                                                            />
                                                                        </div>
                                                                        <div className="col-12 pt-3">
                                                                            <TextField
                                                                                required
                                                                                error={Boolean(errors.Stripe_Connect_Secret_Test) && touched.Stripe_Connect_Secret_Test}
                                                                                helperText={(Boolean(errors.Stripe_Connect_Secret_Test) && touched.Stripe_Connect_Secret_Test) ? errors.Stripe_Connect_Secret_Test : ''}
                                                                                fullWidth
                                                                                onBlur={handleBlur}
                                                                                name="Stripe_Connect_Secret_Test"
                                                                                label={t('profile.payment.connectsecret')}
                                                                                value={values.Stripe_Connect_Secret_Test}
                                                                                onChange={handleChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Tab.Pane>
                                                            </Tab.Content>
                                                        </Tab.Container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h4 className="card-title">{t('profile.payment.accountdetails')}</h4>
                                                </div>
                                                <div className="card-body">
                                                    <p className='ps-2'><span className='font-w600 fs-16'>{t('profile.payment.payment')}:</span> <span className='text-red'><i className="fa-solid fa-eye"></i> enable</span></p>
                                                    <p className='ps-2'><span className='font-w600 fs-16'> {t('profile.payment.payout')}:</span> <span className='text-red'><i className="fa-solid fa-eye"></i> enable</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h4 className="card-title">{t('profile.payment.paymentandtransaction')}</h4>
                                                </div>
                                                <div className="card-body">
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <h6>{t('profile.payment.paymentsettings')}</h6>
                                                            </div>
                                                            <div className='col-12'>
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={values.Payment_Enable_ViaSavedCard} />}
                                                                    label={t('profile.payment.enableViaSavedCard')}
                                                                    name="Payment_Enable_ViaSavedCard"
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            <div className="col-12 pt-3">
                                                                <h6>{t('profile.payment.transactionpreferences')}</h6>
                                                            </div>
                                                            <div className='col-12'>
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={values.Payment_Pre_Auth} />}
                                                                    label={t('profile.payment.issueanAuth')}
                                                                    name="Payment_Pre_Auth"
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            <div className='col-12'>
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={values.Payment_Application_Fee_Refundable} />}
                                                                    label={t('profile.payment.appfeerefundeable')}
                                                                    name="Payment_Application_Fee_Refundable"
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            <div className="col-12 ">
                                                                <FormControl sx={{ width: 200 }} className='me-2'>
                                                                    <TextField
                                                                        select
                                                                        fullWidth
                                                                        value={values.Payment_Order_Status}
                                                                        label={t('profile.payment.optionorderstatus')}
                                                                        name='Payment_Order_Status'
                                                                        onChange={handleChange}
                                                                    >
                                                                        <MenuItem key={1} value={1} >1</MenuItem>
                                                                        <MenuItem key={2} value={2} >2</MenuItem>
                                                                        <MenuItem key={3} value={3} >3</MenuItem>
                                                                    </TextField>
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-12 pt-3">
                                                                <FormControl sx={{ width: 200 }} className='me-2'>
                                                                    <TextField
                                                                        type='number'
                                                                        InputProps={{ inputProps: { min: 0 } }}
                                                                        onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                                        fullWidth
                                                                        onBlur={handleBlur}
                                                                        name="Payment_Amount_Percent"
                                                                        label={t('profile.payment.amountpercent')}
                                                                        value={values.Payment_Amount_Percent}
                                                                        onChange={handleChange}
                                                                    />
                                                                </FormControl>
                                                            </div>

                                                            <div className="col-12 pt-4">
                                                                <h6>{t('profile.payment.customerbankstatement')}</h6>
                                                            </div>
                                                            <div className="col-12">
                                                                <TextField
                                                                    inputProps={{ maxLength: stripeStatementLimit }}
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    name="Payment_Full_Statement"
                                                                    InputProps={{
                                                                        endAdornment: <InputAdornment position="end">{`${stripeStatementLimit - values.Payment_Full_Statement.length}/${stripeStatementLimit}`}</InputAdornment>,
                                                                    }}
                                                                    label={t('profile.payment.fullbankstatement')}
                                                                    value={values.Payment_Full_Statement}
                                                                    onChange={(e) => { handleChange(e); }}
                                                                />
                                                            </div>
                                                            <div className="col-12 pt-3">
                                                                <TextField
                                                                    inputProps={{ maxLength: stripeShortStatementLimit }}
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        endAdornment: <InputAdornment position="end">{`${stripeShortStatementLimit - values.Payment_Short_Statement.length}/${stripeShortStatementLimit}`}</InputAdornment>,
                                                                    }}
                                                                    name="Payment_Short_Statement"
                                                                    label={t('profile.payment.shortbankstatement')}
                                                                    value={values.Payment_Short_Statement}
                                                                    onChange={(e) => { handleChange(e); }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <Accordion className='box-shadow-none' expanded={expanded == 'advance'} onChange={handleChangeAdvance('advance')}>
                                                <AccordionSummary
                                                    className='d-inline-flex'
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                    {t('profile.payment.advancesettings')}
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={values.Payment_Log_ErrorMessage} />}
                                                        label={t('profile.payment.logerrormessage')}
                                                        name="Payment_Log_ErrorMessage"
                                                        onChange={handleChange}
                                                    />
                                                    <br />
                                                    <div className="pt-3">
                                                        <Button
                                                            variant="danger light"
                                                        >
                                                            {t('profile.payment.clearlog')}
                                                        </Button>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    </div>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={handleModalClose}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        size="large"
                                    // disabled={!isValid || isDisabled}
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        </>
                    );
                }}
            </Formik>

        </Modal >

    )
}

export default StripeEditModal
