import React, { useEffect, useMemo } from 'react'
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api'

const containerStyle = {
    width: 'auto',
    height: '400px'
};



const MapComp = (props) => {

    const { position } = props
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyBigbZ8WuNYVqK2wCWVpq_6dIGRoFZ5DJI"
    })

    useEffect(() => {
        renderMap()
    }, [isLoaded])

    const renderMap = () => {

        return <GoogleMap
            mapContainerStyle={containerStyle}
            center={position}
            zoom={15}
        >
            <>
                <MarkerF
                    position={position}
                />
            </>
        </GoogleMap>
    }

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>
    }

    return isLoaded && renderMap()
}

export default MapComp;