import React, { useCallback, useContext } from "react";

/// React router dom
import { Routes, Route, Outlet, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

/// Css
import "./index.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
//import Main from './layouts/Main';

import ScrollToTop from "./layouts/ScrollToTop";

import { ThemeContext } from "../context/ThemeContext";
import Setting from "./layouts/Setting";
import Settings from "./components/localizationsettings/settings";
import Auth from "../Auth";
import Error404 from "./pages/Error404";
import PageMaster from "./components/PageMaster/PageMaster";
import { Backdrop } from "@mui/material";
import RestaurantList from "./components/Restaurant/Restaurant";
import TransactionList from "./components/Transaction/Transaction";
import VoucherConfig from "./components/Configuration/VoucherConfiguration/VoucherConfig";
import { LogoutUser } from "../store/actions/AuthActions";
import AuthVerify from "../helpers/auth-verify";
import Promocode from "./components/Promocode/Promocode";
import Voucher from "./components/vouchers/Voucher";
import RatingComp from "./components/Ratings/Rating";
import ServiceFeeConfiguration from "./components/Configuration/ServiceFeeConfiguration/ServiceFeeConfiguration";
import Profile from "./components/Profile/Profile";
import DelivaryArea from "./components/DelivaryArea/DelivaryArea";
import SystemConfig from "./components/Configuration/SystemConfiguration/SystemConfig";
import PaymentOptions from "./components/PaymentOptions/PaymentOptions";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";

const Markup = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const logOut = useCallback(() => {
    dispatch(LogoutUser(navigate));
  }, [dispatch]);


  if (location.pathname == '/' || location.pathname == '/admin/') {
    const userData = localStorage.getItem('token')
    if (!userData) {
      dispatch(LogoutUser(navigate));
    }
  }

  return (
    <>
      <Routes>
        <Route element={<LogoutLayout />} >
          <Route path='/forgotpassword' element={<ForgotPassword />} />
          <Route path='/resetpassword/:slug' element={<ResetPassword />} />
        </Route>

        <Route element={<MainLayout />} >
          {/* profile */}
          <Route element={<Auth allowedRoles={["admin"]} />}>
            <Route exact path={'profile'} element={<Profile />} />
          </Route>

          {/* Restaurant */}
          <Route element={<Auth allowedRoles={["admin"]} />}>
            <Route exact path={'admin/pagemaster'} element={<PageMaster />} />
            <Route exact path={'admin/restaurantlist'} element={<RestaurantList />} />
            <Route exact path={'/'} element={<RestaurantList />} />
            <Route exact path={'/admin'} element={<RestaurantList />} />
            <Route exact path={'admin/transaction'} element={<TransactionList />} />
            <Route exact path={'admin/voucherconfiguration'} element={<VoucherConfig />} />
            <Route exact path={'admin/servicefeeconfiguration'} element={<ServiceFeeConfiguration />} />
            <Route exact path={'admin/deliveryarea'} element={<DelivaryArea />} />
            <Route exact path={'admin/ratings'} element={<RatingComp />} />
            <Route exact path={'admin/voucher'} element={<Voucher />} />
            <Route exact path={'admin/promocode'} element={<Promocode />} />
            <Route exact path={'admin/systemconfiguration'} element={<SystemConfig />} />
            <Route exact path={'admin/paymentoptions'} element={<PaymentOptions />} />
            {/* <Route exact path={'localization-settings'} element={<Settings />} /> */}

          </Route>


          {/* error */}
        </Route>
        <Route path='404' element={<Error404 />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
      <ScrollToTop />
      <AuthVerify logOut={logOut} />
    </>
  );
};


function MainLayout() {
  const { menuToggle } = useContext(ThemeContext);
  const { loading } = useSelector((state) => state.commonData)
  return (
    <div id="main-wrapper" className={`show ${menuToggle ? "menu-toggle" : ""}`}>
      <Nav />
      <Setting />
      <div className="content-body" style={{ minHeight: window.innerHeight - 45 }}>
        <div className="container">
          <Outlet />
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <img src="/images/burger.gif" alt="" style={{ width: '15%' }} />
          </Backdrop>
        </div>
      </div>
      <Footer />
    </div>
  )
};

function LogoutLayout() {
  return (
    <div className="vh-100">
      <Outlet />
    </div >
  )

};

export default Markup;
