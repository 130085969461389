import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, TextField } from '@mui/material'
import { FieldArray, Form, Formik, getIn } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Tab, Nav } from "react-bootstrap";
import * as Yup from 'yup'
import { S3ImageUpload, URLSlug, symbolsArr } from '../../../constants/constants';
import { AddRestaurantApi, EditRestaurantApi } from '../../../services/RestaurantServices';
import AutoComplete from './AutoComplete';
import MapComp from './Map';
import { S3ImageDelete, S3Uploads } from '../../../helpers/S3Upload/S3upload';
import ImageInput from '../../../helpers/fileUploader/ImageInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


const RestaurantAddEditModal = (props) => {
    const { openModal, setOpenModal, editData, setEditData } = props
    const InitialValues = {
        RestaurantName: editData?.RestaurantName ? editData?.RestaurantName : '',
        RestaurantExternalURL: editData?.RestaurantUrl ? editData?.RestaurantUrl : '',
        ExternalURLSlug: editData?.UrlSlug ? editData?.UrlSlug : '',
        Email: editData?.EmailAddress ? editData?.EmailAddress : '',
        AlternativeEmail: editData?.AlternativeEmail ? editData?.AlternativeEmail : '',
        MobileNo: editData?.MobileNo ? editData?.MobileNo : [{ number: '' }],
        Password: '',
        showPassword: false,
        ShortDescription: editData?.ShortText ? editData?.ShortText : '',
        LongDescription: editData?.Description ? editData?.Description : '',
        AppStoreURL: editData?.AppStoreURL ? editData?.AppStoreURL : '',
        PlayStoreURL: editData?.PlayStoreURL ? editData?.PlayStoreURL : '',
        LinkedInURL: editData?.LinkedInURL ? editData?.LinkedInURL : '',
        FacebookURL: editData?.FaceBookURL ? editData?.FaceBookURL : '',
        InstagramURL: editData?.InstagramURL ? editData?.InstagramURL : '',
        TwitterURL: editData?.TwitterURL ? editData?.TwitterURL : '',
        Location: editData?.AddressForMap ? editData?.AddressForMap : '',
        Latitude: editData?.Lattitude ? editData?.Lattitude : '',
        Longitude: editData?.Longitude ? editData?.Longitude : '',
        DeliveryType: {
            delivery: editData?.DeliveryType && editData?.DeliveryType?.includes('1') ? true : false,
            pickup: editData?.DeliveryType && editData?.DeliveryType?.includes('2') ? true : false
        },
        Active: editData?.IsActive && editData?.IsActive == '1' ? true : false,
        UseDelivaryApp: editData?.CanUseDeliveryApp && editData?.CanUseDeliveryApp == '1' ? true : false,
        // Popular: editData?.Popular && editData?.Popular == '1' ? true : false,
        isOffline: editData?.IsOffline == '1' ? true : false,
        IsGiveContactFree: editData?.IsGiveContactFree == '1' ? true : false,
        HeaderLogoImageName: editData?.HeaderLogo_URL ? editData?.HeaderLogo_URL : '',
        LogoImageName: editData?.LogoUrl ? editData?.LogoUrl : '',
        bannerImageName: editData?.Home_Banner ? editData?.Home_Banner : ''
    }

    const [position, setPosition] = useState({
        lat: InitialValues && Number(InitialValues.Latitude),
        lng: InitialValues && Number(InitialValues.Longitude)
    })
    const [location, setLocation] = useState(InitialValues && InitialValues.Location)
    const [imageVal, setImageVal] = useState({
        HeaderLogoImage: editData?.HeaderLogo_URL ? editData?.HeaderLogo_URL : '',
        HeaderLogoImageSrc: editData?.HeaderLogo_URL ? `${S3ImageUpload}${editData.RestaurantId}/restaurant/headerlogo/${editData?.HeaderLogo_URL}` : '',
        LogoImage: editData?.LogoUrl ? editData?.LogoUrl : '',
        LogoImageSrc: editData?.LogoUrl ? `${S3ImageUpload}${editData.RestaurantId}/restaurant/profile/${editData?.LogoUrl}` : '',
        bannerImage: editData?.Home_Banner ? editData?.Home_Banner : '',
        bannerImageSrc: editData?.Home_Banner ? `${S3ImageUpload}${editData.RestaurantId}/restaurant/banner/${editData?.Home_Banner}` : '',
    })
    const [deleteImagePath, setDeleteImagePath] = useState({
        HeaderLogoImage: '',
        LogoImage: '',
        bannerImage: '',
    })
    const { t } = useTranslation('common');
    const dispatch = useDispatch()

    const handleModalClose = () => {
        setOpenModal(false);
        setEditData()
    }

    const handleChangeForHeaderLogo = (e) => {
        if (editData?.HeaderLogo_URL == imageVal.HeaderLogoImage) {
            const path = `${editData.RestaurantId}/restaurant/headerlogo/${imageVal.HeaderLogoImage}`
            setDeleteImagePath(prevState => ({
                ...prevState,
                HeaderLogoImage: path,
            }))
        }
        setImageVal(prevState => ({
            ...prevState,
            HeaderLogoImage: e.photo.data,
            HeaderLogoImageSrc: e.photo.src
        }))
    }
    const handleChangeForLogo = (e) => {
        if (editData?.LogoUrl == imageVal.LogoImage) {
            const path = `${editData.RestaurantId}/restaurant/profile/${imageVal.LogoImage}`
            setDeleteImagePath(prevState => ({
                ...prevState,
                LogoImage: path,
            }))
        }
        setImageVal(prevState => ({
            ...prevState,
            LogoImage: e.photo.data,
            LogoImageSrc: e.photo.src
        }))
    }
    const handleChangeForBanner = (e) => {
        if (editData?.Home_Banner == imageVal.bannerImage) {
            const path = `${editData.RestaurantId}/restaurant/banner/${imageVal.bannerImage}`
            setDeleteImagePath(prevState => ({
                ...prevState,
                bannerImage: path,
            }))
        }
        setImageVal(prevState => ({
            ...prevState,
            bannerImage: e.photo.data,
            bannerImageSrc: e.photo.src
        }))
    }


    const onsubmit = (value) => {
        let currentDate = Date.now()
        if (editData) {
            value.RestaurantId = editData.RestaurantId
        }
        value.Latitude = position.lat;
        value.Longitude = position.lng;
        value.Location = location;

        if (deleteImagePath.HeaderLogoImage) {
            S3ImageDelete(deleteImagePath.HeaderLogoImage)
        }
        if (deleteImagePath.LogoImage) {
            S3ImageDelete(deleteImagePath.LogoImage)
        }
        if (deleteImagePath.bannerImage) {
            S3ImageDelete(deleteImagePath.bannerImage)
        }

        if (typeof imageVal.HeaderLogoImage == 'object') {
            const fileNameForHeaderlogo = currentDate + '_' + imageVal.HeaderLogoImage.name.replace(/ /g, "_")
            value.HeaderLogoImageName = fileNameForHeaderlogo
        }
        else {
            value.HeaderLogoImageName = imageVal.HeaderLogoImage
        }
        if (typeof imageVal.LogoImage == 'object') {
            const fileNameForLogoImage = currentDate + '_' + imageVal.LogoImage.name.replace(/ /g, "_")
            value.LogoImageName = fileNameForLogoImage
        }
        else {
            value.LogoImageName = imageVal.LogoImage
        }
        if (typeof imageVal.bannerImage == 'object') {
            const fileNameForbannerImage = currentDate + '_' + imageVal.bannerImage.name.replace(/ /g, "_")
            value.bannerImageName = fileNameForbannerImage
        }
        else {
            value.bannerImageName = imageVal.bannerImage
        }

        if (editData) {
            dispatch(EditRestaurantApi(value, (ress) => {
                if (typeof imageVal.HeaderLogoImage == 'object') {
                    const fileNameForHeaderlogo = currentDate + '_' + imageVal.HeaderLogoImage.name.replace(/ /g, "_")
                    const filePathForHeaderlogo = `${editData.RestaurantId}/restaurant/headerlogo/${fileNameForHeaderlogo}`
                    S3Uploads(imageVal.HeaderLogoImage, filePathForHeaderlogo, fileNameForHeaderlogo)
                }
                if (typeof imageVal.LogoImage == 'object') {
                    const fileNameForLogoImage = currentDate + '_' + imageVal.LogoImage.name.replace(/ /g, "_")
                    const filePathForLogoImage = `${editData.RestaurantId}/restaurant/profile/${fileNameForLogoImage}`
                    S3Uploads(imageVal.LogoImage, filePathForLogoImage, fileNameForLogoImage)
                }
                if (typeof imageVal.bannerImage == 'object') {
                    const fileNameForbannerImage = currentDate + '_' + imageVal.bannerImage.name.replace(/ /g, "_")
                    const filePathForbannerImage = `${editData.RestaurantId}/restaurant/banner/${fileNameForbannerImage}`
                    S3Uploads(imageVal.bannerImage, filePathForbannerImage, fileNameForbannerImage)
                }
                setOpenModal(false);
                setEditData()
            }))
        } else {
            dispatch(AddRestaurantApi(value, (ress) => {
                if (typeof imageVal.HeaderLogoImage == 'object') {
                    const fileNameForHeaderlogo = currentDate + '_' + imageVal.HeaderLogoImage.name.replace(/ /g, "_")
                    const filePathForHeaderlogo = `${ress.data}/restaurant/headerlogo/${fileNameForHeaderlogo}`
                    S3Uploads(imageVal.HeaderLogoImage, filePathForHeaderlogo, fileNameForHeaderlogo)
                }
                if (typeof imageVal.LogoImage == 'object') {
                    const fileNameForLogoImage = currentDate + '_' + imageVal.LogoImage.name.replace(/ /g, "_")
                    const filePathForLogoImage = `${ress.data}/restaurant/profile/${fileNameForLogoImage}`
                    S3Uploads(imageVal.LogoImage, filePathForLogoImage, fileNameForLogoImage)
                }
                if (typeof imageVal.bannerImage == 'object') {
                    const fileNameForbannerImage = currentDate + '_' + imageVal.bannerImage.name.replace(/ /g, "_")
                    const filePathForbannerImage = `${ress.data}/restaurant/banner/${fileNameForbannerImage}`
                    S3Uploads(imageVal.bannerImage, filePathForbannerImage, fileNameForbannerImage)
                }
                setOpenModal(false);
                setEditData()
            }))
        }

    }

    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openModal}
            // onHide={handleModalClose}
            size="xl"
            centered
        >
            <Modal.Header>
                <Modal.Title>{editData ? t('profile.editrestaurant') : t('profile.addrestaurant')}</Modal.Title>
                <Button
                    variant=""
                    className="btn-close"
                    onClick={handleModalClose}
                >

                </Button>
            </Modal.Header>
            <Formik
                validateOnChange={false}
                initialValues={InitialValues}
                validationSchema={editData ? Yup.object().shape({
                    RestaurantName: Yup.string().required("Please enter restaurant name"),
                    RestaurantExternalURL: Yup.string().required("Please enter restaurant external url"),
                    MobileNo: Yup.array().of(
                        Yup.object().shape({
                            // number: Yup.string().required('Phone number is required').max(15, "Invalid phone number").min(6, "Invalid phone number"),
                            number: Yup.string()
                                .required('Phone number is required')
                                .test(
                                    'is-valid-phone-number',
                                    'Invalid phone number. It should start with 0 and be 11 characters long',
                                    value => {
                                        console.log(value)
                                        if (!value) {
                                            return false; // Empty value is considered invalid
                                        }
                                        return /^0\d{10}$/.test(value);
                                    }
                                )
                        })
                    ),
                    // MobileNo: Yup.string().required('Please enter mobile number').max(15, "Invalid mobile no.").min(6, "Invalid mobile no."),
                    Email: Yup.string('Enter your email')
                        .email('Enter a valid email')
                        .required('Please enter email'),
                    AlternativeEmail: Yup.string()
                        .email('Invalid email address')
                        .test('email-match', 'Alternative email must not be the same as Email', function (value) {
                            const { Email } = this.parent; // Access the value of 'email'
                            return value !== Email; // Return true if alternativeEmail is different from email
                        }),

                    ShortDescription: Yup.string().required("Please enter short description"),
                    LongDescription: Yup.string().required("Please enter long description"),
                    Latitude: Yup.string().required("Please enter latitude"),
                    Longitude: Yup.string().required("Please enter longitude"),
                    DeliveryType: Yup
                        .object()
                        .shape({
                            delivery: Yup.boolean(),
                            pickup: Yup.boolean()
                        })
                        .test(
                            "DeliveryType",
                            "Please Select one delivery type",
                            (options) => {
                                return options.delivery || options.pickup;
                            }
                        )
                })
                    :
                    Yup.object().shape({
                        Password: Yup.string().required('Password is required'),
                        RestaurantName: Yup.string().required("Please enter restaurant name"),
                        RestaurantExternalURL: Yup.string().required("Please enter restaurant external url"),
                        MobileNo: Yup.array().of(
                            Yup.object().shape({
                                // number: Yup.string().required('Phone number is required').max(15, "Invalid phone number").min(6, "Invalid phone number"),
                                number: Yup.string()
                                    .required('Phone number is required')
                                    .test(
                                        'is-valid-phone-number',
                                        'Invalid phone number. It should start with 0 and be 11 characters long',
                                        value => {
                                            if (!value) {
                                                return false; // Empty value is considered invalid
                                            }
                                            return /^0\d{10}$/.test(value);
                                        }
                                    )
                            })
                        ),
                        // MobileNo: Yup.string().required('Please enter mobile number').max(15, "Invalid mobile no.").min(6, "Invalid mobile no."),
                        Email: Yup.string('Enter your email')
                            .email('Enter a valid email')
                            .required('Please enter email'),
                        AlternativeEmail: Yup.string()
                            .email('Invalid email address')
                            .test('email-match', 'Alternative email must not be the same as Email', function (value) {
                                const { Email } = this.parent; // Access the value of 'email'
                                return value !== Email; // Return true if alternativeEmail is different from email
                            }),

                        // AlternativeEmail: Yup.string('Enter your email')
                        //     .email('Enter a valid email'),
                        ShortDescription: Yup.string().required("Please enter short description"),
                        LongDescription: Yup.string().required("Please enter long description"),
                        Latitude: Yup.string().required("Please enter latitude"),
                        Longitude: Yup.string().required("Please enter longitude"),
                        DeliveryType: Yup
                            .object()
                            .shape({
                                delivery: Yup.boolean(),
                                pickup: Yup.boolean()
                            })
                            .test(
                                "DeliveryType",
                                "Please Select one delivery type",
                                (options) => {
                                    return options.delivery || options.pickup;
                                }
                            )
                    })
                }
                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, isValid, isDisabled, handleBlur, setFieldValue, handleChange, handleSubmit } = props;

                    return (
                        <>
                            <Form onSubmit={handleSubmit} noValidate>
                                <Modal.Body>
                                    <>
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="profile-tab">
                                                    <div className="custom-tab-1">
                                                        <Tab.Container defaultActiveKey='Restaurant Profile'>
                                                            <Nav as='ul' className="nav nav-tabs">
                                                                <Nav.Item as='li' className="nav-item">
                                                                    <Nav.Link to="#Restaurant_Profile" eventKey='Restaurant Profile'>{t('profile.tab.restaurantprofile')} {(errors && (errors.Email || errors.LongDescription || errors.MobileNo || errors.Password || errors.RestaurantExternalURL || errors.RestaurantName || errors.ShortDescription)) && <i className="fa-solid fa-triangle-exclamation"></i>}</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item as='li' className="nav-item">
                                                                    <Nav.Link to="#Restaurant_Images" eventKey='Restaurant Images'>{t('profile.tab.restaurantimages')}</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item as='li' className="nav-item">
                                                                    <Nav.Link to="#Restaurant_Links" eventKey='Restaurant Links'>{t('profile.tab.restaurantlinks')}</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item as='li' className="nav-item">
                                                                    <Nav.Link to="#Restaurant_Address" eventKey='Restaurant Address'>{t('profile.tab.restaurantaddress')} {(errors && (errors.Latitude || errors.Longitude)) && <i className="fa-solid fa-triangle-exclamation"></i>}</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item as='li' className="nav-item">
                                                                    <Nav.Link to="#Restaurant_Settings" eventKey='Restaurant Settings'>{t('profile.tab.restaurantsettings')} {(errors && errors.DeliveryType) && <i className="fa-solid fa-triangle-exclamation"></i>}</Nav.Link>
                                                                </Nav.Item>
                                                            </Nav>
                                                            <Tab.Content>
                                                                <Tab.Pane id="Restaurant_Profile" eventKey='Restaurant Profile'>
                                                                    <div className="pt-3">
                                                                        <div className="settings-form">
                                                                            <div className="row pt-3">
                                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                                    <TextField
                                                                                        required
                                                                                        error={Boolean(errors.RestaurantName) && touched.RestaurantName}
                                                                                        helperText={(Boolean(errors.RestaurantName) && touched.RestaurantName) ? errors.RestaurantName : ''}
                                                                                        fullWidth
                                                                                        onBlur={handleBlur}
                                                                                        name="RestaurantName"
                                                                                        label={t('profile.restaurantprofile.restaurantname')}
                                                                                        value={values.RestaurantName}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        onBlur={handleBlur}
                                                                                        name="ExternalURLSlug"
                                                                                        label={t('profile.restaurantprofile.externalurlslug')}
                                                                                        value={values.ExternalURLSlug}
                                                                                        onChange={(e) => {
                                                                                            handleChange(e);
                                                                                            setFieldValue('RestaurantExternalURL', e.target.value
                                                                                                .toLowerCase()
                                                                                                .replace(/[^a-zA-Z0-9_ ]/g, '')
                                                                                                .replace(/ /g, '-'),)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                                    <TextField
                                                                                        required
                                                                                        inputProps={
                                                                                            { readOnly: true, }
                                                                                        }
                                                                                        error={Boolean(errors.RestaurantExternalURL) && touched.RestaurantExternalURL}
                                                                                        helperText={(Boolean(errors.RestaurantExternalURL) && touched.RestaurantExternalURL) ? errors.RestaurantExternalURL : ''}
                                                                                        fullWidth
                                                                                        onBlur={handleBlur}
                                                                                        name="RestaurantExternalURL"
                                                                                        label={t('profile.restaurantprofile.restaurantexternalurl')}
                                                                                        value={values.RestaurantExternalURL + URLSlug}
                                                                                        onChange={(e) => {
                                                                                            handleChange(e);
                                                                                            setFieldValue('ExternalURLSlug', e.target.value
                                                                                                .toLowerCase()
                                                                                                .replace(/[^a-zA-Z0-9_ ]/g, '')
                                                                                                .replace(/ /g, '-'),)
                                                                                        }}
                                                                                    />
                                                                                </div>

                                                                            </div>
                                                                            <div className="row py-4">
                                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                                    <TextField
                                                                                        required
                                                                                        error={Boolean(errors.Email) && touched.Email}
                                                                                        helperText={(Boolean(errors.Email) && touched.Email) ? errors.Email : ''}
                                                                                        type='email'
                                                                                        fullWidth
                                                                                        onBlur={handleBlur}
                                                                                        name="Email"
                                                                                        label={t('profile.restaurantprofile.email')}
                                                                                        value={values.Email}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                                    <TextField
                                                                                        error={Boolean(errors.AlternativeEmail) && touched.AlternativeEmail}
                                                                                        helperText={(Boolean(errors.AlternativeEmail) && touched.AlternativeEmail) ? errors.AlternativeEmail : ''}
                                                                                        type='email'
                                                                                        fullWidth
                                                                                        onBlur={handleBlur}
                                                                                        name="AlternativeEmail"
                                                                                        label={t('profile.restaurantprofile.alternativeemail')}
                                                                                        value={values.AlternativeEmail}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                                <FieldArray
                                                                                    name="MobileNo"
                                                                                    render={arrayHelpers => (
                                                                                        <>
                                                                                            {values.MobileNo && values.MobileNo.length > 0 && (
                                                                                                values.MobileNo.map((item, index) => {
                                                                                                    const MinSelection = `MobileNo.${index}.number`;
                                                                                                    const touchedMinSelection = getIn(touched, MinSelection);
                                                                                                    const errorMinSelection = getIn(errors, MinSelection);

                                                                                                    return <div className="col-lg-4 col-md-6 col-sm-12">
                                                                                                        <FormControl fullWidth className={`${index > 0 && 'mt-3'}`}>
                                                                                                            <InputLabel htmlFor="outlined-adornment-weight">{t('profile.restaurantprofile.mobileno')}</InputLabel>
                                                                                                            <OutlinedInput
                                                                                                                id="outlined-adornment-weight"
                                                                                                                endAdornment={
                                                                                                                    <InputAdornment position="start">
                                                                                                                        <IconButton
                                                                                                                            aria-label="toggle number visibility"
                                                                                                                            onClick={() => {
                                                                                                                                if (index == 0) {
                                                                                                                                    if (values.MobileNo.length < 4) {
                                                                                                                                        arrayHelpers.push({
                                                                                                                                            number: ""
                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                } else {
                                                                                                                                    arrayHelpers.remove(index)
                                                                                                                                }
                                                                                                                            }}
                                                                                                                            onMouseDown={(e) => { e.preventDefault() }}
                                                                                                                            edge="end"
                                                                                                                        >
                                                                                                                            {index == 0 ? <i class="bi bi-plus-circle"></i> : <i class="bi bi-x-circle"></i>}
                                                                                                                        </IconButton>
                                                                                                                    </InputAdornment>
                                                                                                                }
                                                                                                                type="text"
                                                                                                                inputProps={{
                                                                                                                    inputProps: {
                                                                                                                        inputMode: 'numeric',
                                                                                                                    },
                                                                                                                    onInput: (e) => {
                                                                                                                        e.target.value = e.target.value.replace(/\D/g, '');
                                                                                                                    },
                                                                                                                }}
                                                                                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                                                                                value={item.number}
                                                                                                                onChange={(e) => {
                                                                                                                    handleChange(e, index)
                                                                                                                }}
                                                                                                                label={t('profile.restaurantprofile.mobileno')}
                                                                                                                name={`MobileNo.${index}.number`}
                                                                                                                error={Boolean(touchedMinSelection && errorMinSelection)}
                                                                                                            />
                                                                                                        </FormControl>
                                                                                                        <FormHelperText className="Mui-error">{Boolean(touchedMinSelection && errorMinSelection) ? errorMinSelection : ""}</FormHelperText>
                                                                                                    </div>
                                                                                                })
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                />
                                                                                {/* <div className="col-lg-4 col-md-6 col-sm-12">
                                                                                    <TextField
                                                                                        required
                                                                                        error={Boolean(errors.MobileNo) && touched.MobileNo}
                                                                                        helperText={(Boolean(errors.MobileNo) && touched.MobileNo) ? errors.MobileNo : ''}
                                                                                        type='number'
                                                                                        onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                                                        fullWidth
                                                                                        onBlur={handleBlur}
                                                                                        name="MobileNo"
                                                                                        label={t('profile.restaurantprofile.mobileno')}
                                                                                        value={values.MobileNo}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                </div> */}
                                                                            </div>
                                                                            <div className="row">
                                                                                {!editData && <div className="col-lg-4 col-md-6 col-sm-12">
                                                                                    <TextField
                                                                                        required
                                                                                        InputProps={{
                                                                                            endAdornment:
                                                                                                <InputAdornment position="end">
                                                                                                    <IconButton
                                                                                                        aria-label="toggle password visibility"
                                                                                                        onClick={(e) => { setFieldValue('showPassword', !values.showPassword) }}
                                                                                                        onMouseDown={(e) => e.preventDefault()}
                                                                                                        edge="end"
                                                                                                    >
                                                                                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                                                                    </IconButton>
                                                                                                </InputAdornment>,
                                                                                        }}
                                                                                        error={Boolean(errors.Password) && touched.Password}
                                                                                        helperText={(Boolean(errors.Password) && touched.Password) ? errors.Password : ''}
                                                                                        fullWidth
                                                                                        type={values.showPassword ? "text" : "password"}
                                                                                        onBlur={handleBlur}
                                                                                        name="Password"
                                                                                        label={t('profile.restaurantprofile.password')}
                                                                                        value={values.Password}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                </div>}
                                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                                    <TextField
                                                                                        required
                                                                                        error={Boolean(errors.ShortDescription) && touched.ShortDescription}
                                                                                        multiline
                                                                                        rows={4}
                                                                                        helperText={(Boolean(errors.ShortDescription) && touched.ShortDescription) ? errors.ShortDescription : ''}
                                                                                        fullWidth
                                                                                        onBlur={handleBlur}
                                                                                        name="ShortDescription"
                                                                                        label={t('profile.restaurantprofile.shortdescription')}
                                                                                        value={values.ShortDescription}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                                    <TextField
                                                                                        required
                                                                                        error={Boolean(errors.LongDescription) && touched.LongDescription}
                                                                                        multiline
                                                                                        rows={4}
                                                                                        helperText={(Boolean(errors.LongDescription) && touched.LongDescription) ? errors.LongDescription : ''}
                                                                                        fullWidth
                                                                                        onBlur={handleBlur}
                                                                                        name="LongDescription"
                                                                                        label={t('profile.restaurantprofile.longdescription')}
                                                                                        value={values.LongDescription}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </Tab.Pane>
                                                                <Tab.Pane id="Restaurant_Images" eventKey='Restaurant Images'>
                                                                    <div className="pt-3">
                                                                        <div className="settings-form">
                                                                            <div className="pt-3">
                                                                                <div className="settings-form">
                                                                                    <h4 className="text-primary p-3">{t('profile.tab.restaurantimages')}</h4>
                                                                                    <div className="row pb-5">
                                                                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                                                                            <h6>{t('profile.restaurantimages.restaurantheaderlogoimage')}</h6>
                                                                                            <small>{t('profile.note')}: <span className="text-danger">{t('profile.restaurantimages.imageshouldbeuploadedin200px56pxdimension')}</span></small>
                                                                                            <div className="d-flex">
                                                                                                <ImageInput
                                                                                                    imageWidth={200}
                                                                                                    imageHeight={56}
                                                                                                    imageData={imageVal.HeaderLogoImageSrc}
                                                                                                    type="Header Logo Image"
                                                                                                    name="photo"
                                                                                                    label="Add Photo"
                                                                                                    showPreview
                                                                                                    onChange={(files) => { handleChangeForHeaderLogo(files) }}
                                                                                                />
                                                                                                {imageVal.HeaderLogoImageSrc &&
                                                                                                    <div className='ps-3'>
                                                                                                        <button type='button' className='btn btn-danger shadow btn-xs sharp  me-1'
                                                                                                            onClick={() => {
                                                                                                                if (editData?.HeaderLogo_URL == imageVal.HeaderLogoImage) {
                                                                                                                    const path = `${editData.RestaurantId}/restaurant/headerlogo/${imageVal.HeaderLogoImage}`
                                                                                                                    setDeleteImagePath(prevState => ({
                                                                                                                        ...prevState,
                                                                                                                        HeaderLogoImage: path,
                                                                                                                    }))
                                                                                                                    setImageVal(
                                                                                                                        {
                                                                                                                            HeaderLogoImage: '',
                                                                                                                            HeaderLogoImageSrc: '',
                                                                                                                            LogoImage: imageVal.LogoImage,
                                                                                                                            LogoImageSrc: imageVal.LogoImageSrc,
                                                                                                                            bannerImage: imageVal.bannerImage,
                                                                                                                            bannerImageSrc: imageVal.bannerImageSrc,
                                                                                                                        })
                                                                                                                } else {
                                                                                                                    setImageVal(
                                                                                                                        {
                                                                                                                            HeaderLogoImage: '',
                                                                                                                            HeaderLogoImageSrc: '',
                                                                                                                            LogoImage: imageVal.LogoImage,
                                                                                                                            LogoImageSrc: imageVal.LogoImageSrc,
                                                                                                                            bannerImage: imageVal.bannerImage,
                                                                                                                            bannerImageSrc: imageVal.bannerImageSrc,
                                                                                                                        })
                                                                                                                }
                                                                                                            }}>
                                                                                                            <i className="fa fa-trash"></i>
                                                                                                        </button>
                                                                                                    </div>}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                                                                            <h6>{t('profile.restaurantimages.restaurantlogoimage')}</h6>
                                                                                            <small>{t('profile.note')}: <span className="text-danger">{t('profile.restaurantimages.imageshouldbeuploadedin300px300pxdimension')}</span></small>
                                                                                            <div className="d-flex">
                                                                                                <ImageInput
                                                                                                    imageWidth={150}
                                                                                                    imageHeight={150}
                                                                                                    imageData={imageVal.LogoImageSrc}
                                                                                                    type="Logo Image"
                                                                                                    name="photo"
                                                                                                    label="Add Photo"
                                                                                                    showPreview
                                                                                                    onChange={(files) => { handleChangeForLogo(files) }}
                                                                                                />
                                                                                                {imageVal.LogoImageSrc &&
                                                                                                    <div className='ps-3'>
                                                                                                        <button type='button' className='btn btn-danger shadow btn-xs sharp  me-1'
                                                                                                            onClick={() => {
                                                                                                                if (editData?.LogoUrl == imageVal.LogoImage) {
                                                                                                                    const path = `${editData.RestaurantId}/restaurant/profile/${imageVal.LogoImage}`
                                                                                                                    setDeleteImagePath(prevState => ({
                                                                                                                        ...prevState,
                                                                                                                        LogoImage: path,
                                                                                                                    }))
                                                                                                                    setImageVal(
                                                                                                                        {
                                                                                                                            HeaderLogoImage: imageVal.HeaderLogoImage,
                                                                                                                            HeaderLogoImageSrc: imageVal.HeaderLogoImageSrc,
                                                                                                                            LogoImage: '',
                                                                                                                            LogoImageSrc: '',
                                                                                                                            bannerImage: imageVal.bannerImage,
                                                                                                                            bannerImageSrc: imageVal.bannerImageSrc,
                                                                                                                        })
                                                                                                                } else {
                                                                                                                    setImageVal(
                                                                                                                        {
                                                                                                                            HeaderLogoImage: imageVal.HeaderLogoImage,
                                                                                                                            HeaderLogoImageSrc: imageVal.HeaderLogoImageSrc,
                                                                                                                            LogoImage: '',
                                                                                                                            LogoImageSrc: '',
                                                                                                                            bannerImage: imageVal.bannerImage,
                                                                                                                            bannerImageSrc: imageVal.bannerImageSrc,
                                                                                                                        })
                                                                                                                }
                                                                                                            }}>
                                                                                                            <i className="fa fa-trash"></i>
                                                                                                        </button>
                                                                                                    </div>}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-12 col-md-12 col-sm-12 pt-5">
                                                                                            <h6>{t('profile.restaurantimages.restaurantdetailsbannerimage')}</h6>
                                                                                            <small>{t('profile.note')}: <span className="text-danger">{t('profile.restaurantimages.imageshouldbeuploadedin1400px350pxdimension')}</span></small>
                                                                                            <div className="d-flex">
                                                                                                <ImageInput
                                                                                                    showWidth={'100%'}
                                                                                                    showHeight={'100%'}
                                                                                                    imageWidth={1400}
                                                                                                    imageHeight={350}
                                                                                                    imageData={imageVal.bannerImageSrc}
                                                                                                    type="Banner Image"
                                                                                                    name="photo"
                                                                                                    label="Add Photo"
                                                                                                    showPreview
                                                                                                    onChange={(files) => { handleChangeForBanner(files) }}
                                                                                                />
                                                                                                {imageVal.bannerImageSrc &&
                                                                                                    <div className='ps-3'>
                                                                                                        <button type='button' className='btn btn-danger shadow btn-xs sharp  me-1'
                                                                                                            onClick={() => {
                                                                                                                if (editData?.Home_Banner == imageVal.bannerImage) {
                                                                                                                    const path = `${editData.RestaurantId}/restaurant/banner/${imageVal.bannerImage}`
                                                                                                                    setDeleteImagePath(prevState => ({
                                                                                                                        ...prevState,
                                                                                                                        bannerImage: path,
                                                                                                                    }))
                                                                                                                    setImageVal(
                                                                                                                        {
                                                                                                                            HeaderLogoImage: imageVal.HeaderLogoImage,
                                                                                                                            HeaderLogoImageSrc: imageVal.HeaderLogoImageSrc,
                                                                                                                            LogoImage: imageVal.LogoImage,
                                                                                                                            LogoImageSrc: imageVal.LogoImageSrc,
                                                                                                                            bannerImage: '',
                                                                                                                            bannerImageSrc: '',
                                                                                                                        })
                                                                                                                } else {
                                                                                                                    setImageVal(
                                                                                                                        {
                                                                                                                            HeaderLogoImage: imageVal.HeaderLogoImage,
                                                                                                                            HeaderLogoImageSrc: imageVal.HeaderLogoImageSrc,
                                                                                                                            LogoImage: imageVal.LogoImage,
                                                                                                                            LogoImageSrc: imageVal.LogoImageSrc,
                                                                                                                            bannerImage: '',
                                                                                                                            bannerImageSrc: '',
                                                                                                                        })
                                                                                                                }
                                                                                                            }}>
                                                                                                            <i className="fa fa-trash"></i>
                                                                                                        </button>
                                                                                                    </div>}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center justify-content-between">
                                                                                    <small>{t('profile.note')}: <span className="text-danger">{t('profile.restaurantimages.PleaseuploadimageasperperticulerimageNoteforbetterresolution')}</span></small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Tab.Pane>
                                                                <Tab.Pane id="Restaurant_Links" eventKey='Restaurant Links'>
                                                                    <div className="pt-3">
                                                                        <div className="settings-form">


                                                                            <div className="row pt-3">
                                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                                    <TextField
                                                                                        error={Boolean(errors.AppStoreURL) && touched.AppStoreURL}
                                                                                        helperText={(Boolean(errors.AppStoreURL) && touched.AppStoreURL) ? errors.AppStoreURL : ''}
                                                                                        fullWidth
                                                                                        onBlur={handleBlur}
                                                                                        name="AppStoreURL"
                                                                                        label={t('profile.restaurantlinks.appstoreurl')}
                                                                                        value={values.AppStoreURL}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                                    <TextField
                                                                                        error={Boolean(errors.PlayStoreURL) && touched.PlayStoreURL}
                                                                                        helperText={(Boolean(errors.PlayStoreURL) && touched.PlayStoreURL) ? errors.PlayStoreURL : ''}
                                                                                        fullWidth
                                                                                        onBlur={handleBlur}
                                                                                        name="PlayStoreURL"
                                                                                        label={t('profile.restaurantlinks.Playstoreurl')}
                                                                                        value={values.PlayStoreURL}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                                    <TextField
                                                                                        error={Boolean(errors.LinkedInURL) && touched.LinkedInURL}
                                                                                        helperText={(Boolean(errors.LinkedInURL) && touched.LinkedInURL) ? errors.LinkedInURL : ''}
                                                                                        fullWidth
                                                                                        onBlur={handleBlur}
                                                                                        name="LinkedInURL"
                                                                                        label={t('profile.restaurantlinks.linkedinurl')}
                                                                                        value={values.LinkedInURL}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="row pt-4">
                                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                                    <TextField
                                                                                        error={Boolean(errors.FacebookURL) && touched.FacebookURL}
                                                                                        helperText={(Boolean(errors.FacebookURL) && touched.FacebookURL) ? errors.FacebookURL : ''}
                                                                                        fullWidth
                                                                                        onBlur={handleBlur}
                                                                                        name="FacebookURL"
                                                                                        label={t('profile.restaurantlinks.facebookurl')}
                                                                                        value={values.FacebookURL}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                                    <TextField
                                                                                        error={Boolean(errors.InstagramURL) && touched.InstagramURL}
                                                                                        helperText={(Boolean(errors.InstagramURL) && touched.InstagramURL) ? errors.InstagramURL : ''}
                                                                                        fullWidth
                                                                                        onBlur={handleBlur}
                                                                                        name="InstagramURL"
                                                                                        label={t('profile.restaurantlinks.instagramurl')}
                                                                                        value={values.InstagramURL}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                                    <TextField
                                                                                        error={Boolean(errors.TwitterURL) && touched.TwitterURL}
                                                                                        helperText={(Boolean(errors.TwitterURL) && touched.TwitterURL) ? errors.TwitterURL : ''}
                                                                                        fullWidth
                                                                                        onBlur={handleBlur}
                                                                                        name="TwitterURL"
                                                                                        label={t('profile.restaurantlinks.twitterurl')}
                                                                                        value={values.TwitterURL}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Tab.Pane>
                                                                <Tab.Pane id="Restaurant_Address" eventKey='Restaurant Address'>
                                                                    <div className="pt-3">
                                                                        <div className="settings-form">

                                                                            <div className="row pt-3">
                                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                                    <AutoComplete setPosition={setPosition} location={location} setFieldValue={setFieldValue} setLocation={setLocation} />
                                                                                </div>
                                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                                    <TextField
                                                                                        required
                                                                                        error={Boolean(errors.Latitude) && touched.Latitude}
                                                                                        helperText={(Boolean(errors.Latitude) && touched.Latitude) ? errors.Latitude : ''}
                                                                                        fullWidth
                                                                                        type='number'
                                                                                        onBlur={handleBlur}
                                                                                        name="Latitude"
                                                                                        label={t('profile.restaurantaddress.latitude')}
                                                                                        value={position.lat ? position.lat : values.Latitude}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                                                    <TextField
                                                                                        required
                                                                                        error={Boolean(errors.Longitude) && touched.Longitude}
                                                                                        helperText={(Boolean(errors.Longitude) && touched.Longitude) ? errors.Longitude : ''}
                                                                                        fullWidth
                                                                                        type='number'
                                                                                        onBlur={handleBlur}
                                                                                        name="Longitude"
                                                                                        label={t('profile.restaurantaddress.longitude')}
                                                                                        value={position.lng ? position.lng : values.Longitude}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="row pt-4">
                                                                                <div className="col-lg-12">
                                                                                    <MapComp position={position} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Tab.Pane>
                                                                <Tab.Pane id="Restaurant_Settings" eventKey='Restaurant Settings'>
                                                                    <div className="pt-3">
                                                                        <div className="settings-form">
                                                                            <div className="row pt-4">
                                                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                                                    <FormControl variant="standard">
                                                                                        <FormLabel component="legend"><h6>{t('profile.restaurantsettings.restaurantdeliverytype')}</h6></FormLabel>
                                                                                        <FormHelperText className="Mui-error">{Boolean(errors.DeliveryType) || touched.DeliveryType ? errors.DeliveryType : ""}</FormHelperText>
                                                                                        <FormGroup>
                                                                                            <FormControlLabel
                                                                                                control={
                                                                                                    <Checkbox checked={values.DeliveryType.delivery} onChange={handleChange} name="DeliveryType.delivery" />
                                                                                                }
                                                                                                label={t('profile.restaurantsettings.delivery')}
                                                                                            />
                                                                                            <FormControlLabel
                                                                                                control={
                                                                                                    <Checkbox checked={values.DeliveryType.pickup} onChange={handleChange} name="DeliveryType.pickup" />
                                                                                                }
                                                                                                label={t('profile.restaurantsettings.pickup')}
                                                                                            />
                                                                                        </FormGroup>

                                                                                    </FormControl>
                                                                                </div>
                                                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                                                    <FormControl variant="standard">
                                                                                        <FormLabel component="legend"><h6>{t('profile.restaurantsettings.restaurantstatusconfiguration')}</h6></FormLabel>
                                                                                        <FormGroup>
                                                                                            <FormControlLabel control={
                                                                                                <Checkbox
                                                                                                    checked={values.Active}
                                                                                                    name='Active'
                                                                                                    onChange={handleChange}
                                                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                                                />
                                                                                            } label={t('profile.restaurantsettings.active')} />
                                                                                            <FormControlLabel control={
                                                                                                <Checkbox
                                                                                                    checked={values.UseDelivaryApp}
                                                                                                    name='UseDelivaryApp'
                                                                                                    onChange={handleChange}
                                                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                                                />
                                                                                            } label={t('profile.restaurantsettings.usedelivaryapp')} />
                                                                                            <FormControlLabel control={
                                                                                                <Checkbox
                                                                                                    checked={values.IsGiveContactFree}
                                                                                                    name='IsGiveContactFree'
                                                                                                    onChange={handleChange}
                                                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                                                />
                                                                                            } label={t('profile.restaurantsettings.isgivecontactfree')} />
                                                                                            {/* <FormControlLabel control={
                                                                                                <Checkbox
                                                                                                    checked={values.Popular}
                                                                                                    name='Popular'
                                                                                                    onChange={handleChange}
                                                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                                                />
                                                                                            } label={t('profile.restaurantsettings.popularrestaurant')} /> */}
                                                                                        </FormGroup>

                                                                                    </FormControl>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Tab.Pane>
                                                            </Tab.Content>
                                                        </Tab.Container>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={handleModalClose}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        size="large"
                                    // disabled={!isValid || isDisabled}
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </Modal.Footer>
                            </Form >
                        </>
                    );
                }}
            </Formik>

        </Modal >

    )
}

export default RestaurantAddEditModal
