import React, { useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";

export function NavMenuToggle() {
  setTimeout(() => {
    let mainwrapper = document.querySelector("#main-wrapper");
    if (mainwrapper.classList.contains('menu-toggle')) {
      mainwrapper.classList.remove("menu-toggle");
    } else {
      mainwrapper.classList.add("menu-toggle");
    }
  }, 200);
}


const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } = useContext(
    ThemeContext
  );

  return (
    <div className="nav-header">
      <Link to="/admin/restaurantlist" className="brand-logo">
        {background.value === "dark" || navigationHader !== "color_1" ? (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" width="200px" height="70px" style={{ shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', imageRendering: 'optimizeQuality', fillRule: 'evenodd', clipRule: 'evenodd' }}>
              <g className="brand-title"><path style={{ opacity: 0.894 }} fill="#ffffff" d="M 52.5,37.5 C 53.404,36.791 53.7373,35.791 53.5,34.5C 58.1583,24.7678 65.825,21.2678 76.5,24C 82.8342,27.1631 85.6675,32.3297 85,39.5C 87.9573,26.1045 96.124,20.9378 109.5,24C 115.347,27.8681 117.847,33.3681 117,40.5C 117.327,30.3389 122.494,24.3389 132.5,22.5C 135.764,22.9741 138.598,24.3075 141,26.5C 141.9,22.1658 142.734,17.8324 143.5,13.5C 151,12.1657 153.833,15.1657 152,22.5C 150.597,31.4462 148.264,40.1129 145,48.5C 139.166,55.3751 131.999,57.2085 123.5,54C 119.423,51.245 116.923,47.4117 116,42.5C 109.941,55.7419 100.608,58.7419 88,51.5C 85.8899,48.5083 84.5566,45.175 84,41.5C 80.617,51.945 73.4504,56.445 62.5,55C 54.4237,52.0266 51.0903,46.1933 52.5,37.5 Z M 68.5,31.5 C 72.1019,31.5062 74.1019,33.3395 74.5,37C 74.0238,40.3839 72.6905,43.3839 70.5,46C 68.5,46.6667 66.5,46.6667 64.5,46C 62.2741,40.0402 63.6074,35.2069 68.5,31.5 Z M 100.5,31.5 C 104.251,31.4177 106.251,33.251 106.5,37C 106.821,42.0349 104.654,45.2016 100,46.5C 96.6893,46.1865 95.1893,44.3532 95.5,41C 95.3948,36.7589 97.0615,33.5923 100.5,31.5 Z M 131.5,31.5 C 136.039,30.5394 138.372,32.3727 138.5,37C 138.505,44.1814 135.172,47.0147 128.5,45.5C 126.732,40.1927 127.732,35.526 131.5,31.5 Z"></path></g>
              <g className="brand-title"><path style={{ opacity: 0.886 }} fill="#ffffff" d="M 186.5,13.5 C 194.505,12.3421 197.339,15.6754 195,23.5C 193.305,30.8079 191.805,38.1413 190.5,45.5C 194.605,45.4962 196.105,47.4962 195,51.5C 191.015,56.0404 186.515,56.5404 181.5,53C 180.106,51.2605 179.439,49.2605 179.5,47C 181.645,35.7766 183.978,24.6099 186.5,13.5 Z"></path></g>
              <g><path style={{ opacity: 0.815 }} fill="#ffffff" d="M -0.5,25.5 C -0.5,24.1667 -0.5,22.8333 -0.5,21.5C 0.197963,19.3331 1.5313,17.4998 3.5,16C 12.8274,15.5002 22.1607,15.3335 31.5,15.5C 31.4248,19.5966 29.7581,22.7632 26.5,25C 17.5062,25.4998 8.50617,25.6664 -0.5,25.5 Z"></path></g>
              <g><path style={{ opacity: 0.859 }} fill="#ffffff" d="M 53.5,34.5 C 52.596,35.209 52.2627,36.209 52.5,37.5C 51.3734,38.1217 50.3734,38.955 49.5,40C 45.8484,40.4986 42.1817,40.6653 38.5,40.5C 38.154,45.216 37.4873,49.8827 36.5,54.5C 33.8127,54.6641 31.146,54.4974 28.5,54C 27.0564,52.874 26.3898,51.374 26.5,49.5C 28.3036,38.9781 30.8036,28.6448 34,18.5C 36.2658,17.0437 38.7658,16.2104 41.5,16C 47.1569,15.5006 52.8236,15.3339 58.5,15.5C 58.9305,18.8621 57.9305,21.6954 55.5,24C 50.964,25.3789 46.2973,25.8789 41.5,25.5C 41.7394,27.4672 41.4061,29.3005 40.5,31C 44.8206,31.499 49.1539,31.6657 53.5,31.5C 53.5,32.5 53.5,33.5 53.5,34.5 Z"></path></g>
              <g className="brand-title"><path style={{ opacity: 0.866 }} fill="#ffffff" d="M 164.5,22.5 C 173.03,21.7497 178.364,25.4164 180.5,33.5C 180.352,36.6297 179.018,39.1297 176.5,41C 171.256,42.4861 165.923,43.6527 160.5,44.5C 165.477,48.0593 170.644,48.0593 176,44.5C 176.244,52.4269 172.244,56.0936 164,55.5C 155.024,55.4315 150.191,50.9315 149.5,42C 150.336,32.1079 155.336,25.6079 164.5,22.5 Z M 163.5,31.5 C 165.527,31.3379 167.527,31.5045 169.5,32C 170.337,33.0113 170.67,34.1779 170.5,35.5C 167.428,35.8174 164.428,35.4841 161.5,34.5C 162.193,33.4822 162.859,32.4822 163.5,31.5 Z"></path></g>
              <g><path style={{ opacity: 0.783 }} fill="#ffffff" d="M 9.5,31.5 C 15.5,31.5 21.5,31.5 27.5,31.5C 26.7977,39.5167 22.4643,42.5167 14.5,40.5C 11.5,40.1667 8.5,39.8333 5.5,39.5C 6.31952,36.5276 7.65286,33.8609 9.5,31.5 Z"></path></g>
            </svg>
          </>
        ) : (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" width="200px" height="70px" style={{ shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', imageRendering: 'optimizeQuality', fillRule: 'evenodd', clipRule: 'evenodd' }}>
              <g className="brand-title"><path style={{ opacity: 0.894 }} fill="#ff0000" d="M 52.5,37.5 C 53.404,36.791 53.7373,35.791 53.5,34.5C 58.1583,24.7678 65.825,21.2678 76.5,24C 82.8342,27.1631 85.6675,32.3297 85,39.5C 87.9573,26.1045 96.124,20.9378 109.5,24C 115.347,27.8681 117.847,33.3681 117,40.5C 117.327,30.3389 122.494,24.3389 132.5,22.5C 135.764,22.9741 138.598,24.3075 141,26.5C 141.9,22.1658 142.734,17.8324 143.5,13.5C 151,12.1657 153.833,15.1657 152,22.5C 150.597,31.4462 148.264,40.1129 145,48.5C 139.166,55.3751 131.999,57.2085 123.5,54C 119.423,51.245 116.923,47.4117 116,42.5C 109.941,55.7419 100.608,58.7419 88,51.5C 85.8899,48.5083 84.5566,45.175 84,41.5C 80.617,51.945 73.4504,56.445 62.5,55C 54.4237,52.0266 51.0903,46.1933 52.5,37.5 Z M 68.5,31.5 C 72.1019,31.5062 74.1019,33.3395 74.5,37C 74.0238,40.3839 72.6905,43.3839 70.5,46C 68.5,46.6667 66.5,46.6667 64.5,46C 62.2741,40.0402 63.6074,35.2069 68.5,31.5 Z M 100.5,31.5 C 104.251,31.4177 106.251,33.251 106.5,37C 106.821,42.0349 104.654,45.2016 100,46.5C 96.6893,46.1865 95.1893,44.3532 95.5,41C 95.3948,36.7589 97.0615,33.5923 100.5,31.5 Z M 131.5,31.5 C 136.039,30.5394 138.372,32.3727 138.5,37C 138.505,44.1814 135.172,47.0147 128.5,45.5C 126.732,40.1927 127.732,35.526 131.5,31.5 Z"></path></g>
              <g className="brand-title"><path style={{ opacity: 0.886 }} fill="#ff0000" d="M 186.5,13.5 C 194.505,12.3421 197.339,15.6754 195,23.5C 193.305,30.8079 191.805,38.1413 190.5,45.5C 194.605,45.4962 196.105,47.4962 195,51.5C 191.015,56.0404 186.515,56.5404 181.5,53C 180.106,51.2605 179.439,49.2605 179.5,47C 181.645,35.7766 183.978,24.6099 186.5,13.5 Z"></path></g>
              <g><path style={{ opacity: 0.815 }} fill="#ff0000" d="M -0.5,25.5 C -0.5,24.1667 -0.5,22.8333 -0.5,21.5C 0.197963,19.3331 1.5313,17.4998 3.5,16C 12.8274,15.5002 22.1607,15.3335 31.5,15.5C 31.4248,19.5966 29.7581,22.7632 26.5,25C 17.5062,25.4998 8.50617,25.6664 -0.5,25.5 Z"></path></g>
              <g><path style={{ opacity: 0.859 }} fill="#ff0000" d="M 53.5,34.5 C 52.596,35.209 52.2627,36.209 52.5,37.5C 51.3734,38.1217 50.3734,38.955 49.5,40C 45.8484,40.4986 42.1817,40.6653 38.5,40.5C 38.154,45.216 37.4873,49.8827 36.5,54.5C 33.8127,54.6641 31.146,54.4974 28.5,54C 27.0564,52.874 26.3898,51.374 26.5,49.5C 28.3036,38.9781 30.8036,28.6448 34,18.5C 36.2658,17.0437 38.7658,16.2104 41.5,16C 47.1569,15.5006 52.8236,15.3339 58.5,15.5C 58.9305,18.8621 57.9305,21.6954 55.5,24C 50.964,25.3789 46.2973,25.8789 41.5,25.5C 41.7394,27.4672 41.4061,29.3005 40.5,31C 44.8206,31.499 49.1539,31.6657 53.5,31.5C 53.5,32.5 53.5,33.5 53.5,34.5 Z"></path></g>
              <g className="brand-title"><path style={{ opacity: 0.866 }} fill="#ff0000" d="M 164.5,22.5 C 173.03,21.7497 178.364,25.4164 180.5,33.5C 180.352,36.6297 179.018,39.1297 176.5,41C 171.256,42.4861 165.923,43.6527 160.5,44.5C 165.477,48.0593 170.644,48.0593 176,44.5C 176.244,52.4269 172.244,56.0936 164,55.5C 155.024,55.4315 150.191,50.9315 149.5,42C 150.336,32.1079 155.336,25.6079 164.5,22.5 Z M 163.5,31.5 C 165.527,31.3379 167.527,31.5045 169.5,32C 170.337,33.0113 170.67,34.1779 170.5,35.5C 167.428,35.8174 164.428,35.4841 161.5,34.5C 162.193,33.4822 162.859,32.4822 163.5,31.5 Z"></path></g>
              <g><path style={{ opacity: 0.783 }} fill="#ff0000" d="M 9.5,31.5 C 15.5,31.5 21.5,31.5 27.5,31.5C 26.7977,39.5167 22.4643,42.5167 14.5,40.5C 11.5,40.1667 8.5,39.8333 5.5,39.5C 6.31952,36.5276 7.65286,33.8609 9.5,31.5 Z"></path></g>
            </svg>
          </>
        )}
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          //openMenuToggle();
          NavMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
