/// Menu
import React, { Component, useContext, useEffect, useReducer, useState } from "react";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import Collapse from 'react-bootstrap/Collapse';

/// Link
import { Link, NavLink } from "react-router-dom";

import { MenuList } from './Menu';
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { useTranslation } from "react-i18next";
import { userRole } from "../../../constants/constants";
import { useDispatch, useSelector } from "react-redux";


const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
}

const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } = useContext(ThemeContext);
  const [state, setState] = useReducer(reducer, initialState);
  const { auth } = useSelector((state) => state.auth);
  const [hideOnScroll, setHideOnScroll] = useState(true)


  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll]
  )

  const handleMenuActive = status => {
    localStorage.setItem('activeMenu', status)
    localStorage.removeItem('activeSubMenu')
    setState({ active: status });
    setState({ activeSubmenu: "" })
    if (state.active === status) {
      setState({ active: "" });
    }
  }

  const handleSubmenuActive = (status) => {
    localStorage.setItem('activeSubMenu', status)
    setState({ activeSubmenu: status })
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" })
    }
  }

  useEffect(() => {
    if (path == 'profile') {
      setState({ active: '' })
    }
  }, [path])

  // useEffect(() => {
  //   let status = localStorage.getItem('activeMenu')
  //   setState({ active: status })
  //   if (localStorage.getItem('activeSubMenu')) {
  //     setState({ activeSubmenu: localStorage.getItem('activeSubMenu') })
  //   }
    
  // }, [])

  const { t } = useTranslation('common');

  let UserRoleName = userRole.filter((item) => {
    return item.id == auth.user_type_id
  })

  return (
    <div
      className={`dlabnav border-right ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? hideOnScroll > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <ul className="metismenu" id="menu">
          <li className="menu-title "> </li>
          {MenuList.map((data, index) => {
            if (auth.is_admin_login == 1 || (data.Permissions.includes(UserRoleName[0].role) || data.Permissions == 'all')) {
              let menuClass = data.classsChange;
              if (menuClass === "menu-title") {
                return (
                  <li className={menuClass} key={index}>{t(data.title)}</li>
                )
              } else {
                return (
                  <li className={` ${state.active === t(data.title) ? 'mm-active' : ''}`}
                    key={index}
                  >

                    {data.content && data.content.length > 0 ?
                      <Link to={"#"}
                        className="has-arrow"
                        onClick={() => { handleMenuActive(t(data.title)) }}
                      >
                        <i className={data.iconStyle}></i>
                        <span className="nav-text">{t(data.title)}</span>
                      </Link>
                      :
                      <NavLink to={data.to} onClick={() => { handleMenuActive(t(data.title)) }} >
                        <i className={data.iconStyle}></i>
                        <span className="nav-text">{t(data.title)}</span>
                      </NavLink>
                    }
                    {data.content && <Collapse in={state.active === t(data.title) ? true : false}>
                      <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                        {data.content && data.content.map((data, index) => {
                          return (
                            <li key={index}
                              className={`${state.activeSubmenu === t(data.title) ? "mm-active" : ""}`}
                            >
                              {data.content && data.content.length > 0 ?
                                <NavLink to={data.to} className={data.hasMenu ? 'has-arrow' : ''}
                                  onClick={() => { handleSubmenuActive(t(data.title)) }}
                                >
                                  {t(data.title)}
                                </NavLink>
                                :
                                <Link to={data.to} className={`${state.activeSubmenu === t(data.title) ? "mm-active" : ""}`} onClick={() => { handleSubmenuActive(t(data.title)) }}>
                                  {t(data.title)}
                                </Link>
                              }
                              {data.content && <Collapse in={state.activeSubmenu === t(data.title) ? true : false}>
                                <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                                  {data.content && data.content.map((data, index) => {
                                    return (
                                      <li key={index}>
                                        <Link className={`${path === data.to ? "mm-active" : ""}`} to={data.to}>{t(data.title)}</Link>
                                      </li>
                                    )
                                  })}
                                </ul>
                              </Collapse>}
                            </li>
                          )
                        })}
                      </ul>
                    </Collapse>}
                  </li>
                )
              }
            }
          })}
        </ul>


      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
