import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ThemeContext from "./context/ThemeContext";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_spa from "./locales/spa/translation.json";
import common_en from "./locales/en/translation.json";
import moment from "moment-timezone";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import configureStore from './store/store';


i18next.init({
  interpolation: {
    escapeValue: false,
    format: function (value) {
      if (value instanceof Date) {
        let timeZone = localStorage.getItem('timeZone') ? localStorage.getItem('timeZone') : moment.tz.guess()
        let dateFormat = localStorage.getItem('dateFormat') ? localStorage.getItem('dateFormat') : "DD-MM-YYYY"
        let timeFormat = localStorage.getItem('timeFormat') ? localStorage.getItem('timeFormat') : "24 Hours"

        if (timeFormat === '24 Hours') {
          timeFormat = 'HH'
        } else {
          timeFormat = 'hh'
        }
        return moment.tz(value, timeZone).format(`${dateFormat} ${timeFormat}:mm:ss`);
      }

      if (typeof value == 'number') {
        let currency = localStorage.getItem('currency') ? localStorage.getItem('currency') : 'INR'
        let symbol = localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') : '₹'
        let position = localStorage.getItem('currencyposition') ? localStorage.getItem('currencyposition') : 'Left'
        let noOfDecimals = localStorage.getItem('decimalNumber') ? localStorage.getItem('decimalNumber') : '2'
        let separator = localStorage.getItem('decimalSeparator') ? localStorage.getItem('decimalSeparator') : 'Comma(,)'
        value = new Intl.NumberFormat(currency, { minimumFractionDigits: noOfDecimals, maximumFractionDigits: noOfDecimals }).format(value)

        if (separator == 'Comma(,)') {
          value = value.replace(/[,.]/g, m => (m === '.' ? ',' : '.'))
        }

        if (position == 'Left') {
          return value + ' ' + symbol
        } else {
          return symbol + ' ' + value
        }
      }


      return value;
    }
  },
  lng: localStorage.getItem('language') ? localStorage.getItem('language') : 'EN',                              // language to use
  resources: {
    EN: {
      common: common_en               // 'common' is our custom namespace
    },
    SPA: {
      common: common_spa
    },
  },
});

const store = configureStore()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <BrowserRouter>
          <ThemeContext>
            <ToastContainer />
            <App />
          </ThemeContext>
        </BrowserRouter>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>
);

reportWebVitals();
