import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormControl, FormHelperText, MenuItem, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { pageManagePageList } from '../../../constants/constants';
import { addEditPageApi, getPageListApi } from '../../../services/PageServices';
import * as Yup from 'yup'

const PageMaster = () => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()
    const [description, setDescription] = useState('')
    const { restaurantList } = useSelector((state) => state.restaurantList);


    const handleChangeEditor = (data) => {
        setDescription(data)
    }

    const handleChangeForPage = (e, values) => {
        setDescription('')
        if (e.target.name == 'RestaurantId' && e.target.value && values.PageName) {
            const obj = {
                PageName: values.PageName,
                RestaurantId: e.target.value
            }
            dispatch(getPageListApi(obj, (res) => {
                console.log(res, res && res.data)
                if (res && res.data && res.data?.Description) {
                    setDescription(res.data.Description)
                }
            }))
        }
        if (e.target.name == 'PageName' && e.target.value && values.RestaurantId) {
            const obj = {
                PageName: e.target.value,
                RestaurantId: values.RestaurantId
            }
            dispatch(getPageListApi(obj, (res) => {
                if (res && res.data && res.data?.Description) {
                    setDescription(res.data.Description)
                }
            }))
        }
    }

    const onSubmit = (values) => {
        values.Description = description
        dispatch(addEditPageApi(values, (res) => {
            dispatch(getPageListApi(values, (res) => {
                if (res && res.data && res.data?.Description) {
                    setDescription(res.data.Description)
                }
            }))
        }))
    }

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{t('pagemasterscreen.title')}</h4>
                </div>
                <div className="card-body">
                    <Formik
                        validateOnChange={false}
                        enableReinitialize
                        initialValues={{
                            PageName: '',
                            RestaurantId: ''
                        }}

                        validationSchema={Yup.object().shape({
                            PageName: Yup.string().required("Page Name is required."),
                            RestaurantId: Yup.string().required("Restaurant  is required."),
                        })}
                        onSubmit={onSubmit}
                    >
                        {(props) => {
                            const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                            return (
                                <>
                                    <Form onSubmit={handleSubmit} noValidate>
                                        <div className="row">
                                            <div className="col-6">
                                                <FormControl fullWidth>
                                                    <TextField
                                                        required
                                                        select
                                                        fullWidth
                                                        value={values.RestaurantId}
                                                        label={t('transactionscreen.restaurant')}
                                                        name='RestaurantId'
                                                        onChange={(e) => { handleChange(e); handleChangeForPage(e, values) }}
                                                        onBlur={handleBlur}
                                                        error={Boolean(errors.RestaurantId) && touched.RestaurantId}
                                                    >
                                                        {restaurantList && restaurantList.length > 0 && restaurantList.map((item, index) => {
                                                            return <MenuItem key={index} value={item.RID}>{item.RestaurantName}</MenuItem>
                                                        })}
                                                    </TextField>
                                                    <FormHelperText className="Mui-error">{Boolean(errors.RestaurantId) || touched.RestaurantId ? errors.RestaurantId : ""}</FormHelperText>
                                                </FormControl>
                                            </div>
                                            <div className="col-6">
                                                <FormControl fullWidth>
                                                    <TextField
                                                        required
                                                        select
                                                        fullWidth
                                                        id="demo-simple-select"
                                                        value={values.PageName}
                                                        label={t('pagemasterscreen.page')}
                                                        name='PageName'
                                                        onChange={(e) => { handleChange(e); handleChangeForPage(e, values) }}
                                                        onBlur={handleBlur}
                                                        error={Boolean(errors.PageName) && touched.PageName}
                                                    >
                                                        <MenuItem disabled value=''>Select Page</MenuItem>
                                                        {pageManagePageList && pageManagePageList.map((item, index) => {
                                                            return <MenuItem key={index} value={item}>{item}</MenuItem>
                                                        })}
                                                    </TextField>
                                                    <FormHelperText className="Mui-error">{Boolean(errors.PageName) || touched.PageName ? errors.PageName : ""}</FormHelperText>
                                                </FormControl>
                                            </div>
                                            <div className='col-12 pt-3'>
                                                <CKEditor
                                                    disabled={!values.PageName || !values.RestaurantId}
                                                    editor={ClassicEditor}
                                                    config={{
                                                        toolbar: {
                                                            items: [
                                                                'undo', 'redo',
                                                                '|', 'heading',
                                                                '|', 'bold', 'italic',
                                                                '|', 'link', 'blockQuote',
                                                                '|', 'bulletedList', 'numberedList'
                                                            ],
                                                            shouldNotGroupWhenFull: false
                                                        },
                                                    }}
                                                    data={description}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        handleChangeEditor(data)
                                                    }}
                                                />
                                            </div>
                                            <div className="col-12  pt-3">
                                                <button
                                                    className="btn btn-primary"
                                                    type="submit"
                                                >
                                                    {t('buttons.save')}
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </>
                            );
                        }}
                    </Formik>

                </div>
            </div>
        </>
    )
}

export default PageMaster
