import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const ViewTransactionModal = (props) => {
    const { t } = useTranslation('common')
    const { openViewModal, setOpenViewModal, viewData } = props

    const handleViewModalClose = () => {
        setOpenViewModal(false)
    }

    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openViewModal}
            // onHide={handleViewModalClose}
            size="lg"
            centered
        >
            <Modal.Header>
                <Modal.Title>{t('transactionscreen.viewtransaction')}</Modal.Title>
                <Button
                    variant=""
                    className="btn-close"
                    onClick={handleViewModalClose}
                >

                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className="container mt-0">
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('transactionscreen.orderid')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.OrderId}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('transactionscreen.restaurant')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.RestaurantName}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('transactionscreen.paymenttype')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.PaymentType}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('transactionscreen.status')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.Status}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('transactionscreen.orderquantity')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.Quantity}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('transactionscreen.subtotal')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{t("currency", { Number: parseFloat(viewData.SubTotal) })}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('transactionscreen.delivaryfee')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{t("currency", { Number: parseFloat(viewData.ShippingCharge) })}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('transactionscreen.servicecharge')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{t("currency", { Number: parseFloat(viewData.ServiceCharge) })}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('transactionscreen.smallorderfees')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{t("currency", { Number: parseFloat(viewData.SmallOrderFee) })}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('transactionscreen.discount')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{t("currency", { Number: parseFloat(viewData.Discount) })}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('transactionscreen.grandtotal')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{t("currency", { Number: parseFloat(viewData.GrandTotal) })}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('transactionscreen.orderdate')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{t("date_format", { date: new Date(viewData.OrderDateTime) })}</div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ViewTransactionModal