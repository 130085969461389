
export const COLUMNSFORDELIVARYAREA = [

	{
		Header: 'deliveryareascreen.fromarea',
		accessor: 'StartRange'
	},
	{
		Header: 'deliveryareascreen.toarea',
		accessor: 'EndRange'
	}
]
