import { combineReducers } from "redux";
import { AuthReducer } from "./AuthReducer";
import Common from "./Common";
import { localizationSettings } from "./localizationReducer";
import { Permissions } from "./PermissionsReducer";
import { restaurantList } from "./restaurantReducer";


const reducers = combineReducers({
    auth: AuthReducer,
    commonData: Common,
    permission: Permissions,
    //form: reduxFormReducer,	
    localization: localizationSettings,
    restaurantList: restaurantList

});

export default reducers;