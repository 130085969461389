import React from 'react'
import { useTranslation } from 'react-i18next';

const TableSearch = ({ globalFilter, setGlobalFilter, permissions }) => {
    const { t } = useTranslation('common');
    return (
        <input
            placeholder={t('search')}
            className="mr-2 input-search form-control"
            value={globalFilter || ''}
            onChange={e => setGlobalFilter(e.target.value)}
        />
    )
}

export default TableSearch
