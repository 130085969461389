import { GET_LOCALIZATION_SETTINGS } from "../../constants/ActionTypes";


const INIT_STATE = {
    settings: {},
};

export function localizationSettings(state = INIT_STATE, action) {
    switch (action.type) {
        case GET_LOCALIZATION_SETTINGS: {
            return {
                ...state,
                settings: action.payload || {},
            };
        }
        default:
            return state;
    }
};
