import { Box, Chip, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { currencyArr, currencyPositionArr, dateFormatArr, decimalArr, decimalSeparatorArr, firstDayOfWeekArr, languageArr,  timeFormatArr, timezoneArr, weekendsArr } from '../../../constants/constants';
import { AddEditLocalizationSettingsApi, getLocalizationsettingsApi } from '../../../services/CommonServices';

const Settings = () => {


    const dispatch = useDispatch()
    const { t, i18n } = useTranslation('common');
    const [language, setLanguage] = useState('EN');
    const [timezone, setTimezone] = useState('Local Time')
    const [dateFormat, setDateFormat] = useState('DD-MM-YYYY');
    const [timeFormat, setTimeFormat] = useState('24 Hours');
    const [firstDay, setFirstDay] = useState('Sunday');
    const [currency, setCurrency] = useState('INR');
    const [currencySymbol, setCurrencySymbol] = useState('₹');
    const [currencyPosition, setCurrencyPosition] = useState('Left');
    const [decimalSeparator, setDecimalSeparator] = useState('Dot(.)');
    const [decimalNumber, setDecimalNumber] = useState('2');
    const [weekends, setWeekends] = useState([]);


    useEffect(() => {
        dispatch(getLocalizationsettingsApi((res) => {
            setLanguage(res.language)
            setTimezone(res.timezone)
            setDateFormat(res.dateFormat)
            setTimeFormat(res.timeFormat)
            setFirstDay(res.firstDayOfWeek)
            setCurrency(res.currency)
            setCurrencySymbol(res.currencySymbol)
            setCurrencyPosition(res.currencyposition)
            setDecimalSeparator(res.decimalSeparator)
            setDecimalNumber(res.noOfSeparator)
            setWeekends(res.weekends.split(','))
        }))
    }, [])


    const handleChangeForTimeZone = (time) => {
        if (time === 'Local Time') {
            setTimezone('Local Time')
            time = moment.tz.guess()
        } else {
            setTimezone(time);
        }
        localStorage.setItem('timeZone', time);
        const obj = {
            type: "timezone",
            value: time
        }
        dispatch(AddEditLocalizationSettingsApi(obj))
    }

    const changeLanguage = (lang) => {
        setLanguage(lang);
        i18n.changeLanguage(lang)
        localStorage.setItem('language', lang)
    }

    const handlaChangeForlanguage = (lang) => {
        const obj = {
            type: "language",
            value: lang
        }
        dispatch(AddEditLocalizationSettingsApi(obj))
    }


    const handleChangeForDateFormat = (dateF) => {
        setDateFormat(dateF)
        localStorage.setItem('dateFormat', dateF);
        const obj = {
            type: "dateFormat",
            value: dateF
        }
        dispatch(AddEditLocalizationSettingsApi(obj))
    }

    const handleChangeForTimeFormat = (timeF) => {
        setTimeFormat(timeF)
        localStorage.setItem('timeFormat', timeF);
        const obj = {
            type: "timeFormat",
            value: timeF
        }
        dispatch(AddEditLocalizationSettingsApi(obj))
    }

    const handleChangeForFirstDay = (day) => {
        setFirstDay(day)
        localStorage.setItem('firstDayOfWeek', day);
        const obj = {
            type: "firstDayOfWeek",
            value: day
        }
        dispatch(AddEditLocalizationSettingsApi(obj))
    }

    const handleChangeForCurrency = (cur) => {
        setCurrency(cur)
        localStorage.setItem('currency', cur);
        const obj = {
            type: "currency",
            value: cur
        }
        dispatch(AddEditLocalizationSettingsApi(obj))
    }

    const handleChangeForWeekends = (event) => {
        setWeekends(event.target.value);
        const obj = {
            type: "weekends",
            value: event.target.value.toString()
        }
        localStorage.setItem('weekends', event.target.value.toString());
        dispatch(AddEditLocalizationSettingsApi(obj))
    }



    const handleChangeForCurrencySymbol = (e) => {
        setCurrencySymbol(e.target.value);
        localStorage.setItem('currencySymbol', e.target.value)
    }

    const handleBlurForCurrencySymbol = (e) => {
        const obj = {
            type: "currencySymbol",
            value: e.target.value
        }
        dispatch(AddEditLocalizationSettingsApi(obj))
    }

    const handleChangeForCurrencyPosition = (pos) => {
        setCurrencyPosition(pos)
        localStorage.setItem('currencyposition', pos)
        const obj = {
            type: "currencyposition",
            value: pos
        }
        dispatch(AddEditLocalizationSettingsApi(obj))

    }
    const handleChangeForDecimalSeparator = (sep) => {
        setDecimalSeparator(sep)
        localStorage.setItem('decimalSeparator', sep)
        const obj = {
            type: "decimalSeparator",
            value: sep
        }
        dispatch(AddEditLocalizationSettingsApi(obj))
    }
    const handleChangeForDecimalNumber = (no) => {
        setDecimalNumber(no)
        localStorage.setItem('decimalNumber', no)
        const obj = {
            type: "noOfSeparator",
            value: no
        }
        dispatch(AddEditLocalizationSettingsApi(obj))
    }

    const currentDate = new Date()

    const handleDelete = (e, value) => {
        setWeekends(weekends.filter((item) => item !== value))
        const obj = {
            type: "weekends",
            value: weekends.filter((item) => item !== value).toString()
        }
        dispatch(AddEditLocalizationSettingsApi(obj))
    }

    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">{t('localization.title')}</h4>
            </div>
            <div className="card-body">
                <div>
                    {/* {t("date_format", { date: currentDate })}
                    <br />
                    {t("currency", { Number: 25001.654 })} */}
                    <div className="row">
                        <div className="offset-lg-1 col-lg-3 col-md-5 col-sm-12 text-center align-self-center">
                            <h5> {t('localization.language')}</h5>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <Dropdown className=' bg-light p-2'>
                                <Dropdown.Toggle as="a" className={`nav-link i-false cursor-pointer `}
                                >
                                    <div className="header-info2 d-flex align-items-center">
                                        <div className="d-flex align-items-center sidebar-info w-100 justify-content-between">
                                            <div>
                                                <h6 className="font-w500 mb-0 ms-2 ">{language}</h6>
                                            </div>
                                            <div>
                                                <i className="fas fa-chevron-down text-right"></i>

                                            </div>
                                        </div>

                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {languageArr && languageArr.map((item, index) => {
                                        return <Dropdown.Item key={index} onClick={() => { changeLanguage(item); handlaChangeForlanguage(item) }}>{item}</Dropdown.Item>
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="offset-lg-1 col-lg-3 col-md-5 col-sm-12 text-center align-self-center">
                            <h5>  {t('localization.timezone')}</h5>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <Dropdown className=' bg-light p-2'>
                                <Dropdown.Toggle as="a" className={`nav-link i-false cursor-pointer `} >
                                    <div className="header-info2 d-flex align-items-center">
                                        <div className="d-flex align-items-center sidebar-info w-100 justify-content-between">
                                            <div>
                                                <h6 className="font-w500 mb-0 ms-2 ">{timezone}</h6>
                                            </div>
                                            <i className="fas fa-chevron-down"></i>
                                        </div>

                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {timezoneArr && timezoneArr.map((item, index) => {
                                        return <Dropdown.Item key={index} onClick={() => { handleChangeForTimeZone(item); changeLanguage(i18next.language) }}>{item}</Dropdown.Item>
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="offset-lg-1 col-lg-3 col-md-5 col-sm-12 text-center align-self-center">
                            <h5> {t('localization.dateformat')}</h5>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <Dropdown className=' bg-light p-2'>
                                <Dropdown.Toggle as="a" className={`nav-link i-false cursor-pointer `} >
                                    <div className="header-info2 d-flex align-items-center">
                                        <div className="d-flex align-items-center sidebar-info w-100 justify-content-between">
                                            <div>
                                                <h6 className="font-w500 mb-0 ms-2 ">{dateFormat}</h6>
                                            </div>
                                            <i className="fas fa-chevron-down"></i>
                                        </div>

                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {dateFormatArr && dateFormatArr.map((item, index) => {
                                        return <Dropdown.Item key={index} onClick={() => { handleChangeForDateFormat(item); changeLanguage(i18next.language) }}>{item}</Dropdown.Item>
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="offset-lg-1 col-lg-3 col-md-5 col-sm-12 text-center align-self-center">
                            <h5> {t('localization.timeformat')}</h5>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <Dropdown className=' bg-light p-2'>
                                <Dropdown.Toggle as="a" className={`nav-link i-false cursor-pointer `} >
                                    <div className="header-info2 d-flex align-items-center">
                                        <div className="d-flex align-items-center sidebar-info w-100 justify-content-between">
                                            <div>
                                                <h6 className="font-w500 mb-0 ms-2 ">{timeFormat}</h6>
                                            </div>
                                            <i className="fas fa-chevron-down"></i>
                                        </div>

                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {timeFormatArr && timeFormatArr.map((item, index) => {
                                        return <Dropdown.Item key={index} onClick={() => { handleChangeForTimeFormat(item); changeLanguage(i18next.language) }}>{item}</Dropdown.Item>
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <hr />

                    <div className="row">
                        <div className="offset-lg-1 col-lg-3 col-md-5 col-sm-12 text-center align-self-center">
                            <h5>  {t('localization.firstdayofweek')}</h5>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <Dropdown className='bg-light p-2'>
                                <Dropdown.Toggle as="a" className={`nav-link i-false cursor-pointer `} >
                                    <div className="header-info2 d-flex align-items-center">
                                        <div className="d-flex align-items-center sidebar-info w-100 justify-content-between">
                                            <div>
                                                <h6 className="font-w500 mb-0 ms-2 ">{firstDay}</h6>
                                            </div>
                                            <i className="fas fa-chevron-down"></i>
                                        </div>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {firstDayOfWeekArr && firstDayOfWeekArr.map((item, index) => {
                                        return <Dropdown.Item key={index} onClick={() => { handleChangeForFirstDay(item); changeLanguage(i18next.language) }}>{item}</Dropdown.Item>

                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <hr />

                    <div className="row">
                        <div className="offset-lg-1 col-lg-3 col-md-5 col-sm-12 text-center align-self-center">
                            <h5> {t('localization.weekends')}</h5>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 settingTextfield">
                            <FormControl fullWidth className='bg-light'>
                                <Select
                                    labelId="demo-mutiple-chip-checkbox-label"
                                    id="demo-mutiple-chip-checkbox"
                                    multiple
                                    value={weekends}
                                    onChange={handleChangeForWeekends}
                                    input={<OutlinedInput id="select-multiple-chip" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip
                                                    key={value}
                                                    label={value}
                                                    clickable
                                                    onMouseDown={(event) => event.stopPropagation()}
                                                    onClick={(e) => handleDelete(e, value)}
                                                    onDelete={(e) => handleDelete(e, value)}
                                                />
                                            ))}
                                        </Box>
                                    )}
                                >
                                    {weekendsArr.map((name, index) => (
                                        <MenuItem key={index} value={name}>
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <hr />

                    <div className="row">
                        <div className="offset-lg-1 col-lg-3 col-md-5 col-sm-12 text-center align-self-center">
                            <h5> {t('localization.currency')}</h5>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <Dropdown className=' bg-light p-2'>
                                <Dropdown.Toggle as="a" className={`nav-link i-false cursor-pointer `} >
                                    <div className="header-info2 d-flex align-items-center">
                                        <div className="d-flex align-items-center sidebar-info w-100 justify-content-between">
                                            <div>
                                                <h6 className="font-w500 mb-0 ms-2 ">{currency}</h6>
                                            </div>
                                            <i className="fas fa-chevron-down"></i>
                                        </div>

                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {currencyArr && currencyArr.map((item, index) => {
                                        return <Dropdown.Item key={index} onClick={() => { handleChangeForCurrency(item); changeLanguage(i18next.language) }}>{item}</Dropdown.Item>

                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <hr />

                    <div className="row">
                        <div className="offset-lg-1 col-lg-3 col-md-5 col-sm-12 text-center align-self-center">
                            <h5>  {t('localization.currencysymbol')}</h5>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 settingTextfield">
                            <TextField
                                fullWidth
                                className='border-0 bg-light'
                                id="outlined-basic"
                                placeholder='Weekends'
                                value={currencySymbol}
                                onChange={(e) => handleChangeForCurrencySymbol(e)}
                                onBlur={(e) => handleBlurForCurrencySymbol(e)}
                            />
                        </div>
                    </div>
                    <hr />

                    <div className="row">
                        <div className="offset-lg-1 col-lg-3 col-md-5 col-sm-12 text-center align-self-center">
                            <h5> {t('localization.currencyposition')}</h5>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <Dropdown className=' bg-light p-2'>
                                <Dropdown.Toggle as="a" className={`nav-link i-false cursor-pointer `} >
                                    <div className="header-info2 d-flex align-items-center">
                                        <div className="d-flex align-items-center sidebar-info w-100 justify-content-between">
                                            <div>
                                                <h6 className="font-w500 mb-0 ms-2 ">{currencyPosition}</h6>
                                            </div>
                                            <i className="fas fa-chevron-down"></i>
                                        </div>

                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {currencyPositionArr && currencyPositionArr.map((item, index) => {
                                        return <Dropdown.Item key={index} onClick={() => { handleChangeForCurrencyPosition(item); changeLanguage(i18next.language) }}>{item}</Dropdown.Item>

                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <hr />

                    <div className="row">
                        <div className="offset-lg-1 col-lg-3 col-md-5 col-sm-12 text-center align-self-center">
                            <h5> {t('localization.decimalseparator')}</h5>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <Dropdown className=' bg-light p-2'>
                                <Dropdown.Toggle as="a" className={`nav-link i-false cursor-pointer `} >
                                    <div className="header-info2 d-flex align-items-center">
                                        <div className="d-flex align-items-center sidebar-info w-100 justify-content-between">
                                            <div>
                                                <h6 className="font-w500 mb-0 ms-2 ">{decimalSeparator}</h6>
                                            </div>
                                            <i className="fas fa-chevron-down"></i>
                                        </div>

                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {decimalSeparatorArr && decimalSeparatorArr.map((item, index) => {
                                        return <Dropdown.Item key={index} onClick={() => { handleChangeForDecimalSeparator(item); changeLanguage(i18next.language) }}>{item}</Dropdown.Item>

                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <hr />

                    <div className="row">
                        <div className="offset-lg-1 col-lg-3 col-md-5 col-sm-12 text-center align-self-center">
                            <h5> {t('localization.noofseparator')}</h5>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <Dropdown className=' bg-light p-2'>
                                <Dropdown.Toggle as="a" className={`nav-link i-false cursor-pointer `} >
                                    <div className="header-info2 d-flex align-items-center">
                                        <div className="d-flex align-items-center sidebar-info w-100 justify-content-between">
                                            <div>
                                                <h6 className="font-w500 mb-0 ms-2 ">{decimalNumber}</h6>
                                            </div>
                                            <i className="fas fa-chevron-down"></i>
                                        </div>

                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {decimalArr && decimalArr.map((item, index) => {
                                        return <Dropdown.Item key={index} onClick={() => { handleChangeForDecimalNumber(item); changeLanguage(i18next.language) }}>{item}</Dropdown.Item>

                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Settings
