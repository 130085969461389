import { GET_PERMISSIONS } from "../../constants/ActionTypes";


const INIT_STATE = {
    permission: {
        view: false,
        add: false,
        edit: false,
        delete: false
    },
};

export function Permissions(state = INIT_STATE, action) {
    switch (action.type) {
        case GET_PERMISSIONS: {
            return {
                ...state,
                permission: action.payload || {},
            };
        }
        default:
            return state;
    }
};
