import { Form, Formik } from 'formik';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { StatusListSystemConfigApi, getCancellationStatusApi, updateCancellationStatusApi } from '../../../../services/ConfigServices';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormControl, MenuItem, TextField } from '@mui/material';

const SystemConfig = () => {
    const { t } = useTranslation('common')
    const dispatch = useDispatch()
    const [statusList, setStatusList] = useState([])
    const [cancellationStatus, setCancellationStatus] = useState({})

    useEffect(() => {
        dispatch(StatusListSystemConfigApi((res) => {
            setStatusList(res.data)
        }))
    }, [])

    useEffect(() => {
        dispatch(getCancellationStatusApi((res) => {
            setCancellationStatus(res.data)
        }))
    }, [])

    const onsubmit = (values) => {
        console.log(values)
        dispatch(updateCancellationStatusApi(values, (res) => {

        }))
    }

    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">{t('systemconfigscreen.title')}</h4>
            </div>

            <Formik
                enableReinitialize
                initialValues={{
                    CancellationStatus: cancellationStatus && cancellationStatus.length > 0 && cancellationStatus[0].CancelationStatusId ? cancellationStatus[0].CancelationStatusId : ''
                }}

                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, isValid, isDisabled, handleBlur, setFieldValue, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Form onSubmit={handleSubmit} noValidate>
                                <div className="card-body">
                                    <div className="row">
                                        <div className='col-12 pt-3'>
                                            <FormControl fullWidth>
                                                <TextField
                                                    required
                                                    select
                                                    fullWidth
                                                    value={values.CancellationStatus}
                                                    label={t('systemconfigscreen.ordercancellationstatus')}
                                                    name='CancellationStatus'
                                                    onChange={(e) => { handleChange(e) }}
                                                    onBlur={handleBlur}
                                                    error={Boolean(errors.CancellationStatus) && touched.CancellationStatus}
                                                    helperText={Boolean(errors.CancellationStatus) || touched.CancellationStatus ? errors.CancellationStatus : ""}
                                                >
                                                    <MenuItem value=''>Select Status</MenuItem>
                                                    {statusList && statusList.length > 0 && statusList.map((item, index) => {
                                                        return <MenuItem key={index} value={item.StatusId}>{item.StatusDisplayName}</MenuItem>
                                                    })}
                                                </TextField>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </div>
                            </Form >
                        </>
                    );
                }}
            </Formik>
        </div >
    )
}

export default SystemConfig
