import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';
import { RESTAURANTCOLUMN } from './RestaurantColumns';
import swal from 'sweetalert';
import TablePaginationHelper from '../../../helpers/Pagination';
import TableSearch from '../../../helpers/TableSearch';
import { Button } from 'react-bootstrap';
import { Empty } from 'antd';
import ViewRestaurantModal from './ViewRestaurantModal';
import RestaurantAddEditModal from './RestaurantAddEditModal';
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import { Switch, Tooltip } from '@mui/material';
import { DeleteRestaurantApi, GetPaymentData, GetPaymentGatewayList, UpdatePaymentGatewayStatus, getProfileDataForRestaurant, getRestaurantListApi, updateRestorantMenuVIewApi, updateRestorantStatusApi } from '../../../services/RestaurantServices';
import { paymentGetways } from '../../../constants/constants';
import StripeEditModal from './PaymentModal/StripeEditModal';
import CashEditModal from './PaymentModal/CashEditModal';
import VivaEditModal from './PaymentModal/VivaEditModal';
import PaymentSenseEditModal from './PaymentModal/PaymentSenseEditModal';

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 18,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 1,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(22px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: "&.Mui-checked" ? "green" : "black",
                opacity: 1,
                border: 0
            }
        }
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 16,
        height: 16
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500
        })
    }
}));

const RestaurantList = () => {
    const dispatch = useDispatch()
    const [restaurantList, setRestaurantList] = useState([]);
    const [paymentGateWayList, setPaymentGateWayList] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [openStripeModal, setOpenStripeModal] = useState(false);
    const [openCashModal, setOpenCashModal] = useState(false);
    const [openVivaModal, setOpenVivaModal] = useState(false);
    const [openPaymentSenseModal, setOpenPaymentSenseModal] = useState(false);
    const [viewData, setViewData] = useState({});
    const [editData, setEditData] = useState({});
    const [settingData, setSettingData] = useState({});
    const [paymentData, setPaymentData] = useState({});
    const { t } = useTranslation('common');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [paymentFlag, setPaymentFlag] = useState(false);

    useEffect(() => {
        dispatch(getRestaurantListApi((res) => {
            if (res && res.data && res.data.length > 0) {
                setRestaurantList(res.data)
            }
        }))
    }, [openModal])

    const handleChange = (row, checked) => {
        const obj = {
            RestaurantId: row.original.RID,
            IsOffline: checked ? 0 : 1
        }
        swal({
            title: "Are you sure?",
            text:
                checked == true ? "Do you want to online this restaurant Spice Express?" : "Do you want to offline this restaurant Spice Express?",
            icon: "info",
            buttons: {
                confirm: 'Yes',
                cancel: 'Close'
            },
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(updateRestorantStatusApi(obj, (res) => {
                    row.original.IsOffline = checked ? 0 : 1
                }))
            }
        })
    }

    const handleChangeMenu = (row, checked) => {
        const obj = {
            RestaurantId: row.original.RID,
            IsShowAllMenu: checked ? 1 : 0
        }
        dispatch(updateRestorantMenuVIewApi(obj, (res) => {
            row.original.IsShowAllMenu = checked ? 1 : 0
        }))
    }

    const handleChangeForEnable = (e, row) => {
        row.IsActive = e.target.checked ? 1 : 0
        dispatch(UpdatePaymentGatewayStatus(row, (res) => {

        }))
    }


    const columns = RESTAURANTCOLUMN
    const data = restaurantList || []
    const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy)
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter, state } = tableInstance
    const { globalFilter } = state


    const handleEdit = (row) => {
        const RestaurantId = row.original.RID
        dispatch(getProfileDataForRestaurant({ RestaurantId }, (res) => {
            res.data.Profile.RestaurantId = row.original.RID
            setEditData(res.data.Profile)
            setOpenModal(true)
        }))

    }

    const handleAddOpen = () => {
        setEditData()
        setOpenModal(true)
    }

    const handleSettings = (row) => {
        const RestaurantId = row.original && row.original.RID ? row.original.RID : row.RID
        dispatch(GetPaymentGatewayList({ RestaurantId }, (res) => {
            setPaymentGateWayList(res)
        }))
        setPaymentFlag(true)
        setSettingData(row.original ? row.original : row)
    }

    const handlepaymentclose = () => {
        setPaymentFlag(false)
    }

    const handlePaymentSettings = (item) => {
        const obj = {
            RestaurantId: settingData.RID,
            PaymentType: item.PaymentType,
        }
        dispatch(GetPaymentData(obj, (res) => {
            setPaymentData(res[0])
        }))
        if (item.PaymentType == 1) {
            setOpenCashModal(true)
        } else if (item.PaymentType == 10) {
            setOpenVivaModal(true)
        } else if (item.PaymentType == 4) {
            setOpenPaymentSenseModal(true)
        } else if (item.PaymentType == 11) {
            setOpenStripeModal(true)
        }
    }

    const handleView = (row) => {
        const RestaurantId = row.original.RID
        dispatch(getProfileDataForRestaurant({ RestaurantId }, (res) => {
            res.data.Profile.RestaurantId = row.original.RID
            setViewData(res.data.Profile)
            setOpenViewModal(true)
        }))
    }

    const handleDelete = (row) => {
        const RestaurantId = row.original.RID
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover!",
            icon: "warning",
            buttons: {
                confirm: 'Ok',
                cancel: 'Cancel'
            },
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(DeleteRestaurantApi({ RestaurantId }, (res) => {
                    dispatch(getRestaurantListApi((res) => {
                        setRestaurantList(res.data)
                    }))
                    swal("Restaurant has been deleted!", {
                        icon: "success",
                    });
                }))
            }
        })
    }

    return (
        <>
            {!paymentFlag && <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{t('restaurantlistscreen.title')}</h4>
                    <div className='d-flex justify-content-md-around'>
                        <TableSearch globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                        {<Button onClick={handleAddOpen}>{t('buttons.addrestaurant')}</Button>}
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <div className="dataTables_wrapper">
                            <table {...getTableProps()} className="table dataTable display">
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {t(column.render('Header'))}
                                                    <span className="ml-1">
                                                        {column.isSorted ? (
                                                            column.isSortedDesc ?
                                                                <i className="fa fa-arrow-down ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                                :
                                                                <i className="fa fa-arrow-up ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                        )
                                                            :
                                                            (<i className="fa fa-sort ms-2 fs-14" style={{ opacity: '0.3' }} />)
                                                        }
                                                    </span>
                                                </th>
                                            ))}
                                            <th className='fixedColumn text-end'>{t('table.menuview')}</th>
                                            <th className='fixedColumn text-end'>{t('table.restaurantonoff')}</th>
                                            <th className='fixedColumn text-end'>{t('table.action')}</th>
                                        </tr>
                                    ))}
                                </thead>
                                {tableInstance.rows && tableInstance.rows.length > 0 ? <tbody {...getTableBodyProps()}>
                                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()}>
                                                <td> {row.original.RestaurantName} </td>
                                                <td> {row.original.ContactNumber} </td>
                                                <td> {row.original.EmailAddress} </td>
                                                <td>
                                                    <Tooltip title={`${row.original.IsShowAllMenu == 1 ? t('restaurantlistscreen.showallmenu') : t('restaurantlistscreen.showmenubycategory')}`} arrow>
                                                        <FormGroup className='align-items-center'>
                                                            <IOSSwitch
                                                                className='ms-1'
                                                                checked={row.original.IsShowAllMenu == '1'}
                                                                onChange={(e, checked) => { handleChangeMenu(row, checked) }}
                                                            />
                                                        </FormGroup>
                                                    </Tooltip>
                                                </td>
                                                <td>
                                                    <Tooltip title={`${row.original.IsOffline == 0 ? t('restaurantlistscreen.clicktooffline') : t('restaurantlistscreen.clicktoonline')}`} arrow>
                                                        <FormGroup className='align-items-center'>
                                                            <IOSSwitch
                                                                className='ms-1'
                                                                checked={row.original.IsOffline == '0'}
                                                                onChange={(e, checked) => { handleChange(row, checked) }}
                                                            />
                                                        </FormGroup>
                                                    </Tooltip>
                                                </td>
                                                <td className='fixedColumn text-end d-flex align-items-center justify-content-end'>
                                                    <Tooltip title="View" arrow>
                                                        <button className='btn btn-info shadow btn-xs sharp me-1' title='dfjidfh' onClick={() => handleView(row)} > <i className="fa-solid fa-eye"></i></button>
                                                    </Tooltip>
                                                    <Tooltip title="Edit" arrow>
                                                        <button className='btn btn-primary shadow btn-xs sharp me-1' onClick={() => handleEdit(row)} > <i className="fas fa-pencil-alt"></i></button>
                                                    </Tooltip>
                                                    <Tooltip title="Payment settings" arrow>
                                                        <button className='btn btn-secondary shadow btn-xs sharp me-1' onClick={() => handleSettings(row)} > <i class="fa-regular fa-credit-card"></i></button>
                                                    </Tooltip>
                                                    <Tooltip title="delete" arrow>
                                                        <button className='btn btn-danger shadow btn-xs sharp' onClick={() => handleDelete(row)} > <i className="fa fa-trash"></i></button>
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody> :
                                    <tbody>
                                        <tr>
                                            <td colSpan={6} className='text-center p-5'><Empty /></td>
                                        </tr>
                                    </tbody>}
                            </table>
                        </div>
                    </div>
                </div>
                {tableInstance.rows && tableInstance.rows.length > 0 && <TablePaginationHelper page={page} setPage={setPage} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage} totalnumber={tableInstance.rows.length} />}
                {openModal && <RestaurantAddEditModal openModal={openModal} setOpenModal={setOpenModal} editData={editData} setEditData={setEditData} />}
                {openViewModal && <ViewRestaurantModal openViewModal={openViewModal} setOpenViewModal={setOpenViewModal} viewData={viewData} />}
            </div>}
            {paymentFlag &&
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">{t('profile.payment.paymentconfig') + '(' + settingData?.RestaurantName + ')'}</h4>
                        <div className='d-flex justify-content-md-around'>
                            {<Button onClick={handlepaymentclose}>{t('buttons.back')}</Button>}
                        </div>
                    </div>
                    <div className="card-body">
                        <table className="table table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">{t('profile.payment.paymenttype')}</th>
                                    <th scope="col">{t('profile.payment.enabled')}</th>
                                    <th scope="col" className='text-center'>{t('table.action')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* {paymentGateWayList && paymentGateWayList.length > 0 && paymentGateWayList.map((item, index) => {
                                    // let checked = paymentGateWayList.filter((items) => items.PaymentType == item.id)
                                    return <tr key={index}>
                                        <th scope="row" style={{ width: '65%' }}>{item.PaymentMode}</th>
                                        <td style={{ width: '15%' }}>
                                            <Switch
                                                checked={item.IsActive == '1' ? true : false}
                                                onChange={(e) => handleChangeForEnable(e, item)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </td>
                                        <td className='text-end' style={{ width: '20%' }}>
                                            <Button variant="outline-info" onClick={() => { handlePaymentSettings(item) }}>{t('profile.payment.manage')}</Button>
                                        </td>
                                    </tr>
                                })} */}
                                {paymentGetways && paymentGetways.length > 0 && paymentGetways.map((item, index) => {
                                    let checked = paymentGateWayList.filter((items) => items.PaymentType == item.PaymentType)
                                    return <tr key={index}>
                                        <th scope="row" style={{ width: '65%' }}>{item.name}</th>
                                        <td style={{ width: '15%' }}>
                                            <Switch
                                                disabled={checked.length <= 0 ? true : false}
                                                checked={checked[0]?.IsActive == '1' ? true : false}
                                                onChange={(e) => handleChangeForEnable(e, checked[0])}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </td>
                                        <td className='text-end' style={{ width: '20%' }}>
                                            <Button variant="outline-info" onClick={() => { handlePaymentSettings(item) }}>{t('profile.payment.manage')}</Button>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                    {openStripeModal && <StripeEditModal openStripeModal={openStripeModal} setOpenStripeModal={setOpenStripeModal} paymentData={paymentData} settingData={settingData} handleSettings={handleSettings} />}
                    {openCashModal && <CashEditModal openCashModal={openCashModal} setOpenCashModal={setOpenCashModal} paymentData={paymentData} settingData={settingData} handleSettings={handleSettings} />}
                    {openVivaModal && <VivaEditModal openVivaModal={openVivaModal} setOpenVivaModal={setOpenVivaModal} paymentData={paymentData} settingData={settingData} handleSettings={handleSettings} />}
                    {openPaymentSenseModal && <PaymentSenseEditModal openPaymentSenseModal={openPaymentSenseModal} setOpenPaymentSenseModal={setOpenPaymentSenseModal} paymentData={paymentData} settingData={settingData} handleSettings={handleSettings} />}
                </div>
            }
        </>
    )
}

export default RestaurantList;
