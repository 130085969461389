
export const RESTAURANTCOLUMN = [
	
	{
		Header : 'restaurantlistscreen.restaurantname',
		accessor: 'RestaurantName'
	},
	{
		Header : 'restaurantlistscreen.contactno',
		accessor: 'ContactNumber'
	},
	{
		Header : 'restaurantlistscreen.email',
		accessor: 'EmailAddress'
	},
]
