import React from 'react'
import { Button, Modal, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const ViewOrderDetailsModal = (props) => {
    const { t } = useTranslation('common')
    const { openDetailsModal, setOpenDetailsModal, orderDetails } = props

    const handleViewModalClose = () => {
        setOpenDetailsModal(false)
    }

    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openDetailsModal}
            // onHide={handleViewModalClose}
            size="lg"
            centered
        >
            <Modal.Header>
                <Modal.Title>{t('transactionscreen.customarorderdetail')}</Modal.Title>
                <Button
                    variant=""
                    className="btn-close"
                    onClick={handleViewModalClose}
                >
                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-4">
                        <h4>{t('transactionscreen.customardetails')}</h4>
                        <div>
                            <p>{t('transactionscreen.name')}: {orderDetails.OrderDetail[0]?.Customer_Name}</p>
                            <p>{t('transactionscreen.contactno')}: {orderDetails.OrderDetail[0]?.Customer_Mobile}</p>
                        </div>
                    </div>
                    <div className="col-4">
                        <h4>{t('transactionscreen.orderdetails')}</h4>
                        <div>
                            <p>{t('transactionscreen.orderdate')}: {orderDetails.OrderDetail[0]?.DateOfOrder}</p>
                            <p>{t('transactionscreen.ordertype')}: {orderDetails.OrderDetail[0]?.OrderType == '1' ? 'Delivary' : (orderDetails.OrderDetail[0]?.OrderType == '2' ? 'Collection' : 'Dine-in')}</p>
                            <p>{t('transactionscreen.paymentmode')}: {orderDetails.OrderDetail[0]?.PaymentModeName}</p>
                            <p>{t('transactionscreen.orderdeliveredtime')}: {orderDetails.OrderDetail[0]?.DeliveryTime}</p>
                        </div>
                    </div>
                    <div className="col-4">
                        <h4>{t('transactionscreen.ordernote')}</h4>
                        <div>
                            <p className='breakAll'>{orderDetails.OrderDetail[0]?.OrderNote}</p>
                        </div>
                    </div>
                </div>
                <hr style={{ opacity: "0.7" }} />
                <div className="order-menu style-1 mt-3">
                    <Table responsive>
                        <thead>
                            <tr>
                                <th><strong>{t('ordermasterscreen.orderid')}</strong></th>
                                <th><strong>{t('ordermasterscreen.item')}</strong></th>
                                <th><strong>{t('ordermasterscreen.price')}</strong></th>
                                <th><strong>{t('ordermasterscreen.qty')}</strong></th>
                                <th className='text-end'><strong>{t('ordermasterscreen.subtotal')}</strong></th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderDetails && orderDetails.OrderDetail.length > 0 &&
                                orderDetails.OrderDetail.map((item, index) => {
                                    return <tr key={index}>
                                        <th>{item.OrderId}</th>
                                        <td>{item.ItemName} <br /> {item.ToppingNames != '' ? '(' + item.ToppingNames + ')' : ''}</td>
                                        <td>
                                            {t("currency", { Number: parseFloat((parseFloat(item.Price) / parseFloat(item.Quantity)) + parseFloat(item.ToppingPrice)) })}
                                            <br />
                                            {item.Promotion_Name != '' && <strike className='text-red'>
                                                {t("currency", { Number: parseFloat(parseFloat(item.SubTotalBeforePromotion) + parseFloat(item.ToppingSubTotalBeforePromotion)) })}
                                            </strike>}
                                        </td>
                                        <td>{item.Quantity}</td>
                                        <td className=" text-end">
                                            {t("currency", { Number: parseFloat(parseFloat(item.ToppingSubTotal) + parseFloat(item.SubTotal)) })}
                                            <br />
                                            {item.Promotion_Name != '' &&
                                                <>
                                                    <strike className='text-red'>
                                                        {t("currency", { Number: parseFloat((parseFloat(item.SubTotalBeforePromotion) + parseFloat(item.ToppingSubTotalBeforePromotion)) * parseFloat(item.Quantity)) })}
                                                    </strike>
                                                    <br />
                                                    <span className='text-green'>{item.Promotion_Name}</span>
                                                </>
                                            }
                                        </td>
                                    </tr>
                                })
                            }

                        </tbody>
                    </Table>
                </div>
                <div className="row">
                    <div className="offset-lg-4 col-lg-8">
                        <div className="d-flex align-items-center justify-content-between">
                            <h4 className="font-w500 mb-0">{t('ordermasterscreen.sub_total')}</h4>
                            <h4 className="cate-title text-primary">

                                {orderDetails.OrderTotal.SubTotalBeforePromotion != orderDetails.OrderTotal.SubTotalWithoutDeduction &&
                                    <>
                                        <strike className='text-red'>
                                            {t("currency", { Number: parseFloat(orderDetails.OrderTotal.SubTotalBeforePromotion) })}
                                        </strike>
                                    </>
                                }
                                &nbsp;
                                {t("currency", { Number: parseFloat(orderDetails.OrderTotal.SubTotalWithoutDeduction) })}
                            </h4>
                        </div>
                        {orderDetails.OrderDetail[0].SmallOrderFee > 0 &&
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="font-w500 mb-0">{t('ordermasterscreen.smallorderfee')}</h4>
                                <h4 className="cate-title text-primary">{t("currency", { Number: parseFloat(orderDetails.OrderDetail[0].SmallOrderFee) })}</h4>
                            </div>
                        }
                        {orderDetails.OrderDetail[0].ShippingCharge > 0 &&
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="font-w500 mb-0">{t('ordermasterscreen.delivarycharges')}</h4>
                                <h4 className="cate-title text-primary">{t("currency", { Number: parseFloat(orderDetails.OrderDetail[0].ShippingCharge) })}</h4>
                            </div>}
                        {orderDetails.OrderDetail[0].ServiceCharge > 0 &&
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="font-w500 mb-0">{t('ordermasterscreen.servicecharges')}</h4>
                                <h4 className="cate-title text-primary">{t("currency", { Number: parseFloat(orderDetails.OrderDetail[0].ServiceCharge) })}</h4>
                            </div>}
                        {orderDetails.OrderTotal.SubTotalBeforePromotion != orderDetails.OrderTotal.SubTotalWithoutDeduction &&
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="font-w500 mb-0">{t('ordermasterscreen.restaurantpromo')}</h4>
                                <h4 className="cate-title text-primary">-{t("currency", { Number: parseFloat(parseFloat(orderDetails.OrderTotal.SubTotalBeforePromotion) - parseFloat(orderDetails.OrderTotal.SubTotalWithoutDeduction)) })}</h4>
                            </div>
                        }
                        <div className="d-flex align-items-center justify-content-between">
                            <h4 className="font-w500 mb-0">{t('ordermasterscreen.payableamount')}</h4>
                            <h4 className="cate-title text-primary">{t("currency", { Number: parseFloat(orderDetails.OrderTotal.GrandTotal) })}</h4>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ViewOrderDetailsModal
