import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';
import TablePaginationHelper from '../../../helpers/Pagination';
import TableSearch from '../../../helpers/TableSearch';
import { Empty } from 'antd';
import { styled } from "@mui/material/styles";
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, OutlinedInput, Select, Switch, TextField } from '@mui/material';
import { getOrderDetailsApi, getTransactionListApi } from '../../../services/TransactionServices';
import { TRANSACTIONCOLUMNS } from './TransactionColumns';
import { Form, Formik } from 'formik';
import { PaymentType, StatusforTransaction, symbolsArr } from '../../../constants/constants';
import { Button } from 'react-bootstrap';
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver';
import ViewTransactionModal from './ViewTransactionModal';
import ViewOrderDetailsModal from './ViewOrderDetailsModal';
import { Link } from 'react-router-dom';
const initialvalue = {
    start_date: '',
    end_date: '',
    restaurantId: "",
    paymentTypeId: "",
    statusId: "",
    searchByCityOrPostcode: "",
}

const TransactionList = () => {
    const dispatch = useDispatch()
    const [transactionList, setTransactionList] = useState([]);
    const [filterData, setFilterData] = useState({});
    const [openViewModal, setOpenViewModal] = useState(false);
    const [viewData, setViewData] = useState({});
    const [openDetailsModal, setOpenDetailsModal] = useState(false);
    const [orderDetails, setOrderDetails] = useState({});
    const { restaurantList } = useSelector((state) => state.restaurantList);
    const { t } = useTranslation('common');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const currencySymbol = localStorage.getItem('currencySymbol')

    const columns = TRANSACTIONCOLUMNS
    const data = transactionList
    const tableInstance = useTable({ columns, data, }, useGlobalFilter, useSortBy)
    const { getTableProps, getTableBodyProps, headerGroups, rows, allColumns, visibleColumns, prepareRow, setGlobalFilter, state } = tableInstance
    const { globalFilter } = state

    const handleView = (row) => {
        setViewData(row.original)
        setOpenViewModal(true)
    }

    const handleDetalis = (data) => {
        const obj = { 'RestaurantId': data.original.RestaurantId, "OrderId": data.original.OrderId, "UserId": "0" }
        dispatch(getOrderDetailsApi(obj, (res) => {
            setOrderDetails(res.data)
            setOpenDetailsModal(true)
        }))
    }

    const handleReset = () => {
        setTransactionList([])
        setFilterData(initialvalue)
    }

    const handleSubmit = (values) => {
        setFilterData(values)
        dispatch(getTransactionListApi(values, (res) => {
            setTransactionList(res.data)
        }))
    }

    const handlePrint = () => {
        var content = document.getElementsByClassName('printDivTransaction')
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(content[0].innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    }

    const exportToCSV = () => {
        const workbook = XLSX.utils.book_new();
        const headerMapping = {}
        headerMapping['OrderType'] = 'Order Type'
        headerMapping['OrderId'] = 'Order Id'
        headerMapping['RestaurantName'] = 'Restaurant'
        headerMapping['PaymentType'] = 'Payment Type'
        headerMapping['Status'] = 'Status'
        headerMapping['Quantity'] = 'Order Quantity'
        headerMapping['SubTotal'] = `Subtotal(${currencySymbol})`
        headerMapping['ShippingCharge'] = `Delivery fees(${currencySymbol})`
        headerMapping['ServiceCharge'] = `Service Charge(${currencySymbol})`
        headerMapping['SmallOrderFee'] = `Small Order Fees(${currencySymbol})`
        headerMapping['Discount'] = `Discount(${currencySymbol})`
        headerMapping['GrandTotal'] = `Grand Total(${currencySymbol})`
        headerMapping['OrderDateTime'] = 'Order Date'

        const headers = Object.values(headerMapping);

        const values = transactionList.map(item => Object.keys(headerMapping).map(key => item[key]));

        const headerWorksheet = XLSX.utils.aoa_to_sheet([headers]);

        // Create a worksheet for the actual data
        const dataWorksheet = XLSX.utils.aoa_to_sheet([headers, ...values]);

        XLSX.utils.book_append_sheet(workbook, headerWorksheet, 'Headers');
        XLSX.utils.book_append_sheet(workbook, dataWorksheet, 'Data');
        const csvData = XLSX.utils.sheet_to_csv(workbook.Sheets.Data, { FS: ',' });


        const modifiedAdditionalData = {};
        modifiedAdditionalData['Start Date'] = filterData.start_date
        modifiedAdditionalData['End Date'] = filterData.end_date
        if (filterData.restaurantId != '') {
            modifiedAdditionalData['Restaurant Name'] = restaurantList && restaurantList.length > 0 && restaurantList.filter((item) => item.RID == filterData.restaurantId)[0]?.RestaurantName
        } else {
            modifiedAdditionalData['Restaurant Name'] = ''
        }

        if (filterData.paymentTypeId != '') {
            modifiedAdditionalData['Payment Type'] = PaymentType && PaymentType.length > 0 && PaymentType.filter((item) => item.id == filterData.paymentTypeId)[0]?.type
        } else {
            modifiedAdditionalData['Payment Type'] = ''
        }
        if (filterData.statusId != '') {
            modifiedAdditionalData['Status'] = StatusforTransaction && StatusforTransaction.length > 0 && StatusforTransaction.filter((item) => item.id == filterData.statusId)[0]?.status
        } else {
            modifiedAdditionalData['Status'] = ''
        }

        modifiedAdditionalData['Post Code'] = filterData.searchByCityOrPostcode
        const additionalDataArray = Object.entries(modifiedAdditionalData);
        const additionalDataRows = additionalDataArray.map(([key, value]) => `${key},${value ? value : ''}`);

        const additionalDataString = additionalDataRows.join('\n');

        const blob = new Blob([`${additionalDataString}\n\n\n${csvData}`], { type: 'text/csv;charset=utf-8' });

        saveAs(blob, 'Foodel.csv');
    };

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <Formik
                        initialValues={initialvalue}
                        onSubmit={handleSubmit}
                    >
                        {(props) => {
                            const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                            return (
                                <Form onSubmit={handleSubmit} noValidate>
                                    <div className="row">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <FormControl size="small" variant="outlined" fullWidth>
                                                    <TextField
                                                        onKeyDown={() => { "return false" }}
                                                        fullWidth
                                                        inputProps={{ max: values.end_date }}
                                                        type="date"
                                                        name='start_date'
                                                        variant="outlined"
                                                        label={t('transactionscreen.startdate')}
                                                        InputLabelProps={{ shrink: true }}
                                                        onChange={handleChange}
                                                        value={values.start_date}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormControl size="small" variant="outlined" fullWidth>
                                                    <TextField
                                                        onKeyDown={() => { "return false" }}
                                                        fullWidth
                                                        type="date"
                                                        inputProps={{ min: values.start_date ? new Date(values.start_date).toISOString().slice(0, 10) : "" }}
                                                        name='end_date'
                                                        variant="outlined"
                                                        label={t('transactionscreen.enddate')}
                                                        InputLabelProps={{ shrink: true }}
                                                        onChange={handleChange}
                                                        value={values.end_date}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="row pt-4">
                                            <div className="col-lg-3">
                                                <FormControl fullWidth>
                                                    <TextField
                                                        select
                                                        fullWidth
                                                        value={values.restaurantId}
                                                        label={t('transactionscreen.restaurant')}
                                                        name='restaurantId'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    >
                                                        {restaurantList && restaurantList.length > 0 && restaurantList.map((item, index) => {
                                                            return <MenuItem key={index} value={item.RID}>{item.RestaurantName}</MenuItem>
                                                        })}
                                                    </TextField>
                                                </FormControl>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormControl fullWidth>
                                                    <TextField
                                                        select
                                                        fullWidth
                                                        value={values.paymentTypeId}
                                                        label={t('transactionscreen.paymenttype')}
                                                        name='paymentTypeId'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    >
                                                        <MenuItem value=''>None</MenuItem>
                                                        {PaymentType && PaymentType.length > 0 && PaymentType.map((item, index) => {
                                                            return <MenuItem key={index} value={item.id}>{item.type}</MenuItem>
                                                        })}
                                                    </TextField>
                                                </FormControl>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormControl fullWidth>
                                                    <TextField
                                                        select
                                                        fullWidth
                                                        value={values.statusId}
                                                        label={t('transactionscreen.status')}
                                                        name='statusId'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    >
                                                        <MenuItem value=''>None</MenuItem>
                                                        {StatusforTransaction && StatusforTransaction.length > 0 && StatusforTransaction.map((item, index) => {
                                                            return <MenuItem key={index} value={item.id}>{item.status}</MenuItem>
                                                        })}
                                                    </TextField>
                                                </FormControl>
                                            </div>
                                            <div className="col-lg-3">
                                                <FormControl fullWidth>
                                                    <TextField
                                                        fullWidth
                                                        type='number'
                                                        InputProps={{ inputProps: { min: 0 } }}
                                                        onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                        value={values.searchByCityOrPostcode}
                                                        label={t('transactionscreen.citypostcode')}
                                                        name='searchByCityOrPostcode'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    >
                                                    </TextField>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="row pt-3">
                                            <div className="col-lg-12 text-end">
                                                <Button
                                                    variant="danger light"
                                                    type="reset"
                                                    onClick={handleReset}
                                                >
                                                    {t('buttons.reset')}
                                                </Button>
                                                <button
                                                    className="btn btn-primary ms-2"
                                                    type="submit"
                                                    size="large"
                                                >
                                                    {t('buttons.filter')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{t('transactionscreen.title')}</h4>
                    <div className='d-flex justify-content-md-around transaction width-75'>
                        <div className='me-2'>
                            <Button onClick={handlePrint}>{t('print')}</Button>
                        </div>
                        <div className='me-2'>
                            <Button onClick={exportToCSV}>{t('csv')}</Button>
                        </div>
                        <FormControl sx={{ width: 200 }} className='me-2'>
                            <Select
                                fullWidth
                                multiple
                                value={['Column Visibility']}
                                input={<OutlinedInput label="" />}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {allColumns.map((column) => (
                                    <MenuItem key={column.id} value={column.id} className='pe-0'>
                                        <FormControlLabel className='w-100 me-0' control={<Checkbox  {...column.getToggleHiddenProps()} />} label={t(column.Header)} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TableSearch globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <div className="dataTables_wrapper">
                            <table {...getTableProps()} className="table dataTable display">
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {t(column.render('Header'))}
                                                    <span className="ml-1">
                                                        {column.isSorted ? (
                                                            column.isSortedDesc ?
                                                                <i className="fa fa-arrow-down ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                                :
                                                                <i className="fa fa-arrow-up ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                        )
                                                            :
                                                            (<i className="fa fa-sort ms-2 fs-14" style={{ opacity: '0.3' }} />)
                                                        }
                                                    </span>
                                                </th>
                                            ))}
                                            <th className='fixedColumn text-end'>{t('table.action')}</th>
                                        </tr>
                                    ))}
                                </thead>
                                {tableInstance.rows && tableInstance.rows.length > 0 ? <tbody {...getTableBodyProps()}>
                                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map(cell => {
                                                    if (cell.column.id == "OrderId") {
                                                        return <td {...cell.getCellProps()} className='cursor-pointer' onClick={() => handleDetalis(row)}> <span className='fw-normal color-primary'>{row.original.OrderId} </span></td>
                                                    }
                                                    if (cell.column.id == "SubTotal" || cell.column.id == "ShippingCharge" || cell.column.id == "ServiceCharge" || cell.column.id == "SmallOrderFee" || cell.column.id == "Discount" || cell.column.id == "GrandTotal") {
                                                        return <td {...cell.getCellProps()}>{t("currency", { Number: parseFloat(cell.value) })} </td>
                                                    }
                                                    if (cell.column.id == "OrderDateTime") {
                                                        return <td {...cell.getCellProps()}>{t("date_format", { date: new Date(cell.value) })}</td>
                                                    }
                                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                })}
                                                {
                                                    visibleColumns.length > 0 && <td className='fixedColumn text-end d-flex align-items-center justify-content-end'>
                                                        <button className='btn btn-info shadow btn-xs sharp me-1' onClick={() => handleView(row)} > <i className="fa-solid fa-eye"></i></button>
                                                    </td>
                                                }
                                            </tr>
                                        )
                                    })}
                                </tbody> :
                                    <tbody>
                                        <tr>
                                            <td colSpan={7} className='text-center p-5'><Empty /></td>
                                        </tr>
                                    </tbody>}
                            </table>
                        </div>
                    </div>
                </div>
                {tableInstance.rows && tableInstance.rows.length > 0 && <TablePaginationHelper page={page} setPage={setPage} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage} totalnumber={tableInstance.rows.length} />}
            </div >
            {openViewModal && <ViewTransactionModal openViewModal={openViewModal} setOpenViewModal={setOpenViewModal} viewData={viewData} />}
            {openDetailsModal && <ViewOrderDetailsModal openDetailsModal={openDetailsModal} setOpenDetailsModal={setOpenDetailsModal} orderDetails={orderDetails} />}
            <div className='printDivTransaction d-none' style={{ margin: '0px', padding: '0px' }}>
                <div className="row">
                    <div className="col-lg-12">
                        <h2>{t('transactionscreen.title')}</h2>
                    </div>
                    <div className="col-lg-12">
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '33.33%' }}>
                                <span style={{ marginBottom: '0px', fontWeight: '600', fontSize: '16px' }}>{t('transactionscreen.restaurantname')}:</span>
                                <br />
                                <span>{restaurantList && restaurantList.length > 0 && restaurantList.filter((item) => item.RID == filterData.restaurantId)[0]?.RestaurantName}</span>
                            </div>
                            <div style={{ width: '33.33%' }}>
                                <span style={{ marginBottom: '0px', fontWeight: '600', fontSize: '16px' }}>{t('transactionscreen.paymenttype')}:</span>
                                <br />
                                <span>{PaymentType && PaymentType.length > 0 && PaymentType.filter((item) => item.id == filterData.paymentTypeId)[0]?.type}</span>
                            </div>
                            <div style={{ width: '33.33%' }}>
                                <span style={{ marginBottom: '0px', fontWeight: '600', fontSize: '16px' }}>{t('transactionscreen.status')}:</span>
                                <br />
                                <span>{StatusforTransaction && StatusforTransaction.length > 0 && StatusforTransaction.filter((item) => item.id == filterData.statusId)[0]?.status}</span>
                            </div>
                        </div>
                        <div style={{ display: 'flex', paddingBottom: '20px', paddingTop: '10px' }}>
                            <div style={{ width: '33.33%' }}>
                                <span style={{ marginBottom: '0px', fontWeight: '600', fontSize: '16px' }}>{t('transactionscreen.startdate')}:</span>
                                <br />
                                <span>{filterData.start_date}</span>
                            </div>
                            <div style={{ width: '33.33%' }}>
                                <span style={{ marginBottom: '0px', fontWeight: '600', fontSize: '16px' }}>{t('transactionscreen.enddate')}:</span>
                                <br />
                                <span>{filterData.end_date}</span>
                            </div>
                            <div style={{ width: '33.33%' }}>
                                <span style={{ marginBottom: '0px', fontWeight: '600', fontSize: '16px' }}>{t('transactionscreen.citypostcode')}:</span>
                                <br />
                                <span>{filterData.searchByCityOrPostcode}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <table style={{ borderCollapse: 'collapse' }}>
                    <thead style={{ border: '1px solid gray' }}>
                        <tr>
                            <th style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }}>{t('transactionscreen.ordertype')}</th>
                            <th style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }}>{t('transactionscreen.orderid')}</th>
                            <th style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }}>{t('transactionscreen.restaurant')}</th>
                            <th style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }}>{t('transactionscreen.paymenttype')}</th>
                            <th style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }}>{t('transactionscreen.status')}</th>
                            <th style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }}>{t('transactionscreen.orderquantity')}</th>
                            <th style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }}>{t('transactionscreen.subtotal')}</th>
                            <th style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }}>{t('transactionscreen.delivaryfee')}</th>
                            <th style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }}>{t('transactionscreen.servicecharge')}</th>
                            <th style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }}>{t('transactionscreen.smallorderfees')}</th>
                            <th style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }}>{t('transactionscreen.discount')}</th>
                            <th style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }}>{t('transactionscreen.grandtotal')}</th>
                            <th style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }}>{t('transactionscreen.orderdate')}</th>
                        </tr>
                    </thead>
                    <tbody style={{ borderBottom: '1px solid gray' }}>
                        {rows.map((row, index) => {
                            prepareRow(row)
                            return (
                                <tr key={index} >
                                    <td style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }} >{row.original.OrderType} </td>
                                    <td style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }} > {row.original.OrderId} </td>
                                    <td style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }} > {row.original.RestaurantName} </td>
                                    <td style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }} > {row.original.PaymentType} </td>
                                    <td style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }} > {row.original.Status} </td>
                                    <td style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }} > {row.original.Quantity} </td>
                                    <td style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }} > {t("currency", { Number: parseFloat(row.original.SubTotal) })} </td>
                                    <td style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }} > {t("currency", { Number: parseFloat(row.original.ShippingCharge) })} </td>
                                    <td style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }} > {t("currency", { Number: parseFloat(row.original.ServiceCharge) })} </td>
                                    <td style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }} > {t("currency", { Number: parseFloat(row.original.SmallOrderFee) })} </td>
                                    <td style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }} > {t("currency", { Number: parseFloat(row.original.Discount) })} </td>
                                    <td style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }} > {t("currency", { Number: parseFloat(row.original.GrandTotal) })} </td>
                                    <td style={{ borderLeft: '1px solid gray', borderRight: '1px solid gray', fontSize: '12px', textAlign: 'center' }} > {t("date_format", { date: new Date(row.original.OrderDateTime) })} </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div >
            <iframe id="ifmcontentstoprint" style={{ height: "0px", width: "0px", position: "absolute" }}></iframe>
        </>
    )
}


export default TransactionList;


