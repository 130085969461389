import { TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ImageInput from '../../../helpers/fileUploader/ImageInput'
import { S3ImageUpload } from '../../../constants/constants'
import { getProfileDataApi, updateProfileApi } from '../../../services/ProfileServices'
import { useDispatch } from 'react-redux'
import { S3ImageDelete, S3Uploads } from '../../../helpers/S3Upload/S3upload'
import swal from 'sweetalert'
import { Logout } from '../../../store/actions/AuthActions'
import { useNavigate } from 'react-router-dom'

const Profile = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { t } = useTranslation('common')
    const [profileData, setProfileData] = useState({})
    const [deleteImagePath, setDeleteImagePath] = useState('')


    useEffect(() => {
        dispatch(getProfileDataApi((res) => {
            setProfileData(res.data)
        }))
    }, [])


    const onsubmit = (values) => {
        if (values.EmailAddress != profileData.EmailAddress) {
            swal({
                title: "Are you sure?",
                text:
                    "If you change Email address You need to re-login",
                icon: "info",
                buttons: {
                    cancel: 'No',
                    confirm: 'Yes',
                },
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    if (deleteImagePath != '') {
                        S3ImageDelete(deleteImagePath)
                    }
                    if (typeof values.ProfilePic == 'object') {
                        const fileNameForProfile = Date.now() + '_' + values.ProfilePic.name.replace(/ /g, "_")
                        const filePathForProfile = `adminprofile/${fileNameForProfile}`
                        let ProfileUploadstatus = S3Uploads(values.ProfilePic, filePathForProfile)
                        if (ProfileUploadstatus) {
                            values.ProfilePic = fileNameForProfile
                        }
                    } else {
                        values.ProfilePic = values.ProfilePic
                    }

                    dispatch(updateProfileApi(values, (ress) => {
                        Logout(navigate)
                    }))
                }
            })
        }
        else {
            if (deleteImagePath != '') {
                S3ImageDelete(deleteImagePath)
            }
            if (typeof values.ProfilePic == 'object') {
                const fileNameForProfile = Date.now() + '_' + values.ProfilePic.name.replace(/ /g, "_")
                const filePathForProfile = `adminprofile/${fileNameForProfile}`
                let ProfileUploadstatus = S3Uploads(values.ProfilePic, filePathForProfile, fileNameForProfile)
                if (ProfileUploadstatus) {
                    values.ProfilePic = fileNameForProfile
                }
            } else {
                values.ProfilePic = values.ProfilePic
            }

            dispatch(updateProfileApi(values, (ress) => {
                dispatch(getProfileDataApi((res) => {
                    setProfileData(res.data)
                }))
            }))
        }
    }

    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">{t('adminprofile.title')}</h4>
            </div>
            <Formik
                enableReinitialize
                initialValues={{
                    FirstName: profileData && profileData.FirstName ? profileData.FirstName : '',
                    LastName: profileData && profileData.LastName ? profileData.LastName : '',
                    EmailAddress: profileData && profileData.EmailAddress ? profileData.EmailAddress : '',
                    ProfilePic: profileData && profileData.ProfilePic ? profileData.ProfilePic : '',
                    ProfilePicSrc: profileData?.ProfilePic ? `${S3ImageUpload}adminprofile/${profileData?.ProfilePic}` : ''
                }}

                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, isValid, isDisabled, handleBlur, setFieldValue, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Form onSubmit={handleSubmit} noValidate>
                                <div className="card-body">
                                    <div className="row">
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                error={Boolean(errors.FirstName) && touched.FirstName}
                                                helperText={(Boolean(errors.FirstName) && touched.FirstName) ? errors.FirstName : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="FirstName"
                                                label={t('adminprofile.firstname')}
                                                value={values.FirstName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                error={Boolean(errors.LastName) && touched.LastName}
                                                helperText={(Boolean(errors.LastName) && touched.LastName) ? errors.LastName : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="LastName"
                                                label={t('adminprofile.lastname')}
                                                value={values.LastName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className='col-12 pt-3'>
                                            <TextField
                                                required
                                                error={Boolean(errors.EmailAddress) && touched.EmailAddress}
                                                helperText={(Boolean(errors.EmailAddress) && touched.EmailAddress) ? errors.EmailAddress : ''}
                                                fullWidth
                                                onBlur={handleBlur}
                                                name="EmailAddress"
                                                label={t('adminprofile.email')}
                                                value={values.EmailAddress}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-12 pt-3">
                                            <h6>{t('adminprofile.profilepicture')}</h6>
                                            <div className="d-flex">
                                                <ImageInput
                                                    imageWidth={200}
                                                    imageHeight={200}
                                                    imageData={values.ProfilePicSrc}
                                                    type="Admin Profile"
                                                    name="ProfilePic"
                                                    label="Add Photo"
                                                    showPreview
                                                    onChange={(e) => {
                                                        if (profileData?.ProfilePic == values.ProfilePic) {
                                                            const path = `adminprofile/${values.ProfilePic}`
                                                            setDeleteImagePath(path)
                                                        }
                                                        setFieldValue('ProfilePic', e.ProfilePic.data);
                                                        setFieldValue('ProfilePicSrc', e.ProfilePic.src);
                                                    }}
                                                />
                                                {values.ProfilePicSrc &&
                                                    <div className='ps-3'>
                                                        <button type='button' className='btn btn-danger shadow btn-xs sharp me-1'
                                                            onClick={() => {
                                                                if (profileData?.ProfilePic == values.ProfilePic) {
                                                                    const path = `adminprofile/${values.ProfilePic}`
                                                                    setDeleteImagePath(path)
                                                                    setFieldValue('ProfilePic', '');
                                                                    setFieldValue('ProfilePicSrc', '');
                                                                } else {
                                                                    setFieldValue('ProfilePic', '');
                                                                    setFieldValue('ProfilePicSrc', '');
                                                                }
                                                            }}>
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </div>
                            </Form >
                        </>
                    );
                }}
            </Formik>
        </div>
    )
}

export default Profile
