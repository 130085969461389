import { Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as Yup from 'yup';
// image
import logo from "../../images/logo-full.png";
import TextField from '@mui/material/TextField';
import { Button } from "react-bootstrap";
import { resetPassword } from "../../services/AuthService";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment } from "@mui/material";

const ResetPassword = ({ history }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const params = useParams()

    const onSubmit = (values) => {
        const obj = {
            email: params.slug,
            password: values.password,
        }

        dispatch(resetPassword(obj, (res) => {
            if (res.status == 1) {
                navigate("/admin/login");
            }
        }))
    };

    const handleCancle = () => {
        navigate("/admin/login");
    }


    return (
        <div className="authincation h-100 p-meddle">
            <div className="container h-100">
                {" "}
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-md-6">
                        <div className="authincation-content">
                            <div className="row no-gutters">
                                <div className="col-xl-12">
                                    <div className="auth-form">
                                        <h4 className="text-center mb-4 ">Reset Password</h4>
                                        <Formik
                                            initialValues={{
                                                password: '',
                                                showPassword: false,
                                                confirmPassword: '',
                                                showconfirmPassword: false
                                            }}
                                            validationSchema={Yup.object().shape({
                                                password: Yup.string()
                                                    .required('Password is required.')
                                                    .min(6, 'Password must contain atleast 6 characters.'),
                                                confirmPassword: Yup.string().when('password', {
                                                    is: value => value && value.length > 0,
                                                    then: Yup.string()
                                                        .required('Re - enter password.')
                                                        .min(6, 'Password must contain atleast 6 characters.')
                                                        .oneOf([Yup.ref('password'), null], 'Must match password.'),
                                                    otherwise: Yup.string(),
                                                }),
                                            })}
                                            onSubmit={onSubmit}>
                                            {props => {
                                                const { values, touched, errors, handleBlur, setFieldValue, handleChange, handleSubmit } = props;
                                                return (
                                                    <>
                                                        <div className="mb-3">
                                                            <TextField
                                                                required
                                                                InputProps={{
                                                                    endAdornment:
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                aria-label="toggle password visibility"
                                                                                onClick={(e) => { setFieldValue('showPassword', !values.showPassword) }}
                                                                                onMouseDown={(e) => e.preventDefault()}
                                                                                edge="end"
                                                                            >
                                                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                                            </IconButton>
                                                                        </InputAdornment>,
                                                                }}
                                                                type={values.showPassword ? "text" : "password"}
                                                                fullWidth
                                                                variant="outlined"
                                                                name="password"
                                                                label="Password"
                                                                value={values.password}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                error={Boolean(errors.password) && touched.password}
                                                                helperText={Boolean(errors.password) && touched.password ? errors.password : ''}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <TextField
                                                                required
                                                                InputProps={{
                                                                    endAdornment:
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                aria-label="toggle password visibility"
                                                                                onClick={(e) => { setFieldValue('showconfirmPassword', !values.showconfirmPassword) }}
                                                                                onMouseDown={(e) => e.preventDefault()}
                                                                                edge="end"
                                                                            >
                                                                                {values.showconfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                                            </IconButton>
                                                                        </InputAdornment>,
                                                                }}
                                                                type={values.showconfirmPassword ? "text" : "password"}
                                                                fullWidth
                                                                variant="outlined"
                                                                name="confirmPassword"
                                                                label="Confirm Password"
                                                                value={values.confirmPassword}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                error={Boolean(errors.confirmPassword) && touched.confirmPassword}
                                                                helperText={Boolean(errors.confirmPassword) && touched.confirmPassword ? errors.confirmPassword : ''}
                                                            />
                                                        </div>
                                                        <div display="flex" className="text-end" mb={4}>
                                                            <Button variant="contained" color="primary" onClick={handleCancle}>Cancel</Button>
                                                            <Button onClick={handleSubmit}>
                                                                Change Password
                                                            </Button>
                                                        </div>
                                                    </>
                                                );
                                            }}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
