import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { S3ImageUpload } from '../../../constants/constants'

const ViewRestaurantModal = (props) => {
    const { t } = useTranslation('common')
    const { openViewModal, setOpenViewModal, viewData } = props

    const handleViewModalClose = () => {
        setOpenViewModal(false)
    }
    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openViewModal}
            // onHide={handleViewModalClose}
            size="lg"
            centered
        >
            <Modal.Header>
                <Modal.Title>{t('profile.viewrestaurant')}</Modal.Title>
                <Button
                    variant=""
                    className="btn-close"
                    onClick={handleViewModalClose}
                >

                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className="container mt-0">
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('profile.restaurantprofile.restaurantname')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.RestaurantName}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('profile.restaurantprofile.restaurantexternalurl')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.RestaurantUrl}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('profile.restaurantprofile.externalurlslug')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.UrlSlug}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('profile.restaurantprofile.email')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.EmailAddress}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('profile.restaurantprofile.alternativeemail')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.AlternativeEmail}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('profile.restaurantprofile.mobileno')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.ContactNumber}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('profile.restaurantprofile.shortdescription')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.ShortText}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('profile.restaurantprofile.longdescription')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.Description}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('profile.restaurantimages.restaurantheaderlogoimage')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2"><img className='w-100' src={`${S3ImageUpload}${viewData.RestaurantId}/restaurant/headerlogo/${viewData?.HeaderLogo_URL}`} alt="" /></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('profile.restaurantimages.restaurantlogoimage')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2"><img className='w-50' src={`${S3ImageUpload}${viewData.RestaurantId}/restaurant/profile/${viewData?.LogoUrl}`} alt="" /></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('profile.restaurantimages.restaurantdetailsbannerimage')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2"><img className='w-100' src={`${S3ImageUpload}${viewData.RestaurantId}/restaurant/banner/${viewData?.Home_Banner}`} alt="" /></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('profile.restaurantlinks.appstoreurl')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.AppStoreURL}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('profile.restaurantlinks.Playstoreurl')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.PlayStoreURL}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('profile.restaurantlinks.linkedinurl')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.LinkedInURL}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('profile.restaurantlinks.facebookurl')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.FaceBookURL}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('profile.restaurantlinks.instagramurl')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.InstagramURL}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('profile.restaurantlinks.twitterurl')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.TwitterURL}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('profile.restaurantaddress.location')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.AddressForMap}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('profile.restaurantaddress.latitude')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.Lattitude}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('profile.restaurantaddress.longitude')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.Longitude}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('profile.restaurantsettings.restaurantdeliverytype')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2 mw-ft" >{viewData.DeliveryType && viewData.DeliveryType.includes('1') ? 'Delivary' : ''}</div>
                        <div className="col p-2 ps-0 mw-ft" >{viewData.DeliveryType && viewData.DeliveryType.includes('1') && viewData.DeliveryType && viewData.DeliveryType.includes('2') ? ',' : ''}</div>
                        <div className="col p-2 mw-ft" >{viewData.DeliveryType && viewData.DeliveryType.includes('2') ? 'collection' : ''}</div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 p-2 text-end"><h6>{t('profile.restaurantsettings.restaurantstatusconfiguration')}</h6></div>
                        <div className="col-lg-1 p-2 text-center">:</div>
                        <div className="col p-2">{viewData.Active}</div>
                        <div className="col p-2">{viewData.UseDelivaryApp}</div>
                        <div className="col p-2">{viewData.Popular}</div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ViewRestaurantModal
