import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';
import { COLUMNSFORDELIVARYAREA } from './DelivaryAreaColumns';
import swal from 'sweetalert';
import RestaurantSelect from '../Common/RestaurantSelect';
import TableSearch from '../../../helpers/TableSearch';
import { Button } from 'react-bootstrap';
import { Empty } from 'antd';
import TablePaginationHelper from '../../../helpers/Pagination';
import DelivaryAreaAddEditModal from './DelivaryAreaAddEditModal';
import { DeleteDelivaryArea, GetDelivaryAreaListApi } from '../../../services/delivaryAreaServices';
import ViewdelivaryAreaModal from './ViewdelivaryAreaModal';

const DelivaryArea = () => {
    const dispatch = useDispatch()
    const [delivaryAreaList, setDelivaryAreaList] = useState([])
    const [openModal, setOpenModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [viewData, setViewData] = useState({});
    const [editData, setEditData] = useState({});
    const { t } = useTranslation('common');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [RestaurantId, setRestaurantId] = useState('');


    useEffect(() => {
        if (RestaurantId) {
            dispatch(GetDelivaryAreaListApi({ RestaurantId }, (res) => {
                setDelivaryAreaList(res.data)
            }))
        }
    }, [openModal, RestaurantId])

    const columns = COLUMNSFORDELIVARYAREA
    const data = delivaryAreaList
    const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy)
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter, state } = tableInstance
    const { globalFilter } = state

    const handleEdit = (row) => {
        setEditData(row.original)
        setOpenModal(true)
    }

    const handleAddOpen = () => {
        setEditData()
        setOpenModal(true)
    }


    const handleView = (row) => {
        setViewData(row.original)
        setOpenViewModal(true)
    }


    const handleDelete = (row) => {
        const id = row.original.ID
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover!",
            icon: "warning",
            buttons: {
                confirm: 'Ok',
                cancel: 'Cancel'
            },
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(DeleteDelivaryArea({ id }, (res) => {
                    swal("Promo Code has been deleted!", {
                        icon: "success",
                    });
                    if (RestaurantId) {
                        dispatch(GetDelivaryAreaListApi({ RestaurantId }, (res) => {
                            setDelivaryAreaList(res.data)
                        }))
                    }
                }))


            }
        })
    }


    return (
        <>

            <div className="row pb-3">
                <RestaurantSelect setRestaurantId={setRestaurantId} />
            </div>

            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{t('deliveryareascreen.title')}</h4>
                    <div className='d-flex justify-content-md-around TableSerchInput'>
                        <TableSearch globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                        {RestaurantId && <Button onClick={handleAddOpen}>{t('buttons.adddeliveryarea')}</Button>}
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <div className="dataTables_wrapper">
                            <table {...getTableProps()} className="table dataTable display">
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {t(column.render('Header'))}
                                                    <span className="ml-1">
                                                        {column.isSorted ? (
                                                            column.isSortedDesc ?
                                                                <i className="fa fa-arrow-down ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                                :
                                                                <i className="fa fa-arrow-up ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                        )
                                                            :
                                                            (<i className="fa fa-sort ms-2 fs-14" style={{ opacity: '0.3' }} />)
                                                        }
                                                    </span>
                                                </th>
                                            ))}
                                            <th className='fixedColumn text-end'>{t('table.action')}</th>
                                        </tr>
                                    ))}
                                </thead>
                                {tableInstance && tableInstance.rows && tableInstance.rows.length > 0 ? <tbody {...getTableBodyProps()}>
                                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()}>
                                                <td> {row.original.StartRange} </td>
                                                <td> {row.original.EndRange} </td>
                                                <td className=' fixedColumn text-end'>
                                                    <button className='btn btn-info shadow btn-xs sharp me-1' onClick={() => handleView(row)} > <i className="fa-solid fa-eye"></i></button>
                                                    <button className='btn btn-primary shadow btn-xs sharp me-1' onClick={() => handleEdit(row)} > <i className="fas fa-pencil-alt"></i></button>
                                                    <button className='btn btn-danger shadow btn-xs sharp' onClick={() => handleDelete(row)} > <i className="fa fa-trash"></i></button>
                                                </td>

                                            </tr>
                                        )
                                    })}
                                </tbody> :
                                    <tbody>
                                        <tr>
                                            <td colSpan={6} className='text-center p-5'><Empty /></td>
                                        </tr>
                                    </tbody>}
                            </table>
                        </div>
                    </div>
                </div>
                {tableInstance.rows && tableInstance.rows.length > 0 && <TablePaginationHelper page={page} setPage={setPage} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage} totalnumber={tableInstance.rows.length} />}
            </div>
            {openModal && <DelivaryAreaAddEditModal openModal={openModal} setOpenModal={setOpenModal} editData={editData} setEditData={setEditData} RestaurantId={RestaurantId} />}
            {openViewModal && <ViewdelivaryAreaModal openViewModal={openViewModal} setOpenViewModal={setOpenViewModal} viewData={viewData} />}
        </>
    )
}

export default DelivaryArea
