import { Checkbox, FormControl, FormControlLabel, IconButton, InputAdornment, MenuItem, TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { Button, Modal, Nav, Tab } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { PaymentSensePaymentManage } from '../../../../services/RestaurantServices';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


const PaymentSenseEditModal = (props) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()
    const { openPaymentSenseModal, setOpenPaymentSenseModal, paymentData, settingData, handleSettings } = props

    const onsubmit = (values) => {
        if (paymentData) {
            values.ID = paymentData.ID
        }
        values.RestaurantId = settingData.RID
        dispatch(PaymentSensePaymentManage(values, (res) => {
            setTimeout(() => {
                handleModalClose()
                handleSettings(settingData)
            }, 1);
        }))
    }

    const handleModalClose = () => {
        setOpenPaymentSenseModal(false);

    }


    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openPaymentSenseModal}
            // onHide={handleModalClose}
            size="lg"
            centered
        >

            <Formik
                enableReinitialize
                initialValues={{
                    Payment_Enable: paymentData && paymentData.IsActive == '0' ? false : true,
                    Payment_Enable_TestMode: paymentData && paymentData.Payment_Enable_TestMode == '1' ? true : false,
                    Payment_Name: paymentData && paymentData.Payment_Name ? paymentData.Payment_Name : '',
                    Payment_Description: paymentData && paymentData.Payment_Description ? paymentData.Payment_Description : "",
                    MerchantID: paymentData && paymentData.MerchantID ? paymentData.MerchantID : '',
                    Password: paymentData && paymentData.Password ? paymentData.Password : '',
                    showPassword: false,
                    PreSharedKey: paymentData && paymentData.PreSharedKey ? paymentData.PreSharedKey : '',
                    APIKey: paymentData && paymentData.APIKey ? paymentData.APIKey : '',
                    AccessToken: paymentData && paymentData.AccessToken ? paymentData.AccessToken : '',
                    MerchantID_Test: paymentData && paymentData.MerchantID_Test ? paymentData.MerchantID_Test : '',
                    Password_Test: paymentData && paymentData.Password_Test ? paymentData.Password_Test : '',
                    showPasswordTest: false,
                    PreSharedKey_Test: paymentData && paymentData.PreSharedKey_Test ? paymentData.PreSharedKey_Test : '',
                    APIKey_Test: paymentData && paymentData.APIKey_Test ? paymentData.APIKey_Test : '',
                    AccessToken_Test: paymentData && paymentData.AccessToken_Test ? paymentData.AccessToken_Test : '',
                }}
                validationSchema={
                    Yup.object().shape({
                        Payment_Name: Yup.string().required("name is required."),
                        MerchantID: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: false,
                                then: Yup.string().required("Merchant Id is required")
                            }),
                        Password: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: false,
                                then: Yup.string().required("Password is required")
                            }),
                        PreSharedKey: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: false,
                                then: Yup.string().required("PreShared key is required")
                            }),
                        APIKey: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: false,
                                then: Yup.string().required("API key is required")
                            }),
                        AccessToken: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: false,
                                then: Yup.string().required("Access token is required")
                            }),
                        MerchantID_Test: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: true,
                                then: Yup.string().required("Merchant Id is required")
                            }),
                        Password_Test: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: true,
                                then: Yup.string().required("Password is required")
                            }),
                        PreSharedKey_Test: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: true,
                                then: Yup.string().required("PreShared key is required")
                            }),
                        APIKey_Test: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: true,
                                then: Yup.string().required("API key is required")
                            }),
                        AccessToken_Test: Yup
                            .string()
                            .when("Payment_Enable_TestMode", {
                                is: true,
                                then: Yup.string().required("Access token is required")
                            }),
                    })
                }
                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, isValid, isDisabled, handleBlur, setFieldValue, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Modal.Header>
                                <Modal.Title>{t('profile.payment.settings')}</Modal.Title>
                                <Button
                                    variant=""
                                    className="btn-close"
                                    onClick={handleModalClose}
                                >

                                </Button>
                            </Modal.Header>
                            <Form onSubmit={handleSubmit} noValidate>
                                <Modal.Body>
                                    <div className="row">
                                        <div className='col-12'>
                                            <FormControlLabel
                                                control={<Checkbox checked={values.Payment_Enable} />}
                                                label={`Enable PaymentSense`}
                                                name="Payment_Enable"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-12 pt-3">
                                            {/* <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">{t('profile.payment.displaysettings')}</h4>
                                            </div>
                                            <div className="card-body"> */}
                                            <div className='col-12'>
                                                <TextField
                                                    required
                                                    error={Boolean(errors.Payment_Name) && touched.Payment_Name}
                                                    helperText={(Boolean(errors.Payment_Name) && touched.Payment_Name) ? errors.Payment_Name : ''}
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    name="Payment_Name"
                                                    label={t('profile.payment.name')}
                                                    value={values.Payment_Name}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className='col-12 pt-3'>
                                                <TextField
                                                    multiline
                                                    rows={3}
                                                    error={Boolean(errors.Payment_Description) && touched.Payment_Description}
                                                    helperText={(Boolean(errors.Payment_Description) && touched.Payment_Description) ? errors.Payment_Description : ''}
                                                    fullWidth
                                                    onBlur={handleBlur}
                                                    name="Payment_Description"
                                                    label={t('profile.payment.description')}
                                                    value={values.Payment_Description}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className='col-12'>
                                                <FormControlLabel
                                                    control={<Checkbox checked={values.Payment_Enable_TestMode} />}
                                                    label={t('profile.payment.enabletestmode')}
                                                    name="Payment_Enable_TestMode"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className='col-12 '>
                                                <Tab.Container defaultActiveKey='live'>
                                                    <Nav as='ul' className="nav nav-tabs">
                                                        <Nav.Item as='li' className="nav-item">
                                                            <Nav.Link to="#live" eventKey='live'>{t('profile.payment.live')}</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item as='li' className="nav-item">
                                                            <Nav.Link to="#test" eventKey='test'>{t('profile.payment.test')}</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                    <Tab.Content>
                                                        <Tab.Pane id="live" eventKey='live'>
                                                            <div className="row pt-3">
                                                                <div className="col-12 pt-3">
                                                                    <TextField
                                                                        required
                                                                        error={Boolean(errors.MerchantID) && touched.MerchantID}
                                                                        helperText={(Boolean(errors.MerchantID) && touched.MerchantID) ? errors.MerchantID : ''}
                                                                        fullWidth
                                                                        onBlur={handleBlur}
                                                                        name="MerchantID"
                                                                        label={t('profile.payment.merchantid')}
                                                                        value={values.MerchantID}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                                <div className="col-12 pt-3">
                                                                    <TextField
                                                                        required
                                                                        InputProps={{
                                                                            endAdornment:
                                                                                <InputAdornment position="end">
                                                                                    <IconButton
                                                                                        aria-label="toggle password visibility"
                                                                                        onClick={(e) => { setFieldValue('showPassword', !values.showPassword) }}
                                                                                        onMouseDown={(e) => e.preventDefault()}
                                                                                        edge="end"
                                                                                    >
                                                                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                                                    </IconButton>
                                                                                </InputAdornment>,
                                                                        }}
                                                                        type={values.showPassword ? "text" : "password"}
                                                                        error={Boolean(errors.Password) && touched.Password}
                                                                        helperText={(Boolean(errors.Password) && touched.Password) ? errors.Password : ''}
                                                                        fullWidth
                                                                        onBlur={handleBlur}
                                                                        name="Password"
                                                                        label={t('profile.payment.password')}
                                                                        value={values.Password}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                                <div className="col-12 pt-3">
                                                                    <TextField
                                                                        required
                                                                        error={Boolean(errors.PreSharedKey) && touched.PreSharedKey}
                                                                        helperText={(Boolean(errors.PreSharedKey) && touched.PreSharedKey) ? errors.PreSharedKey : ''}
                                                                        fullWidth
                                                                        onBlur={handleBlur}
                                                                        name="PreSharedKey"
                                                                        label={t('profile.payment.presharedkey')}
                                                                        value={values.PreSharedKey}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                                <div className="col-12 pt-3">
                                                                    <TextField
                                                                        required
                                                                        error={Boolean(errors.APIKey) && touched.APIKey}
                                                                        helperText={(Boolean(errors.APIKey) && touched.APIKey) ? errors.APIKey : ''}
                                                                        fullWidth
                                                                        onBlur={handleBlur}
                                                                        name="APIKey"
                                                                        label={t('profile.payment.apikey')}
                                                                        value={values.APIKey}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                                <div className="col-12 pt-3">
                                                                    <TextField
                                                                        required
                                                                        error={Boolean(errors.AccessToken) && touched.AccessToken}
                                                                        helperText={(Boolean(errors.AccessToken) && touched.AccessToken) ? errors.AccessToken : ''}
                                                                        fullWidth
                                                                        onBlur={handleBlur}
                                                                        name="AccessToken"
                                                                        label={t('profile.payment.accesstoken')}
                                                                        value={values.AccessToken}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>
                                                        <Tab.Pane id="test" eventKey='test'>
                                                            <div className="row pt-3">
                                                                <div className="col-12 pt-3">
                                                                    <TextField
                                                                        required
                                                                        error={Boolean(errors.MerchantID_Test) && touched.MerchantID_Test}
                                                                        helperText={(Boolean(errors.MerchantID_Test) && touched.MerchantID_Test) ? errors.MerchantID_Test : ''}
                                                                        fullWidth
                                                                        onBlur={handleBlur}
                                                                        name="MerchantID_Test"
                                                                        label={t('profile.payment.merchantid')}
                                                                        value={values.MerchantID_Test}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                                <div className="col-12 pt-3">
                                                                    <TextField
                                                                        required
                                                                        InputProps={{
                                                                            endAdornment:
                                                                                <InputAdornment position="end">
                                                                                    <IconButton
                                                                                        aria-label="toggle password visibility"
                                                                                        onClick={(e) => { setFieldValue('showPasswordTest', !values.showPasswordTest) }}
                                                                                        onMouseDown={(e) => e.preventDefault()}
                                                                                        edge="end"
                                                                                    >
                                                                                        {values.showPasswordTest ? <VisibilityOff /> : <Visibility />}
                                                                                    </IconButton>
                                                                                </InputAdornment>,
                                                                        }}
                                                                        type={values.showPasswordTest ? "text" : "password"}
                                                                        error={Boolean(errors.Password_Test) && touched.Password_Test}
                                                                        helperText={(Boolean(errors.Password_Test) && touched.Password_Test) ? errors.Password_Test : ''}
                                                                        fullWidth
                                                                        onBlur={handleBlur}
                                                                        name="Password_Test"
                                                                        label={t('profile.payment.password')}
                                                                        value={values.Password_Test}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                                <div className="col-12 pt-3">
                                                                    <TextField
                                                                        required
                                                                        error={Boolean(errors.PreSharedKey_Test) && touched.PreSharedKey_Test}
                                                                        helperText={(Boolean(errors.PreSharedKey_Test) && touched.PreSharedKey_Test) ? errors.PreSharedKey_Test : ''}
                                                                        fullWidth
                                                                        onBlur={handleBlur}
                                                                        name="PreSharedKey_Test"
                                                                        label={t('profile.payment.presharedkey')}
                                                                        value={values.PreSharedKey_Test}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                                <div className="col-12 pt-3">
                                                                    <TextField
                                                                        required
                                                                        error={Boolean(errors.APIKey_Test) && touched.APIKey_Test}
                                                                        helperText={(Boolean(errors.APIKey_Test) && touched.APIKey_Test) ? errors.APIKey_Test : ''}
                                                                        fullWidth
                                                                        onBlur={handleBlur}
                                                                        name="APIKey_Test"
                                                                        label={t('profile.payment.apikey')}
                                                                        value={values.APIKey_Test}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                                <div className="col-12 pt-3">
                                                                    <TextField
                                                                        required
                                                                        error={Boolean(errors.AccessToken_Test) && touched.AccessToken_Test}
                                                                        helperText={(Boolean(errors.AccessToken_Test) && touched.AccessToken_Test) ? errors.AccessToken_Test : ''}
                                                                        fullWidth
                                                                        onBlur={handleBlur}
                                                                        name="AccessToken_Test"
                                                                        label={t('profile.payment.accesstoken')}
                                                                        value={values.AccessToken_Test}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Tab.Container>
                                            </div>
                                            {/* </div>
                                        </div> */}
                                        </div>
                                    </div>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={handleModalClose}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        size="large"
                                    // disabled={!isValid || isDisabled}
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        </>
                    );
                }}
            </Formik>

        </Modal >

    )
}

export default PaymentSenseEditModal
