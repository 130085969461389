import { Box, Checkbox, Chip, FormControl, FormControlLabel, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { symbolsArr } from '../../../../constants/constants'
import { ServiceFeeListApi, BanserviceFeeUpdateApi, DeleteServiceFeeApi } from '../../../../services/ConfigServices'
import swal from 'sweetalert'
import { useEffect } from 'react'
import { COLUMNFORSERVICEFEES } from './ServiceFeeColumns'
import { useGlobalFilter, useSortBy, useTable } from 'react-table'
import RestaurantSelect from '../../Common/RestaurantSelect'
import TableSearch from '../../../../helpers/TableSearch'
import { Button } from 'react-bootstrap'
import { Empty } from 'antd'
import TablePaginationHelper from '../../../../helpers/Pagination'
import ServicefeeAddEditModal from './ServicefeeAddEditModal'
import ViewServiceFeesModal from './ViewServiceFeesModal'
import * as Yup from 'yup'

const ServiceFeeConfiguration = () => {
    const dispatch = useDispatch()
    const [serviceFeesList, setServiceFeeList] = useState([])
    const [openModal, setOpenModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [viewData, setViewData] = useState({});
    const [editData, setEditData] = useState({});
    const { t } = useTranslation('common');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [RestaurantId, setRestaurantId] = useState('');
    const { restaurantList } = useSelector((state) => state.restaurantList);



    useEffect(() => {
        dispatch(ServiceFeeListApi({ RestaurantId }, (res) => {
            setServiceFeeList(res.data)
        }))
    }, [])

    useEffect(() => {
        dispatch(ServiceFeeListApi({ RestaurantId: '' }, (res) => {
            setServiceFeeList(res.data)
        }))
    }, [openModal])

    const columns = COLUMNFORSERVICEFEES
    const data = serviceFeesList
    const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy)
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter, state } = tableInstance
    const { globalFilter } = state

    const handleEdit = (row) => {
        setEditData(row.original)
        setOpenModal(true)
    }

    const handleAddOpen = () => {
        setEditData()
        setOpenModal(true)
    }


    const handleView = (row) => {
        setViewData(row.original)
        setOpenViewModal(true)
    }

    const handleReset = () => {
        dispatch(ServiceFeeListApi({ RestaurantId: '' }, (res) => {
            setServiceFeeList(res.data)
        }))
    }

    const handleSubmitfilter = (values) => {
        dispatch(ServiceFeeListApi({ RestaurantId }, (res) => {
            setServiceFeeList(res.data)
        }))
    }


    const handleDelete = (row) => {
        const id = row.original.id
        console.log(row.original.id)
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover!",
            icon: "warning",
            buttons: {
                confirm: 'Ok',
                cancel: 'Cancel'
            },
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal("Service fee has been deleted!", {
                    icon: "success",
                });
                dispatch(DeleteServiceFeeApi({ id }, (res) => {
                    dispatch(ServiceFeeListApi({ RestaurantId }, (res) => {
                        setServiceFeeList(res.data)
                    }))
                }))

            }
        })
    }

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <Formik
                        validateOnChange={false}
                        enableReinitialize
                        initialValues={{
                            RestaurantId: ''
                        }}

                        validationSchema={Yup.object().shape({
                            RestaurantId: Yup.string().required("Restaurant  is required."),
                        })}
                        onSubmit={handleSubmitfilter}
                    >
                        {(props) => {
                            const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                            return (
                                <>
                                    <Form onSubmit={handleSubmit} noValidate>
                                        <div className="row">
                                            <div className="col-6">
                                                <FormControl fullWidth>
                                                    <TextField
                                                        required
                                                        select
                                                        fullWidth
                                                        value={values.RestaurantId}
                                                        label={t('transactionscreen.restaurant')}
                                                        name='RestaurantId'
                                                        onChange={(e) => { handleChange(e); setRestaurantId(e.target.value) }}
                                                        onBlur={handleBlur}
                                                        error={Boolean(errors.RestaurantId) && touched.RestaurantId}
                                                        helperText={Boolean(errors.RestaurantId) || touched.RestaurantId ? errors.RestaurantId : ""}
                                                    >
                                                        {restaurantList && restaurantList.length > 0 && restaurantList.map((item, index) => {
                                                            return <MenuItem key={index} value={item.RID}>{item.RestaurantName}</MenuItem>
                                                        })}
                                                    </TextField>
                                                </FormControl>
                                            </div>
                                            <div className="col-6 text-end">
                                                <Button
                                                    variant="danger light"
                                                    type="reset"
                                                    onClick={handleReset}
                                                >
                                                    {t('buttons.reset')}
                                                </Button>
                                                <button
                                                    className="btn btn-primary ms-2"
                                                    type="submit"
                                                    size="large"
                                                >
                                                    {t('buttons.filter')}
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </>
                            );
                        }}
                    </Formik>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{t('servicefeeconfigscreen.title')}</h4>
                    <div className='d-flex justify-content-md-around TableSerchInput'>
                        <TableSearch globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                        <Button onClick={handleAddOpen}>{t('buttons.addservicecharge')}</Button>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <div className="dataTables_wrapper">
                            <table {...getTableProps()} className="table dataTable display">
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {t(column.render('Header'))}
                                                    <span className="ml-1">
                                                        {column.isSorted ? (
                                                            column.isSortedDesc ?
                                                                <i className="fa fa-arrow-down ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                                :
                                                                <i className="fa fa-arrow-up ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                        )
                                                            :
                                                            (<i className="fa fa-sort ms-2 fs-14" style={{ opacity: '0.3' }} />)
                                                        }
                                                    </span>
                                                </th>
                                            ))}
                                            <th className='fixedColumn text-end'>{t('table.action')}</th>
                                        </tr>
                                    ))}
                                </thead>
                                {tableInstance && tableInstance.rows && tableInstance.rows.length > 0 ? <tbody {...getTableBodyProps()}>
                                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()}>
                                                <td> {row.original.RestaurantName} </td>
                                                <td> {row.original.type} </td>
                                                <td >{row.original.type == 'percent' ? row.original.value + '%' : t("currency", { Number: parseFloat(row.original.value) })}</td>
                                                <td >
                                                    <Checkbox
                                                        checked={row.original.IsActive == '1'}
                                                        inputProps={{ readOnly: true }}
                                                    />
                                                </td>
                                                <td className=' fixedColumn text-end'>
                                                    <button className='btn btn-info shadow btn-xs sharp me-1' onClick={() => handleView(row)} > <i className="fa-solid fa-eye"></i></button>
                                                    <button className='btn btn-primary shadow btn-xs sharp me-1' onClick={() => handleEdit(row)} > <i className="fas fa-pencil-alt"></i></button>
                                                    <button className='btn btn-danger shadow btn-xs sharp' onClick={() => handleDelete(row)} > <i className="fa fa-trash"></i></button>
                                                </td>

                                            </tr>
                                        )
                                    })}
                                </tbody> :
                                    <tbody>
                                        <tr>
                                            <td colSpan={6} className='text-center p-5'><Empty /></td>
                                        </tr>
                                    </tbody>}
                            </table>
                        </div>
                    </div>
                </div>
                {tableInstance.rows && tableInstance.rows.length > 0 && <TablePaginationHelper page={page} setPage={setPage} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage} totalnumber={tableInstance.rows.length} />}
            </div>
            {openModal && <ServicefeeAddEditModal openModal={openModal} setOpenModal={setOpenModal} editData={editData} setEditData={setEditData} RestaurantId={RestaurantId} />}
            {openViewModal && <ViewServiceFeesModal openViewModal={openViewModal} setOpenViewModal={setOpenViewModal} viewData={viewData} />}
        </>

    )
}

export default ServiceFeeConfiguration
