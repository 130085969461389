import axiosApi from "../api";
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, SHOW_MESSAGE } from "../constants/ActionTypes";
import { errorHandler } from "../helpers/apiErrorHandler";


// voucher configuration
export const BanVoucherListApi = (cb) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axiosApi.post('Admin/Api/ban-voucher-list').then((res) => {
            if (res.data.status == 1) {
                dispatch({ type: FETCH_SUCCESS });
            } else {
                dispatch({ type: FETCH_ERROR, payload: '' });
            }
            if (cb) cb(res.data)
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}
export const BanVoucherUpdateApi = (data, cb) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axiosApi.post('Admin/Api/ban-voucher-update', data).then((res) => {
            if (res.data.status == 1) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({
                    type: SHOW_MESSAGE,
                    payload: res.data.message,
                });
            } else {
                dispatch({ type: FETCH_ERROR, payload: '' });
            }
            if (cb) cb(res.data)
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}



// service fee configuration
export const ServiceFeeListApi = (id, cb) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axiosApi.post('Admin/Api/service-charge-list', id).then((res) => {
            if (res.data.status == 1) {
                dispatch({ type: FETCH_SUCCESS });
            } else {
                dispatch({ type: FETCH_ERROR, payload: '' });
            }
            if (cb) cb(res.data)
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}

export const serviceFeeUpdateApi = (data, cb) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axiosApi.post('Admin/Api/service-charge-update', data).then((res) => {
            if (res.data.status == 1) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({
                    type: SHOW_MESSAGE,
                    payload: res.data.message,
                });
            } else {
                dispatch({ type: FETCH_ERROR, payload: '' });
            }
            if (cb) cb(res.data)
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}

export const DeleteServiceFeeApi = (id, cb) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axiosApi.post('Admin/Api/service-charge-delete', id).then((res) => {
            if (res.data.status == 1) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({
                    type: SHOW_MESSAGE,
                    payload: res.data.message,
                });
                if (cb) cb(res.data)
            } else {
                dispatch({ type: FETCH_ERROR, payload: res.data.message });
            }
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}


// system configuratuion
export const StatusListSystemConfigApi = (cb) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axiosApi.post('Admin/Api/status-list').then((res) => {
            if (res.data.status == 1) {
                dispatch({ type: FETCH_SUCCESS });
            } else {
                dispatch({ type: FETCH_ERROR, payload: '' });
            }
            if (cb) cb(res.data)
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}
export const getCancellationStatusApi = (cb) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axiosApi.post('Admin/Api/cancellation-status-get').then((res) => {
            if (res.data.status == 1) {
                dispatch({ type: FETCH_SUCCESS });
            } else {
                dispatch({ type: FETCH_ERROR, payload: '' });
            }
            if (cb) cb(res.data)
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}
export const updateCancellationStatusApi = (id, cb) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axiosApi.post('Admin/Api/cancellation-status-update', id).then((res) => {
            if (res.data.status == 1) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({
                    type: SHOW_MESSAGE,
                    payload: res.data.message,
                });
            } else {
                dispatch({ type: FETCH_ERROR, payload: '' });
            }
            if (cb) cb(res.data)
        }).catch((error) => {
            errorHandler(error, dispatch)
        })
    }
}
