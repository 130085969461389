import { FormControl, MenuItem, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react'
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TableSearch from '../../../helpers/TableSearch';
import { RATINGSCOLUMNS } from './RatingsColumns';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';
import TablePaginationHelper from '../../../helpers/Pagination';
import { Empty } from 'antd';
import { DeleteRatingApi, getRatingListApi } from '../../../services/RatingServices';
import swal from 'sweetalert';
import { useEffect } from 'react';
import Rating from '@mui/material/Rating';

const RatingComp = () => {
    const { t } = useTranslation('common')
    const { restaurantList } = useSelector((state) => state.restaurantList);
    const [ratingList, setRatingList] = useState([])
    const dispatch = useDispatch()
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        dispatch(getRatingListApi({
            start_date: '',
            end_date: '',
            restaurantId: "",
        }, (res) => {
            setRatingList(res.data)
        }))
    }, [])

    const columns = RATINGSCOLUMNS
    const data = ratingList
    const tableInstance = useTable({
        columns,
        data,
    }, useGlobalFilter, useSortBy)
    const { getTableProps, getTableBodyProps, headerGroups, rows, allColumns, visibleColumns, prepareRow, setGlobalFilter, state } = tableInstance
    const { globalFilter } = state

    const handleSubmit = (values) => {
        dispatch(getRatingListApi(values, (res) => {
            setRatingList(res.data)
        }))
    }

    const handleReset = () => {
        dispatch(getRatingListApi({
            start_date: '',
            end_date: '',
            restaurantId: "",
        }, (res) => {
            setRatingList(res.data)
        }))
    }

    const handleDelete = (row) => {
        const Id = row.original.ID
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover!",
            icon: "warning",
            buttons: {
                confirm: 'Ok',
                cancel: 'Cancel'
            },
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(DeleteRatingApi({ Id }, (res) => {
                    dispatch(getRatingListApi({
                        start_date: '',
                        end_date: '',
                        restaurantId: "",
                    }, (res) => {
                        setRatingList(res.data)
                    }))
                    swal("Rating has been deleted!", {
                        icon: "success",
                    });
                }))
            }
        })
    }

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <Formik
                        initialValues={{
                            start_date: '',
                            end_date: '',
                            restaurantId: "",
                            }}
                            onSubmit={handleSubmit}
                    >
                        {(props) => {
                            const { values, touched, errors, isValid, isDisabled, handleBlur, handleChange, handleSubmit } = props;
                            return (
                                <Form onSubmit={handleSubmit} noValidate>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <FormControl fullWidth>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    value={values.restaurantId}
                                                    label={t('Ratingscreen.restaurant')}
                                                    name='restaurantId'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                >
                                                    {restaurantList && restaurantList.length > 0 && restaurantList.map((item, index) => {
                                                        return <MenuItem key={index} value={item.RID}>{item.RestaurantName}</MenuItem>
                                                    })}
                                                </TextField>
                                            </FormControl>
                                        </div>
                                        <div className="col-lg-4">
                                            <FormControl size="small" variant="outlined" fullWidth>
                                                <TextField
                                                    onKeyDown={() => { "return false" }}
                                                    fullWidth
                                                    inputProps={{ max: values.end_date }}
                                                    type="date"
                                                    name='start_date'
                                                    variant="outlined"
                                                    label={t('Ratingscreen.startdate')}
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={handleChange}
                                                    value={values.start_date}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="col-lg-4">
                                            <FormControl size="small" variant="outlined" fullWidth>
                                                <TextField
                                                    onKeyDown={() => { "return false" }}
                                                    fullWidth
                                                    type="date"
                                                    inputProps={{ min: values.start_date ? new Date(values.start_date).toISOString().slice(0, 10) : "" }}
                                                    name='end_date'
                                                    variant="outlined"
                                                    label={t('Ratingscreen.enddate')}
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={handleChange}
                                                    value={values.end_date}
                                                />
                                            </FormControl>
                                        </div>

                                        <div className="row pt-3 pe-0">
                                            <div className="col-lg-12 pe-0 text-end">
                                                <Button
                                                    variant="danger light"
                                                    type="reset"
                                                    onClick={handleReset}
                                                >
                                                    {t('buttons.reset')}
                                                </Button>
                                                <button
                                                    className="btn btn-primary ms-2"
                                                    type="submit"
                                                    size="large"
                                                >
                                                    {t('buttons.filter')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{t('Ratingscreen.title')}</h4>
                    <div className='d-flex justify-content-md-around TableSerchInput'>
                        <TableSearch globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <div className="dataTables_wrapper">
                            <table {...getTableProps()} className="table dataTable display">
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {t(column.render('Header'))}
                                                    <span className="ml-1">
                                                        {column.isSorted ? (
                                                            column.isSortedDesc ?
                                                                <i className="fa fa-arrow-down ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                                :
                                                                <i className="fa fa-arrow-up ms-2 fs-14" style={{ opacity: '0.7' }} />
                                                        )
                                                            :
                                                            (<i className="fa fa-sort ms-2 fs-14" style={{ opacity: '0.3' }} />)
                                                        }
                                                    </span>
                                                </th>
                                            ))}
                                            <th className='fixedColumn text-end'>{t('table.action')}</th>
                                        </tr>
                                    ))}
                                </thead>
                                {tableInstance.rows && tableInstance.rows.length > 0 ? <tbody {...getTableBodyProps()}>
                                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        prepareRow(row)
                                        return (
                                            <tr key={index} {...row.getRowProps()}>
                                                <td > {row.original.ID} </td>
                                                <td > {row.original.RestaurantName} </td>
                                                <td > {row.original.UserReview} </td>
                                                <td > <Rating name="read-only" value={row.original.UserRating} readOnly /> </td>
                                                {/* <td > {row.original.UserRating} </td> */}
                                                <td > {row.original.CreatedDateTime ? t("date_format", { date: new Date(row.original.CreatedDateTime) }) : '-'} </td>
                                                <td className='fixedColumn text-end d-flex align-items-center justify-content-end'>
                                                    <button className='btn btn-danger shadow btn-xs sharp' onClick={() => handleDelete(row)} > <i className="fa fa-trash"></i></button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody> :
                                    <tbody>
                                        <tr>
                                            <td colSpan={6} className='text-center p-5'><Empty /></td>
                                        </tr>
                                    </tbody>}
                            </table>
                        </div>
                    </div>
                </div>
                {tableInstance.rows && tableInstance.rows.length > 0 && <TablePaginationHelper page={page} setPage={setPage} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage} totalnumber={tableInstance.rows.length} />}
            </div >
        </>
    )
}

export default RatingComp
