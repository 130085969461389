import { Checkbox, FormControl, FormControlLabel, InputAdornment, MenuItem, Radio, RadioGroup, TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { symbolsArr } from '../../../../constants/constants';
import swal from 'sweetalert';
import { ServiceFeeListApi, serviceFeeUpdateApi } from '../../../../services/ConfigServices';
import { useEffect } from 'react';
import { useState } from 'react';


const ServicefeeAddEditModal = (props) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch()
    const { openModal, setOpenModal, editData, setEditData, RestaurantId } = props
    const currencySymbol = localStorage.getItem('currencySymbol')
    const { restaurantList } = useSelector((state) => state.restaurantList);
    const [finalRestaurantList, setFinalRestaurantList] = useState([])

    const onsubmit = (values) => {
        if (editData && editData.id) {
            values.id = editData.id
        }
        dispatch(serviceFeeUpdateApi(values, (res) => {
            setOpenModal(false);
            setEditData()
        }))
    }
    
    const handleModalClose = () => {
        setOpenModal(false);
        setEditData()
    }

    useEffect(() => {
        dispatch(ServiceFeeListApi({ RestaurantId: '' }, (res) => {
            let tempRestList = []
            let tempRestaurantId = []
            if (res.data.length > 0) {
                for (let index = 0; index < res.data.length; index++) {
                    const element = res.data[index];
                    tempRestaurantId.push(element.RestaurantId)
                }
                tempRestList = restaurantList.filter((items) => !tempRestaurantId.includes(items.RID))
                setFinalRestaurantList(tempRestList)
            }

        }))
    }, [])

    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={openModal}
            // onHide={handleModalClose}
            size="sm"
            centered
        >

            <Formik
                enableReinitialize
                initialValues={{
                    serviceType: editData && editData.type ? editData.type : 'fix',
                    serviceFee: editData && editData.value ? editData.value : '',
                    RestaurantId: editData && editData.RestaurantId ? editData.RestaurantId : '',
                    isActive: editData ? (editData.IsActive == '1' ? true : false) : true,
                }}

                validationSchema={editData ?
                    Yup.object().shape({
                        serviceType: Yup.string().required("service Type is required."),
                        serviceFee: Yup.number().required("service Fee is required."),
                    })
                    :
                    Yup.object().shape({
                        RestaurantId: Yup.string().required("Restaurant is required."),
                        serviceType: Yup.string().required("service Type is required."),
                        serviceFee: Yup.number().required("service Fee is required."),
                    })
                }
                onSubmit={onsubmit}
            >
                {(props) => {
                    const { values, touched, errors, isValid, isDisabled, setFieldValue, handleBlur, handleChange, handleSubmit } = props;
                    return (
                        <>
                            <Modal.Header>
                                <Modal.Title>{editData ? t('servicefeeconfigscreen.editservicefee') + ' (' + editData.RestaurantName + ')' : t('servicefeeconfigscreen.addservicefee')}</Modal.Title>
                                <Button
                                    variant=""
                                    className="btn-close"
                                    onClick={handleModalClose}
                                >

                                </Button>
                            </Modal.Header>
                            <Form onSubmit={handleSubmit} noValidate>
                                <Modal.Body>
                                    <div className="row">
                                        <div className="col-12 pb-3">
                                            {!editData && <FormControl fullWidth>
                                                <TextField
                                                    required
                                                    select
                                                    fullWidth
                                                    disabled={editData ? true : false}
                                                    value={values.RestaurantId}
                                                    label={t('transactionscreen.restaurant')}
                                                    name='RestaurantId'
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        setFieldValue('RestaurantId', e.target.value)
                                                    }}
                                                    onBlur={handleBlur}
                                                    error={Boolean(errors.RestaurantId) && touched.RestaurantId}
                                                    helperText={Boolean(errors.RestaurantId) || touched.RestaurantId ? errors.RestaurantId : ""}
                                                >
                                                    {finalRestaurantList && finalRestaurantList.length > 0 && finalRestaurantList.map((item, index) => {
                                                        return <MenuItem key={index} value={item.RID}>{item.RestaurantName}</MenuItem>
                                                    })}
                                                </TextField>
                                            </FormControl>}
                                        </div>
                                        <div className="col-12 ps-4 servicefeeRadio">
                                            <p className="fs-16 mb-0">{t('servicefeeconfigscreen.selectservicetype')}</p>
                                            <RadioGroup value={values.serviceType} name='serviceType'
                                                onChange={(e) => {
                                                    swal({
                                                        title: "Are you sure?",
                                                        text:
                                                            "you want to change the service fee type?",
                                                        icon: "info",
                                                        buttons: {
                                                            cancel: 'No',
                                                            confirm: 'Yes',
                                                        },
                                                        dangerMode: true,
                                                    }).then((willDelete) => {
                                                        if (willDelete) {
                                                            handleChange(e)
                                                        }
                                                    })
                                                }
                                                } row >
                                                <FormControlLabel
                                                    value={"fix"}
                                                    control={<Radio color="primary" />}
                                                    label={t('servicefeeconfigscreen.fix')}
                                                />
                                                <FormControlLabel
                                                    value={"percent"}
                                                    control={<Radio color="primary" />}
                                                    label={t('servicefeeconfigscreen.percent')}
                                                />
                                            </RadioGroup>
                                        </div>
                                        <div className="col-12 pt-3">
                                            <p className="fs-16 mb-0">{t('servicefeeconfigscreen.servicefee')}</p>
                                            <TextField
                                                type='number'
                                                onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                                value={values.serviceFee}
                                                name={'serviceFee'}
                                                onChange={handleChange}
                                                InputProps={values.serviceType == 'fix' ? {
                                                    inputProps: { min: 0 },
                                                    startAdornment: (
                                                        <InputAdornment position="start">{currencySymbol}</InputAdornment>
                                                    ),
                                                } : {
                                                    inputProps: { min: 0 },
                                                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                                                }}
                                                variant="outlined"
                                            />
                                        </div>
                                        <div className='col-6 pt-3'>
                                            <FormControlLabel
                                                control={<Checkbox checked={values.isActive} />}
                                                label={t('isActive')}
                                                name="isActive"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>


                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={handleModalClose}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        size="large"
                                    // disabled={!isValid || isDisabled}
                                    >
                                        {t('buttons.save')}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        </>
                    );
                }}
            </Formik>
        </Modal >

    )
}

export default ServicefeeAddEditModal
